import React from 'react'
import clsx from "clsx";
import { DecoIcon } from '../images/IconImage';

function SelectionCheckBox({selected, handleClick}) {
  const divContainer = clsx(
    "w-10 h-10 md:h-12 md:w-12",
    "hover:animate-wiggle-doux",
  );

  return (
    <div className={divContainer}>
      <div className="relative" onClick={handleClick}>
        <DecoIcon
          iconName={"checkbox-icon"}
          altName={"selection"}
          size={"h-10 w-10 md:h-12 md:w-12"}
        />
        {selected && (
          <div className="absolute -bottom-0 -right-0">
            <DecoIcon
              iconName={"check-icon"}
              altName={"check-selection"}
              size={"h-10 w-10 md:h-12 md:w-12"}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default SelectionCheckBox