import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import clsx from "clsx";
import { REGISTER, FORGOT_PWD } from "../../constants/routes";
import {
  SLICE_LOGIN_DATA,
  KEYS_LOGIN_DATA,
} from "../../redux/slices/loginDataSlice";
import { useLoginMutation } from "../../hooks/requests/useLoginMutation";
import LoadingModal from "../elements/modals/LoadingModal";
import { SectionCol } from "../elements/sections/Sections";
import { H1 } from "../elements/texts/Texts";
import { FormLogin } from "../elements/forms/Forms";
import { ButtonFill } from "../elements/bouttons/Buttons";
import useLocalStorage from "../../hooks/utils/useLocalStorage";
import { persist } from "../../constants/authent";
import { CheckBoxInput } from "../elements/inputs/CheckBoxInputs";
import { DivColRowRev, DivCol } from "../elements/divs/Divs";
import { AuthEmailFieldAndError } from "../elements/fields/EmailFields";
import { AuthPwdFieldAndError } from "../elements/fields/PwdFields";
import useAuthentFieldsOk from "../../hooks/utils/useAuthentFieldsOk";

function Login() {
  const loginMutation = useLoginMutation();

  return (
    <>
      {loginMutation.isLoading && <LoadingModal />}
      <LoginContent loginPostRequest={loginMutation.mutate} />
    </>
  );
}

function LoginContent({ loginPostRequest }) {
  const formData = useSelector((state) => ({
    email: state[SLICE_LOGIN_DATA][KEYS_LOGIN_DATA.EMAIL],
    pwd: state[SLICE_LOGIN_DATA][KEYS_LOGIN_DATA.PWD],
  }));

  const onSubmit = async () => {
    loginPostRequest({
      email: formData.email.value,
      pwd: formData.pwd.value,
    });
  };

  return (
    <SectionCol add="pt-12">
      <H1 add="text-center">Quel est ce cuisinier ?</H1>
      <FormLogin>
        <FormContent onSubmit={onSubmit} />
      </FormLogin>
    </SectionCol>
  );
}

function FormContent({ onSubmit }) {
  return (
    <>
      <AuthEmailFieldAndError />
      <AuthPwdFieldAndError />
      <KeepLoginField />
      <RedirectLinksAndSubmitButton handleSubmit={onSubmit} />
    </>
  );
}

const KeepLoginField = React.memo(function KeepLoginField() {
  const [value, setValue] = useLocalStorage("persist", false);

  return <CheckBoxInput item={persist} value={value} setter={setValue} />;
})

function RedirectLinksAndSubmitButton({ handleSubmit }) {
  return (
    <DivColRowRev add="pt-8" ju={"justify-between"}>
      <RedirectLinks />
      <ButtonLogIn handleSubmit={handleSubmit} />
    </DivColRowRev>
  );
}

function RedirectLinks() {
  const pLinkStyle = clsx(
    "text-gray-500",
    "font-normal",
    "text-sm sm:text-base",
    "font-spin",
    "py-4 sm:py-0"
  );

  return (
    <DivCol it={"items-center sm:items-start"} add={"space-y-2 pt-4 sm:pt-0"}>
      <ForgotPwdLink pLinkStyle={pLinkStyle} />
      <RegisterLink pLinkStyle={pLinkStyle} />
    </DivCol>
  );
}

function ForgotPwdLink({ pLinkStyle }) {
  return (
    <p className={pLinkStyle}>
      <Link className="underline" to={FORGOT_PWD.route}>
        {FORGOT_PWD.name}
      </Link>
    </p>
  );
}

function RegisterLink({ pLinkStyle }) {
  return (
    <p className={pLinkStyle}>
      Non inscrit ?{" "}
      <Link className="underline" to={REGISTER.route}>
        {REGISTER.name}
      </Link>
    </p>
  );
}

function ButtonLogIn({ handleSubmit }) {
  const { loginFormOk } = useAuthentFieldsOk();
  return (
    <ButtonFill
      type="submit"
      add="mt-4"
      onClick={handleSubmit}
      testid="connexion"
      disabled={!loginFormOk}
    >
      Connexion
    </ButtonFill>
  );
}

export default Login;
