import React, { useCallback, useEffect, useMemo, useState } from "react";
import { SectionCol } from "../../elements/sections/Sections";
import { DivCol } from "../../elements/divs/Divs";
import { useDispatch, useSelector } from "react-redux";
import {
  KEYS_RECIPES_TO_COOK,
  SLICE_RECIPES_TO_COOK,
  STARTERS,
  MEALS,
  DESSERTS,
  setRecipeSelected,
  setShowResumeRecipe,
  setRecipeShown,
  setIndexRecipeShown,
  setImageRecipeShown,
} from "../../../redux/slices/recipesToCookSlice";
import {
  FILTERS_DISPLAYED,
  INFOS_DISPLAYED,
  setFiltersDisplay,
  setWhoIsDisplay,
} from "../../../redux/slices/recipesSlice";
import { setType as setFilterTypeOfMeal } from "../../../redux/slices/filtersRecipesSlice";
import { ButtonFill } from "../../elements/bouttons/Buttons";
import { H3 } from "../../elements/texts/Texts";
import useRecipesToCookQuery from "../../../hooks/requests/useRecipesToCookQuery";
import CardRecipe from "../../elements/cards/cardRecipe/CardRecipe";
import useDeleteRecipesToCookMutation from "../../../hooks/requests/useDeleteRecipesToCookMutation";
import { RECETTES } from "../../../constants/routes";
import ResumeRecipeModal from "../../elements/modals/ResumeRecipeModal";
import useHandleFavoritesRecipes from "../../../hooks/utils/useHandleFavoritesRecipes";
import LoadingModal from "../../elements/modals/LoadingModal";
import useHandleDisplayRecipe from "../../../hooks/utils/useHandleDisplayRecipe";
import useWindowWidth from "../../../hooks/utils/useWindowWidth";
import clsx from "clsx";
import NavBarRecipesToCook from "../../elements/navbars/NavBarRecipesToCook";

function Cooking() {
  const states = useSelector((state) => state[SLICE_RECIPES_TO_COOK]);

  const recipesToDisplay = useMemo(() => {
    if (states.type.value === STARTERS) {
      return states.recipesToCookFetched.filter(
        (recipe) => recipe.type === STARTERS
      );
    }
    if (states.type.value === MEALS) {
      return states.recipesToCookFetched.filter(
        (recipe) => recipe.type === MEALS
      );
    }
    if (states.type.value === DESSERTS) {
      return states.recipesToCookFetched.filter(
        (recipe) => recipe.type === DESSERTS
      );
    }
  }, [states.type, states.recipesToCookFetched]);
  
  const handleChangeLikeStatus = useHandleFavoritesRecipes();
  const handleDisplayRecipe = useHandleDisplayRecipe(
    setRecipeShown,
    setIndexRecipeShown,
    setShowResumeRecipe,
    setImageRecipeShown
  );

  const divRecipesContentStyle = clsx(
    "w-full",
    "mb-18 pb-18 md:pt-20 md:mb-0",
    "flex flex-col items-center justify-center",
    "overflow-hidden"
  );

  return (
    <SectionCol>
      <NavBarRecipesToCook states={states}/>
      <div className={divRecipesContentStyle}>
      <RecipesToCook
        type={states.type.value}
        dishes={recipesToDisplay}
        handleChangeLikeStatus={handleChangeLikeStatus}
        handleDisplayRecipe={handleDisplayRecipe}
      />
      </div>
      {states.showResumeRecipe && (
        <ResumeRecipeModal
          recipeShown={states.recipeShown}
          indexRecipeShown={states.indexRecipeShown}
          imageRecipeShown={states.imageRecipeShown}
          setShowResumeRecipe={setShowResumeRecipe}
          handleChangeLikeStatus={handleChangeLikeStatus}
          ButtonHandleActionOnRecipe={ButtonDeleteRecipeToCook}
          handleDisplayRecipe={handleDisplayRecipe}
          recipesToDisplay={recipesToDisplay}
          couvertWanted={states.recipeShown.couvertWanted}
        />
      )}
    </SectionCol>
  );
}

function RecipesToCook({
  type,
  dishes,
  handleChangeLikeStatus,
  handleDisplayRecipe,
}) {
  const recipesToCookQuery = useRecipesToCookQuery();

  const divContainerStyle = clsx(
    "w-full",
    "flex flex-wrap justify-center items-center",
  );

  return (
    <div className={divContainerStyle}>
      {recipesToCookQuery.isLoading && <p>Chargement...</p>}
      {recipesToCookQuery.isError && <p>Erreur</p>}
      {recipesToCookQuery.isSuccess && (
        <>
          {dishes.length === 0 && <ButtonRedirection type={type} />}
          {dishes.length > 0 && (
            <RecipesList
              dishes={dishes}
              handleChangeLikeStatus={handleChangeLikeStatus}
              handleDisplayRecipe={handleDisplayRecipe}
            />
          )}
        </>
      )}
    </div>
  );
}

function ButtonRedirection({ type }) {
  const dispatch = useDispatch();

  function handleClick() {
    dispatch(setFilterTypeOfMeal(type));
    dispatch(setWhoIsDisplay(FILTERS_DISPLAYED));
    dispatch(setFiltersDisplay(INFOS_DISPLAYED));
  }

  return (
    <DivCol add={"w-full pt-12 space-y-8"}>
      <H3>
        Vous n'avez pas{" "}
        {type === STARTERS
          ? "d'entrée"
          : type === MEALS
          ? "de plat"
          : "de dessert"}{" "}
        à cuisiner
      </H3>
      <ButtonFill onClick={handleClick} nav={RECETTES.route}>
        Consulter les recettes
      </ButtonFill>
    </DivCol>
  );
}

function RecipesList({ dishes, handleChangeLikeStatus, handleDisplayRecipe }) {
  const dispatch = useDispatch();
  const isMobile = useWindowWidth();

  const handleSelectionStatus = useCallback(
    (id) => {
      dispatch(setRecipeSelected(id));
    },
    [dispatch]
  );

  return (
    <>
      {dishes.map((recipe, index) => {
        return (
          <CardRecipe
            key={recipe.idRecipeToCook}
            recipe={recipe}
            couvert={recipe.couvertWanted}
            index={index}
            openCard={handleDisplayRecipe}
            changeRecipeSelectionStatus={handleSelectionStatus}
            changeLikeStatus={handleChangeLikeStatus}
            isMobile={isMobile}
          />
        );
      })}
    </>
  );
}

function ButtonDeleteRecipeToCook({ recipeShown }) {
  const dispatch = useDispatch();
  const [confirmDelete, setConfirmDelete] = useState(false);

  const { recipesToCookFetched } = useSelector((state) => ({
    recipesToCookFetched:
      state[SLICE_RECIPES_TO_COOK][
        KEYS_RECIPES_TO_COOK.RECIPES_TO_COOK_FETCHED
      ],
  }));
  const deleteRecipesToCookMutation =
    useDeleteRecipesToCookMutation(recipesToCookFetched);

  useEffect(() => {
    let timer = null;
    if (confirmDelete) {
      timer = setTimeout(() => {
        setConfirmDelete(false);
      }, 3000);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [confirmDelete]);

  function handleClick() {
    if (!confirmDelete) {
      setConfirmDelete(true);
    } else {
      deleteRecipesToCookMutation.mutate({
        recipesToDelete: [recipeShown.idRecipeToCook],
      });
      dispatch(setShowResumeRecipe(false));
    }
  }

  return (
    <>
      <ButtonFill
        onClick={handleClick}
        add={"my-4"}
        col={confirmDelete ? "bg-red-400" : "bg-sky-600"}
      >
        {confirmDelete ? "Confirmer la suppression" : "Supprimer la recette"}
      </ButtonFill>
      {deleteRecipesToCookMutation.isLoading && <LoadingModal />}
    </>
  );
}

export default Cooking;
