import { useMutation } from "react-query";
import useError from "../utils/useError";
import { update_recipe } from "../../functions/requests/axiosRequests";
import { useDispatch, useSelector } from "react-redux";
import {
  setDisplayUpdateSuccess,
  setDisplayResumeRecipe,
  resetState,
} from "../../redux/slices/editRecipeSlice";
import {
  setFileNameRecipeShown,
  setImageRecipeShown,
} from "../../redux/slices/ownRecipesSlice";
import {
  KEYS_RECIPES,
  SLICE_RECIPES,
  setRecipesFetched,
} from "../../redux/slices/recipesSlice";
import {
  KEYS_RECIPES_TO_COOK,
  SLICE_RECIPES_TO_COOK,
  setARecipeToCookIsUpdated,
  setRecipesToCookFetched,
} from "../../redux/slices/recipesToCookSlice";

import { BASE_URL_API } from "../../constants/routes";

function useUpdateRecipeMutation() {
  const dispatch = useDispatch();
  const { setError } = useError();

  const { recipesFetched } = useSelector((state) => ({
    recipesFetched: state[SLICE_RECIPES][KEYS_RECIPES.RECIPES_FETCHED],
  }));

  const { recipesToCookFetched } = useSelector((state) => ({
    recipesToCookFetched:
      state[SLICE_RECIPES_TO_COOK][
        KEYS_RECIPES_TO_COOK.RECIPES_TO_COOK_FETCHED
      ],
  }));

  function setUrlOfImageUpdated(nameImage) {
    switch (nameImage) {
      case "starter_meal.png":
      case "main_meal.png":
      case "dessert_meal.png":
        return require(`../../assets/images/${nameImage}`);
      default:
        return `${BASE_URL_API}/image_recipe/${nameImage}`;
    }
  }

  function setNameOfImageUpdated(nameImage) {
    switch (nameImage) {
      case "starter_meal.png":
      case "main_meal.png":
      case "dessert_meal.png":
        return "";
      default:
        return nameImage;
    }
  }

  const updateRecipeMutation = useMutation(update_recipe, {
    onSuccess: (data) => {
      // console.log("updateRecipeMutation data", data);
      let foundInRecipesFetched = false;
      let foundInRecipesToCookFetched = false;

      const newRecipesFetched = recipesFetched.map((recipe) => {
        if (recipe._id === data.recipeUpdated._id) {
          foundInRecipesFetched = true;
          return {
            ...data.recipeUpdated,
            selected: recipe.selected,
            isLiked: recipe.isLiked,
          };
        }
        return recipe;
      });

      const newRecipesToCookFetched = recipesToCookFetched.map((recipe) => {
        if (recipe._id === data.recipeUpdated._id) {
          foundInRecipesToCookFetched = true;
          return {
            ...data.recipeUpdated,
            selected: recipe.selected,
            isLiked: recipe.isLiked,
            couvertWanted: recipe.couvertWanted,
          };
        }
        return recipe;
      });

      if (foundInRecipesFetched) {
        dispatch(setRecipesFetched(newRecipesFetched));
      }

      // console.log("foundInRecipesToCookFetched", foundInRecipesToCookFetched);
      if (foundInRecipesToCookFetched) {
        // setTimeout(() => { // to avoid the no refresh
          // console.log("if (foundInRecipesToCookFetched)");
          dispatch(setARecipeToCookIsUpdated(true));
          // console.log("after dispatch(setARecipeToCookIsUpdated(true))");
          dispatch(setRecipesToCookFetched(newRecipesToCookFetched));
        // }, 200);
      }

      const newImageUrl = setUrlOfImageUpdated(data.recipeUpdated.imageRecipe);
      const newImageName = setNameOfImageUpdated(
        data.recipeUpdated.imageRecipe
      );
      dispatch(setImageRecipeShown(newImageUrl));
      dispatch(setFileNameRecipeShown(newImageName));
      dispatch(resetState());
      dispatch(setDisplayResumeRecipe(false));
      dispatch(setDisplayUpdateSuccess(true));
    },
    onError: (error) => {
      setError(error.response?.data?.message || "Une erreur s'est produite");
    },
  });

  return updateRecipeMutation;
}

export default useUpdateRecipeMutation;
