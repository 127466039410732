import React from 'react'
import clsx from 'clsx'
import IngredientsCardRecipe from './IngredientsCardRecipe'
import TimeTypeSelectionCardRecipe from './TimeTypeSelectionCardRecipe'

function IngredientsAndTypeCardRecipe({ recipe, changeRecipeSelectionStatus, isMobile, selectionDisabled}) {
  const divStyle = clsx(
    "flex flex-col justify-start items-center",
    "w-15 md:w-full",
    "h-full md:h-36",
    "order-first md:order-[2]",
    "py-0.5 md:pt-0"
  )

  return (
    <div className={divStyle}>
      <IngredientsCardRecipe ingredients={recipe.ingredients} idRecipeToCook={recipe.idRecipeToCook} id={recipe._id} />
      <TimeTypeSelectionCardRecipe recipe={recipe} changeRecipeSelectionStatus={changeRecipeSelectionStatus} isMobile={isMobile} selectionDisabled={selectionDisabled}/>
    </div>
  )
}

export default IngredientsAndTypeCardRecipe