import React from "react";
import clsx from "clsx";
import FavorisCardRecipe from "./FavorisCardRecipe";
import CouvertCardRecipe from "./CouvertCardRecipe";
import TimeCardRecipe from "./TimeCardRecipe";
import SelectionCardRecipe from "./SelectionCardRecipe";

function VerticalInfosCardRecipe({
  recipe,
  changeRecipeSelectionStatus,
  couvert,
  selectionDisabled
}) {
  const divContainerStyle = clsx(
    "flex flex-col justify-around items-center",
    "h-48",
    "w-15 ",
    // "bg-white",
    "py-0.5"
  );

  return (
    <div className={divContainerStyle}>
      <FavorisCardRecipe
        id={recipe._id}
        isLiked={recipe.isLiked}
      />
      <CouvertCardRecipe couvert={couvert} />
      <TimeCardRecipe totalTime={recipe.totalTime} />
      {!selectionDisabled && <SelectionCardRecipe
        idRecipeToCook={recipe.idRecipeToCook}
        id={recipe._id}
        selected={recipe.selected}
        changeRecipeSelectionStatus={changeRecipeSelectionStatus}
      />}
      {selectionDisabled && 
      <div className="h-10 w-10"></div>}
    </div>
  );
}

export default VerticalInfosCardRecipe;
