import React from 'react'
import { DecoIcon } from './IconImage';

function SeasonIcon({seasons}) {
  const seasonData = [
    {
      name: "printemps",
      icon: "icons8-sakura",
    },
    {
      name: "ete",
      icon: "icons8-sun",
    },
    {
      name: "automne",
      icon: "icons8-autumn",
    },
    {
      name: "hiver",
      icon: "icons8-snow",
    },
  ];
  return (
    <>
      {seasonData.map((season) =>
        seasons.includes(season.name) ? (
          <DecoIcon
            key={season.name}
            iconName={season.icon}
            altName={`${season.name}_icon`}
            size={"h-10 w-10"}
          />
        ) : null
      )}
    </>
  )
}

export default SeasonIcon