// SwitchSpan.js
import React, { useState } from "react";
import clsx from "clsx";
import { DivRow } from "../divs/Divs";
import { H3 } from "../texts/Texts";

const SwitchSpan = ({ onChange, foodObject, index }) => {
  const [checked, setChecked] = useState(foodObject.isExclude);

  const toggleChecked = () => {
    const newChecked = !checked;
    setChecked(newChecked);
    if (onChange) {
      onChange(index);
    }
  };

  return (
    <DivRow add="space-x-2">
      <H3 add="text-center w-18">{checked ? "Exclue" : "Exclure"}</H3>
      <label
        className="inline-flex items-center cursor-pointer"
        onClick={toggleChecked}
      >
        {/* <span className="relative"> */}
        <span
          className={clsx(
            "relative",
            "block",
            "w-10",
            "h-6",
            "rounded-full",
            "transition-all",
            "duration-300",
            checked ? "bg-sky-500" : "bg-gray-500"
          )}
        >
          <span
            className={clsx(
              "absolute",
              "top-0",
              "left-0",
              "block",
              "w-6",
              "h-6",
              // "mt-px",
              // "ml-px",
              "rounded-full",
              "bg-white",
              "shadow-md",
              "transform",
              "transition-all",
              "duration-300",
              checked ? "translate-x-4" : ""
            )}
          ></span>
        </span>
        {/* </span> */}
      </label>
    </DivRow>
  );
};

export default SwitchSpan;
