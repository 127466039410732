import clsx from "clsx";
import React, { useCallback } from "react";
import { sH3 } from "../../../styles/styles";
import { H3 } from "../texts/Texts";
import { DivRow } from "../divs/Divs";
import { useDispatch } from "react-redux";
import { DecoIcon } from "../images/IconImage";

export const ListCheckBoxesInput = React.memo(
  ({ item, formValue, dispatcher }) => {
    const handleCheckboxChange = useCallback(
      (event) => {
        const { name, checked } = event.target;
        const currentValue = formValue || [];
        if (checked) {
          dispatcher([...currentValue, name]);
        } else {
          dispatcher(currentValue.filter((val) => val !== name));
        }
      },
      [formValue, dispatcher]
    );

    return (
      <div className="px-2 w-full pt-4 flex flex-col justify-center items-center">
        <DivRow add="space-x-2">
          {item.logo && (
            <DecoIcon
              iconName={item.logo}
              altName={item.name}
              size={"h-8 w-8"}
            />
          )}
          <H3 size={"md:text-lg lg:text-lg"} add="text-center">
            {item.title}
          </H3>
        </DivRow>
        {/* <UlColRow add="w-full space-x-0 space-y-4 sm:space-x-4 sm:space-y-0 pt-4" ju="justify-center"
            > */}
        <div
          className={clsx(
            "pt-4",
            // "grid grid-cols-2 gap-4",
            "flex flex-row justify-center items-center md:space-x-4",
            "md:w-2/3"
          )}
        >
          {item.data.map((season) => {
            return (
              <div
                key={season.name}
                // className="w-28 relative"
              >
                <input
                  type="checkbox"
                  name={season.name}
                  id={season.name}
                  value={season.defaultValue}
                  checked={(formValue || []).includes(season.name)}
                  onChange={handleCheckboxChange}
                  className={clsx("hidden")}
                  testid={season.name}
                />

                <label htmlFor={season.name} testid={`label_${season.name}`}>
                  <DecoIcon
                    add={clsx(
                      item.value.includes(season.name)
                        ? "border-4 border-sky-700"
                        : "",
                      item.value.includes(season.name)
                        ? "hover:border-4 hover:border-sky-300"
                        : "hover:border-2 hover:border-sky-300",
                      "cursor-pointer"
                    )}
                    iconName={season.logo}
                    altName={season.name}
                    size={"h-10 w-10"}
                  />
                </label>
              </div>
            );
          })}
          {/* </UlColRow> */}
        </div>
      </div>
    );
  }
);

export const ListCheckBoxesInputStore = React.memo(({ state, action }) => {
  const dispatch = useDispatch();

  const onChange = useCallback(
    (value) => {
      dispatch(action(value));
    },
    [dispatch, action]
  );

  return (
    <ListCheckBoxesInput
      item={state}
      formValue={state.value}
      dispatcher={onChange}
    />
  );
});

export const CheckBoxInput = React.memo(
  ({ item, value, setter, reference }) => {
    const handleChange = (e) => {
      setter(!value);
    };

    return (
      <div ref={reference}>
        <input
          type={item.type}
          name={item.name}
          value={item.name}
          id={item.name}
          className={clsx("peer [&:checked_+_label_svg]:block", "hidden")}
          // onClick={handleClick}
          onChange={handleChange}
          defaultChecked={value}
        />
        <label htmlFor={item.name}>
          <div
            testid="label_content"
            className="flex flex-row items-center justify-center space-x-2"
          >
            <div className="relative h-7 w-7 bg-white border-2 border-slate-900 rounded-lg">
              <img
                src={item.logo}
                alt={`logo_${item.name}`}
                className={clsx(
                  "absolute",
                  "h-7 w-7",
                  value ? "opacity-100" : "opacity-0",
                  //centrer l'image dans la div relative
                  "top-1/2 left-1/2",
                  "transform -translate-x-1/2 -translate-y-1/2",
                  "block cursor-pointer"
                )}
              />
            </div>
            {item.title && (
              <h3 className={clsx(sH3, "block cursor-pointer")}>
                {item.title}
              </h3>
            )}
          </div>
        </label>
      </div>
    );
  }
);
