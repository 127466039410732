import React from "react"

const useDebounce = (callback, delay) => {
  const debounceRef = React.useRef(null);
  
  const onDebounce = React.useCallback((...args) => {
    clearTimeout(debounceRef.current);
    debounceRef.current = setTimeout(() => {
      callback(...args);
    }, delay);
  }, [callback, delay]);

  const cancelDebounce = React.useCallback(() => {
    if (debounceRef.current) {
      clearTimeout(debounceRef.current);
    }
  }, []);

  return [onDebounce, cancelDebounce];
};

export default useDebounce;
