import { DivCol } from "../divs/Divs";
import { ButtonFill, ButtonEmpty } from "./Buttons";
import { PROFILE, PROFILE_EMAIL, PROFILE_PWD } from "../../../constants/routes";
import useLogoutMutation from "../../../hooks/requests/useLogoutMutation";

export function UpdateUserDatasButtons() {
  return (
    <DivCol it="no" add="space-y-4">
      <UpdateEmailButton />
      <UpdatePwdButton />
    </DivCol>
  );
}

function UpdateEmailButton() {
  return (
    <ButtonFill testid="changeEmail" nav={PROFILE_EMAIL.route}>
      Changer l'email
    </ButtonFill>
  );
}

function UpdatePwdButton() {
  return (
    <ButtonFill testid="changePwd" nav={PROFILE_PWD.route}>
      Changer le mot de passe
    </ButtonFill>
  );
}

export function LogoutAndDeleteButtons({ setDisplayDeleteAccount }) {
  return (
  <DivCol  add="pt-12 pb-0 sm:pb-8 space-y-4">
    <LogoutButton />
    <DeleteAccountButton setDisplayDeleteAccount={setDisplayDeleteAccount} />
  </DivCol>
  );
}

function LogoutButton() {
  const logoutRequest = useLogoutMutation();
  return (
    <ButtonFill testid="logout" onClick={logoutRequest.mutate}>
      Déconnexion
    </ButtonFill>
  );
}

function DeleteAccountButton({ setDisplayDeleteAccount }) {
  return (
    <ButtonEmpty
      testid="deleteAccount"
      onClick={() => setDisplayDeleteAccount(true)}
    >
      Supprimer le compte
    </ButtonEmpty>
  );
}

export function SubmitAndProfileButton({ formOk, onSubmit }) {
  return (
    <DivCol add="pt-4 space-y-4">
      <SubmitButton formOk={formOk} onSubmit={onSubmit} />
      <BackProfileButton />
    </DivCol>
  );
}

function SubmitButton({ formOk, onSubmit }) {
  return (
    <ButtonFill
      type="submit"
      add="w-44"
      disabled={!formOk}
      onClick={onSubmit}
      testid="submit"
    >
      Modifier
    </ButtonFill>
  );
}

function BackProfileButton() {
  return (
    <ButtonEmpty testid="profile" nav={PROFILE.route} add="w-44">
      {PROFILE.name}
    </ButtonEmpty>
  );
}
