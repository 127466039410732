import React, { useCallback, useMemo } from "react";
import "./style.css";
import CardStand from "./CardStand";
import ImageAndTitleCardRecipe from "./ImageAndTitleCardRecipe";
import IngredientsAndTypeCardRecipe from "./IngredientsAndTypeCardRecipe";
import FavorisCardRecipe from "./FavorisCardRecipe";
import CouvertCardRecipe from "./CouvertCardRecipe";
import VerticalInfosCardRecipe from "./VerticalInfosCardRecipe";
import { BASE_URL_API } from "../../../../constants/routes";

function CardRecipe({
  recipe,
  index,
  openCard,
  changeRecipeSelectionStatus,
  couvert,
  selectionDisabled = false,
  isMobile,
}) {
  
  const srcImage = useMemo(() => {
    switch (recipe.imageRecipe) {
      case "starter_meal.png":
      case "main_meal.png":
      case "dessert_meal.png":
        return require(`../../../../assets/images/${recipe.imageRecipe}`);
      default:
        return `${BASE_URL_API}image_recipe/${recipe.imageRecipe}`;
    }
  }, [recipe.imageRecipe]);

  const handleClick = useCallback(() => {
    openCard(index, srcImage, recipe);
  }, [openCard, index, srcImage, recipe]);
  

  return (
    <CardStand onClick={handleClick}>
      <ImageAndTitleCardRecipe
        srcImage={srcImage}
        blurhash={recipe.blurImageRecipe}
        title={recipe.name}
        seasons={recipe.seasons}
        id={recipe._id}
        type={recipe.type}
      />
      <IngredientsAndTypeCardRecipe
        recipe={recipe}
        changeRecipeSelectionStatus={changeRecipeSelectionStatus}
        isMobile={isMobile}
        selectionDisabled={selectionDisabled}
      />
      {!isMobile && (
        <FavorisCardRecipe
          id={recipe._id}
          isLiked={recipe.isLiked}
        />
      )}
      {!isMobile && <CouvertCardRecipe couvert={couvert} />}
      {isMobile && (
        <VerticalInfosCardRecipe
          recipe={recipe}
          couvert={couvert}
          changeRecipeSelectionStatus={changeRecipeSelectionStatus}
          selectionDisabled={selectionDisabled}
        />
      )}
    </CardStand>
  );
}

export default React.memo(CardRecipe);
