import React from "react";
import clsx from "clsx";

export const FormAuth = ({ children }) => {
  const formStyle = clsx(
    "w-full sm:w-3/4 md:w-2/3 lg:w-1/2 xl:w-1/3",
    "mt-8",
    "mb-0",
    "space-y-4",
    "flex",
    "flex-col",
    "items-center",
    "pb-4"
  );

  return <form className={formStyle}>{children}</form>;
};

export function FormLogin({ children }) {
  const formStyle = clsx(
    "w-full sm:w-3/4 md:w-2/3 lg:w-1/2 xl:w-1/3",
    "mt-8",
    "mb-0",
    "space-y-4"
  );

  return <form className={formStyle}>{children}</form>;
}


export function FormRegister({ children }) {
  const formStyle = clsx(
    // "mx-auto",
    "w-full",
    "mt-8",
    "mb-0",
    "max-w-md",
    "space-y-4",
    "pb-4"
  );

  return <form className={formStyle}>{children}</form>;
}