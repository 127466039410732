import React from 'react'
import { ButtonNumberInputStore } from '../inputs/ButtonNumberInput'
import { setTotalTime } from '../../../redux/slices/filtersRecipesSlice';
import { useSelector } from 'react-redux';

function FieldTotalTimeOfRecipe() {
  const totalTime = useSelector((state) => state.filtersRecipes.totalTime);
  
  return (
    <ButtonNumberInputStore state={totalTime} action={setTotalTime} />
  )
}

export default React.memo(FieldTotalTimeOfRecipe)