import React from "react";
import clsx from "clsx";
import { DecoIcon } from "../images/IconImage";
function ButtonIcon({
  handleClick,
  isSelected,
  iconName,
  spanText,
  disableSpan = false,
}) {
  const activeButtonStyle = clsx(
    "border border-slate-900",
    "scale-110",
    "bg-sky-100"
  );

  const buttonIconStyle = clsx(
    isSelected ? activeButtonStyle : "",
    "flex flex-row items-center justify-center overflow-visible",
    "h-12 sm:h-14 md:h-16 lg:h-18",
    "w-12 sm:w-14 md:w-16 lg:w-18",
    "bg-lime-100 hover:bg-sky-100",
    "rounded-md hover:rounded-xl",
    "shadow-md",
    "transition-all duration-100 ease-linear",
    "relative",
    "group"
  );

  const sizeIconStyle = clsx(
    "h-12 sm:h-10 md:h-14 lg:h-16",
    "w-12 sm:w-10 md:w-14 lg:w-16"
  );

  const activeSpanNavStyle = clsx("border border-slate-900");

  const spanTextStyle = clsx(
    isSelected ? activeSpanNavStyle : "",
    "absolute",
    "bg-sky-100 text-sky-700",
    "rounded-md",
    "w-auto",
    "px-7 py-2",
    "top-[65px]",
    "text-xl",
    "font-spin",
    "transition-all duration-100 scale-0 origin-top",
    "group-hover:scale-100"
  );

  return (
    <button
      onClick={handleClick}
      className={buttonIconStyle}
      testid={`button_${spanText}`}
    >
      <DecoIcon
        iconName={iconName}
        altName={`button_${spanText}`}
        size={sizeIconStyle}
      />
      {!disableSpan && <span className={spanTextStyle}>{spanText}</span>}
    </button>
  );
}

export default ButtonIcon;
