import React, { useCallback } from "react";
import clsx from "clsx";
import useGetTempRecipesMutation from "../../../hooks/requests/useGetTempRecipesMutation";
import { ButtonEmpty, ButtonFill } from "../../elements/bouttons/Buttons";
import { useDispatch, useSelector } from "react-redux";
import {
  KEYS_TEMP_RECIPES,
  SLICE_TEMP_RECIPES,
  setImageRecipeShown,
  setIndexRecipeShown,
  setRecipeSelected,
  setRecipeShown,
  setShowResumeRecipe,
} from "../../../redux/slices/tempRecipesSlice";
import CardRecipe from "../../elements/cards/cardRecipe/CardRecipe";
import useWindowWidth from "../../../hooks/utils/useWindowWidth";
import useHandleDisplayRecipe from "../../../hooks/utils/useHandleDisplayRecipe";
import useHandleFavoritesRecipes from "../../../hooks/utils/useHandleFavoritesRecipes";
import ResumeRecipeModal from "../../elements/modals/ResumeRecipeModal";
import { DivCol } from "../../elements/divs/Divs";
import {
  KEYS_EDIT_RECIPE,
  SLICE_EDIT_RECIPE,
  resetState,
  setRecipeToUpdate,
  setStepsFilledToTrue,
} from "../../../redux/slices/editRecipeSlice";
import { EDITER } from "../../../constants/routes";
import useAddTempRecipeToRecipesMutation from "../../../hooks/requests/useAddTempRecipeToRecipesMutation";
import useDeleteTempRecipeMutation from "../../../hooks/requests/useDeleteTempRecipeMutation";
import LoadingModal from "../../elements/modals/LoadingModal";

function TempRecipes() {
  const divContainerStyle = clsx(
    "w-full",
    "pb-4 md:pb-0",
    "md:pt-20 md:mb-0",
    "flex flex-col items-center justify-center",
    "overflow-hidden"
  );

  const states = useSelector((state) => state[SLICE_TEMP_RECIPES]);
  console.log('states', states)

  const getTempRecipesRequest = useGetTempRecipesMutation();

  function handleClick() {
    getTempRecipesRequest.mutate();
  }

  const handleDisplayRecipe = useHandleDisplayRecipe(
    setRecipeShown,
    setIndexRecipeShown,
    setShowResumeRecipe,
    setImageRecipeShown
  );

  const handleChangeLikeStatus = useHandleFavoritesRecipes();

  const { id } = useSelector((state) => ({
    id: state[SLICE_EDIT_RECIPE][KEYS_EDIT_RECIPE.ID],
  }));
  const dispatch = useDispatch();

  function closeModalCustom() {
    if (id !== null) {
      dispatch(resetState());
    }
  }

  const handlers = {
    handleChangeLikeStatus,
    handleDisplayRecipe,
    dispatch,
  };

  return (
    <section className={divContainerStyle}>
      <ButtonFill onClick={handleClick}>Charger recettes en attente</ButtonFill>
      {getTempRecipesRequest.isLoading && <p>Chargement...</p>}
      {!getTempRecipesRequest.isSuccess && <p>Cliquer sur le bouton</p>}
      {getTempRecipesRequest.isSuccess && (
        <TempRecipesContent values={states} handlers={handlers} />
      )}
      {states.showResumeRecipe && (
        <ResumeRecipeModal
          recipeShown={states.recipeShown}
          indexRecipeShown={states.indexRecipeShown}
          imageRecipeShown={states.imageRecipeShown}
          setShowResumeRecipe={setShowResumeRecipe}
          handleChangeLikeStatus={handleChangeLikeStatus}
          handleDisplayRecipe={handleDisplayRecipe}
          recipesToDisplay={states.tempRecipesFetched}
          closeModalCustom={closeModalCustom}
          ButtonHandleActionOnRecipe={() => (
            <ButtonsHandleRecipe
              recipeShown={states.recipeShown}
              ownRecipes={states.ownRecipesFetched}
            />
          )}
        />
      )}
    </section>
  );
}

function TempRecipesContent({ values, handlers }) {
  console.log('TempRecipesContent values', values)
  const divContainerStyle = clsx(
    "flex",
    "flex-wrap",
    "w-full",
    "h-full",
    "pt-16 md:pt-32",
    "pb-16 md:pb-0",
    "justify-center",
    "items-center"
  );

  const { dispatch, handleChangeLikeStatus, handleDisplayRecipe } = handlers;

  const handleSelectionStatus = useCallback(
    (id) => {
      dispatch(setRecipeSelected(id));
    },
    [dispatch]
  );

  const isMobile = useWindowWidth();

  return (
    <div className={divContainerStyle}>
      {values.tempRecipesFetched.length === 0 && (
        <p>Aucune recette en attente. Appuyer sur le boutton</p>
      )}
      {values.tempRecipesFetched.length > 0 && (
        <>
          {values.tempRecipesFetched.map((recipe, index) => {
            return (
              <CardRecipe
                key={recipe._id}
                recipe={recipe}
                index={index}
                openCard={handleDisplayRecipe}
                couvert={recipe.couvert}
                changeRecipeSelectionStatus={handleSelectionStatus}
                changeLikeStatus={handleChangeLikeStatus}
                selectionDisabled={true}
                isMobile={isMobile}
              />
            );
          })}
        </>
      )}
    </div>
  );
}

function ButtonsHandleRecipe({ recipeShown, ownRecipes }) {
  return (
    <DivCol add={"mt-2"}>
      {/* <UpdateRecipeButton recipeShown={recipeShown} /> */}
      <ValiderRecipeButton recipeShown={recipeShown} />
      <DeleteRecipeButton recipeShown={recipeShown} ownRecipes={ownRecipes} />
    </DivCol>
  );
}

// function UpdateRecipeButton({ recipeShown }) {
//   const dispatch = useDispatch();

//   const handleClick = useCallback(() => {
//     dispatch(setRecipeToUpdate(recipeShown));
//     dispatch(setStepsFilledToTrue());
//   }, [dispatch, recipeShown]);

//   return (
//     <ButtonFill onClick={handleClick} nav={EDITER.route}>
//       Modifier
//     </ButtonFill>
//   );
// }

function ValiderRecipeButton({ recipeShown }) {
  const addTempRecipesRequest = useAddTempRecipeToRecipesMutation();

  function handleClick() {
    addTempRecipesRequest.mutate(recipeShown._id);
  }

  return <ButtonFill onClick={handleClick}>Valider</ButtonFill>;
}

function DeleteRecipeButton({ recipeShown }) {
  const deleteTempRecipesRequest = useDeleteTempRecipeMutation();

  function handleClick() {
    deleteTempRecipesRequest.mutate(recipeShown._id);
  }

  return (
    <>
      <ButtonEmpty
        onClick={handleClick}
        add={"my-1 md:my-4"}
        col={"bg-red-400 text-white"}
      >
        Supprimer
      </ButtonEmpty>
      {deleteTempRecipesRequest.isLoading && <LoadingModal />}
    </>
  );
}

export default TempRecipes;
