import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import clsx from "clsx";
import { DivCol, DivRow } from "../divs/Divs";
import { H3, P } from "../texts/Texts";
import { Span, Spans } from "../spans/Spans";
import { DecoIcon } from "../images/IconImage";

function ImageInput({
  imageRecipe,
  setImageDataUrl,
  setImageFile,
  setFileName,
  setFileSizeError,
}) {
  const dispatch = useDispatch();
  const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB

  

  const handleFileChange = useCallback(
    (event) => {
      const file = event.target.files[0];
      if (file) {
        if (file.size > MAX_FILE_SIZE) {
          dispatch(
            setFileSizeError("La taille de l'image ne doit pas dépasser 2 Mo.")
          );
          dispatch(setFileName(""));
          dispatch(setImageDataUrl(null));
          return;
        }
        dispatch(setImageFile(file));
        const reader = new FileReader();
        reader.onloadend = () => {
          dispatch(setImageDataUrl(reader.result));
        };
        reader.readAsDataURL(file);
        dispatch(setFileName(file.name));
        dispatch(setFileSizeError(null));
      }
    },
    [dispatch, setImageDataUrl, setFileName, setFileSizeError, MAX_FILE_SIZE, setImageFile]
  );

  function deleteImage() {
    dispatch(setImageDataUrl(null));
    dispatch(setFileName(""));
    dispatch(setFileSizeError(null));
  }

  return (
    <DivCol>
      <H3>{imageRecipe.titleInput}</H3>
      <label
        className={clsx(
          "rounded mt-2 p-2 bg-sky-300 cursor-pointer",
          "font-mitr",
          "text-sm sm:text-base",
          "font-normal",
          "text-white"
        )}
        htmlFor="file_input"
        testid="image_upload_label"
      >
        Choisir une image
      </label>
      <input
        type="file"
        accept="image/*"
        className="hidden"
        id="file_input"
        onChange={handleFileChange}
        testid="image_upload_input"
      />
      <P add="pt-2">MAX. 5Mo</P>
      <Spans>
        {imageRecipe.fileName && (
          <Span>
            <DivRow>
              {imageRecipe.fileName}
              <button onClick={deleteImage}>
                <DecoIcon
                  iconName={"icons8-remove"}
                  add={"cursor-pointer"}
                  size={"h-6 w-6"}
                />
              </button>
            </DivRow>
          </Span>
        )}
        {imageRecipe.fileSizeError && (
          <Span>La taille de l'image ne doit pas dépasser 2 Mo.</Span>
        )}
      </Spans>
    </DivCol>
  );
}

export default ImageInput;
