import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setStepFilled, ETAPES_STEP } from "../../redux/slices/editRecipeSlice";

export function useIsEtapesEditRecipeFilled(etapesList) {
  const dispatch = useDispatch();
  
  useEffect(() => {
    if(etapesList.every((etape) => etape.length >= 10)) {
      dispatch(setStepFilled({stepIndex: ETAPES_STEP, isFilled: true}));
    } else {
      dispatch(setStepFilled({stepIndex: ETAPES_STEP, isFilled: false}));
    }
  }, [etapesList, dispatch]);
}

export default useIsEtapesEditRecipeFilled;