import React from 'react'
import clsx from 'clsx'

export const SectionCol = ({children, add}) => {
  const sectionColStyle = clsx(
    "flex",
    "flex-col",
    "items-center",
    "justify-center",
    "w-full",
    "h-full",
    "px-4",
    add ? add : ""
  )
  return (
    <section className={sectionColStyle}>
      {children}
      </section>
  )
}