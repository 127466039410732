import useForgotPwdMutation from "../../hooks/requests/useForgotPwdMutation";
import useAuthentFieldsOk from "../../hooks/utils/useAuthentFieldsOk";
import { ButtonEmpty, ButtonFill } from "../elements/bouttons/Buttons";
import { DivCol } from "../elements/divs/Divs";
import {
  AuthEmailFieldAndError,
} from "../elements/fields/EmailFields";
import { FormAuth } from "../elements/forms/Forms";
import { SectionCol } from "../elements/sections/Sections";
import { Span } from "../elements/spans/Spans";
import { H1, H2 } from "../elements/texts/Texts";
import { LOGIN } from "../../constants/routes";
import { useSelector } from "react-redux";
import {
  SLICE_LOGIN_DATA,
  KEYS_LOGIN_DATA,
} from "../../redux/slices/loginDataSlice";

function ForgotPwd() {
  return (
    <SectionCol add="pt-12 space-y-8">
      <Title />
      <FormContent />
    </SectionCol>
  );
}

function Title() {
  return (
    <>
      <H1>Mot de passe oublié</H1>
      <H2>Renseignez votre adresse email</H2>
    </>
  );
}

function FormContent() {
  const forgotPwdMutation = useForgotPwdMutation();
  const { email } = useSelector((state) => ({
    email: state[SLICE_LOGIN_DATA][KEYS_LOGIN_DATA.EMAIL],
  }));
  
  async function onSubmit() {
    forgotPwdMutation.mutate({ email: email.value });
  }

  return (
    <FormAuth>
      <AuthEmailFieldAndError FieldMessages={FieldMessages} forgotPwdMutation={forgotPwdMutation} />
      <SubmitAndLoginButtons onSubmit={onSubmit} />
    </FormAuth>
  );
}


function FieldMessages({ resMutation }) {
  return (
    <>
      {resMutation.isSuccess && (
        <Span add="text-center">
          Lien pour mettre à jour votre mot de passe envoyé !
        </Span>
      )}
      {resMutation.isLoading && <Span>En attente du serveur...</Span>}
    </>
  );
}

function SubmitAndLoginButtons({ onSubmit }) {
  return (
    <DivCol add="pt-8 w-2/4 space-y-4">
      <SubmitButton onSubmit={onSubmit} />
      <LoginButton />
    </DivCol>
  );
}

function SubmitButton({ onSubmit }) {
  const { forgotPwdFormOk } = useAuthentFieldsOk();

  return (
    <ButtonFill
      type="submit"
      add="w-44"
      onClick={onSubmit}
      disabled={!forgotPwdFormOk}
      testid="forgot"
    >
      Envoyer
    </ButtonFill>
  );
}

function LoginButton() {
  return (
    <ButtonEmpty add="w-44" nav={LOGIN.route} testid="back_login">
      {LOGIN.name}
    </ButtonEmpty>
  );
}

export default ForgotPwd;
