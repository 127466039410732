import React from "react";
import { DivRow } from "../divs/Divs";
import { Span } from "../spans/Spans";
import clsx from "clsx";
import {
  INFOS_STEP,
  INGREDIENTS_STEP,
  ETAPES_STEP,
  setCurrentStep,
} from "../../../redux/slices/editRecipeSlice";
import { useDispatch } from "react-redux";
import { DecoIcon } from "../images/IconImage";

function ProgressBar({ steps}) {
  return (
    <>
      <Bar nbrOfSteps={steps.list.length} valueCurrentStep={steps.current} />
      <StepsInfosBar steps={steps} />
    </>
  );
}

function Bar({ nbrOfSteps, valueCurrentStep }) {
  const stepWidth = 100 / nbrOfSteps;

  return (
    <div className="w-full h-2 bg-gray-300 mt-3 rounded-xl">
      <div
        className="h-2 bg-sky-600 transition-all duration-500 ease-in-out rounded-xl"
        style={{ width: `${stepWidth * (valueCurrentStep + 1)}%` }}
        testid="progress_bar"
      />
    </div>
  );
}

function StepsInfosBar({ steps }) {
  const dispatch = useDispatch();

  function handleNavToStep(stepTarget) {
    if (stepTarget === INFOS_STEP)
      dispatch(setCurrentStep(INFOS_STEP));

    if (stepTarget === INGREDIENTS_STEP && steps.list[INFOS_STEP].isFilled)
      dispatch(setCurrentStep(INGREDIENTS_STEP));

    if (stepTarget === ETAPES_STEP && steps.list[INFOS_STEP].isFilled && steps.list[INGREDIENTS_STEP].isFilled)
      dispatch(setCurrentStep(ETAPES_STEP));
  }

  return (
    <DivRow add="mt-4 w-full" ju="justify-between sm:justify-around">
      {steps.list.map((step, index) => {
        return(
          <StepInfo
          key={step.name}
          stepItem={step}
          steps={steps}
          index={index}
          handleNavToStep={handleNavToStep}
          />
          )
        }
      )}
    </DivRow>
  );
}

const StepInfo = React.memo(function StepInfo({ stepItem, steps, index, handleNavToStep }) {

  const isActualStep = stepItem.current === steps.current;
  const isStepItemZero = stepItem.current === 0;
  const isPreviousFilled = isStepItemZero ? true : steps.list[stepItem.current-1].isFilled;
  
  const divStyle= clsx(
    isActualStep ? "scale-110 cursor-pointer" : isPreviousFilled ? "scale-100 cursor-pointer" : "scale-75",
  )

  const spanStyle = clsx(
    isActualStep ? "font-semibold text-sky-600 " : isPreviousFilled ? "text-sky-600" : "text-gray-300"
  )


  function handleClick() {
    if (isPreviousFilled) {
      handleNavToStep(index)
    }
  }

  return (
    <DivRow add={divStyle}>
      <DecoIcon
        iconName={stepItem.logo}
        altName={stepItem.name}
        size="h-8 w-8"
      />
      <Span add={spanStyle} onClick={handleClick}>
        {stepItem.name}
      </Span>
    </DivRow>
  );
});

export default ProgressBar;
