import { SLICE_PROFILE_PAGE, KEYS_PROFILE_PAGE, USER_RECIPES_CREATED } from '../../../redux/slices/profilePageSlice'
// import ProfileRecipesNavBar from '../../elements/navbars/ProfileRecipesNavBar'
import { useSelector } from 'react-redux'
import ProfileOwnRecipes from './ProfileOwnRecipes'
// import ProfileFavoriteRecipes from './ProfileFavoriteRecipes'

function ProfileRecipes() {
  const { recipesToShow } = useSelector((state) => ({
    recipesToShow: state[SLICE_PROFILE_PAGE][KEYS_PROFILE_PAGE.RECIPES_TO_SHOW],
  }));

  return (
    <>
      {recipesToShow === USER_RECIPES_CREATED && <ProfileOwnRecipes />}
      {/* {recipesToShow === FAVORITES_RECIPES_USER && <ProfileFavoriteRecipes />} */}
    </>
  )
}

export default ProfileRecipes