import React from "react";
import { useSelector } from "react-redux";
import { isValidEmail } from "../../../functions/utils/isValidEmail";
import {
  setEmailAuth,
  KEYS_LOGIN_DATA,
  SLICE_LOGIN_DATA,
} from "../../../redux/slices/loginDataSlice";
import { Span, Spans } from "../spans/Spans";
import { useLocation } from "react-router-dom";
import { REGISTER } from "../../../constants/routes";
import FieldText from "./FieldText";

export const AuthEmailFieldAndError = React.memo(function AuthEmailFieldAndError({ FieldMessages, forgotPwdMutation }) {
  const { email, existingEmailRegister } = useSelector((state) => ({
    email: state[SLICE_LOGIN_DATA][KEYS_LOGIN_DATA.EMAIL],
    existingEmailRegister:
      state[SLICE_LOGIN_DATA][KEYS_LOGIN_DATA.EXISTING_EMAIL_REGISTER],
  }));

  return (
    <>
      <AuthEmailField email={email}/>
      <Spans>
        <AuthEmailFieldError email={email} existingEmailRegister={existingEmailRegister}/>
        {FieldMessages && <FieldMessages resMutation={forgotPwdMutation} />}
      </Spans>
    </>
  );
});


export function AuthEmailField({email}) {
const fieldIsOk = email.value ? isValidEmail(email.value) : false;

  return (
    <>
      <FieldText state={email} action={setEmailAuth} fieldIsOk={fieldIsOk}/>
    </>
  );
}

export function AuthEmailFieldError({email, existingEmailRegister}) {
  const pathname = useLocation().pathname;

  return (
    <>
      {email.value.length > 0 && !isValidEmail(email.value) && (
        <Span> Format de l'email incorrect</Span>
      )}

      {pathname === REGISTER.route && existingEmailRegister && (
        <Span>Cette adresse email est déjà utilisée</Span>
      )}
    </>
  );
}