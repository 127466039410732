import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  KEYS_LOGIN_DATA,
  SLICE_LOGIN_DATA,
} from "../../redux/slices/loginDataSlice";
import { isValidEmail } from "../../functions/utils/isValidEmail";

function useAuthentFieldsOk() {
  const { email, author, pwd, cpwd } = useSelector((state) => ({
    email: state[SLICE_LOGIN_DATA][KEYS_LOGIN_DATA.EMAIL],
    author: state[SLICE_LOGIN_DATA][KEYS_LOGIN_DATA.AUTHOR],
    pwd: state[SLICE_LOGIN_DATA][KEYS_LOGIN_DATA.PWD],
    cpwd: state[SLICE_LOGIN_DATA][KEYS_LOGIN_DATA.CONFIRM_PWD],
  }));

  const [loginFormOk, setloginFormOk] = useState(false);
  const [registerFormOk, setRegisterFormOk] = useState(false);
  const [forgotPwdFormOk, setForgotPwdFormOk] = useState(false);

  useEffect(() => {
    if (isValidEmail(email.value) && pwd.value.length >= 6) {
      setloginFormOk(true);
    }
    else {
      setloginFormOk(false);
    }
  }, [email.value, pwd.value]);

  useEffect(() => {
    if (
      isValidEmail(email.value) &&
      author.value.length >= 3 &&
      pwd.value.length >= 6 &&
      pwd.value === cpwd.value
    ) {
      setRegisterFormOk(true);
    }
    else {
      setRegisterFormOk(false);
    }
  }, [email.value, author.value, pwd.value, cpwd.value]);

  useEffect(() => {
    if (isValidEmail(email.value)) {
      setForgotPwdFormOk(true);
    }
    else {
      setForgotPwdFormOk(false);
    }
  }, [email.value]);

  return { loginFormOk, registerFormOk, forgotPwdFormOk };
}

export default useAuthentFieldsOk;
