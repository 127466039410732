import { useDispatch, useSelector } from "react-redux";
import { useMutation } from "react-query";
import useError from "../utils/useError";
import { setOwnRecipesFetched, setShowResumeRecipe } from '../../redux/slices/ownRecipesSlice';
import { delete_own_recipes } from '../../functions/requests/axiosRequests';
import { KEYS_RECIPES, SLICE_RECIPES, setRecipesFetched } from '../../redux/slices/recipesSlice';
import { KEYS_RECIPES_TO_COOK, SLICE_RECIPES_TO_COOK, setARecipeToCookIsUpdated, setRecipesToCookFetched } from '../../redux/slices/recipesToCookSlice';


function useDeleteOwnRecipeMutation(ownRecipes) {
  const dispatch = useDispatch();
  const { setError } = useError();
  const { recipesFetched } = useSelector((state) => ({
    recipesFetched: state[SLICE_RECIPES][KEYS_RECIPES.RECIPES_FETCHED]
  }));

  const {recipesToCookFetched} = useSelector((state) => ({
    recipesToCookFetched: state[SLICE_RECIPES_TO_COOK][KEYS_RECIPES_TO_COOK.RECIPES_TO_COOK_FETCHED]
  }));

  const deleteOwnRecipeMutation = useMutation(
    "delete_own_recipes",
    delete_own_recipes,
    {
      onSuccess: (data, variables) => {

        const remainingOwnRecipes = ownRecipes.filter(
          (recipe) => !variables.recipesToDelete.includes(recipe._id.toString())
          );
        const remainingRecipes = recipesFetched.filter(
          (recipe) => !variables.recipesToDelete.includes(recipe._id.toString())
          );
          const remainingRecipesToCook = recipesToCookFetched.filter(
            (recipe) => !variables.recipesToDelete.includes(recipe._id.toString())
            );

          dispatch(setOwnRecipesFetched(remainingOwnRecipes));
          dispatch(setRecipesFetched(remainingRecipes));
          dispatch(setARecipeToCookIsUpdated(true));
          dispatch(setRecipesToCookFetched(remainingRecipesToCook));
          dispatch(setShowResumeRecipe(false));
      },
      onError: (error) => {
        setError(error.response?.data?.message || "Une erreur s'est produite dans la mise à jour de vos recettes, réessayer");
      }
})
return deleteOwnRecipeMutation;
}

export default useDeleteOwnRecipeMutation