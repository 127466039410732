import React from 'react'
import { useQueryClient } from 'react-query'
import { H3 } from '../../elements/texts/Texts'
import { ButtonFill } from '../../elements/bouttons/Buttons'

function ProfileRecipesErrorToReload({queryKeyToInvalidate}) {
  
  const queryClient = useQueryClient()

  function handleClick() {
    queryClient.invalidateQueries(queryKeyToInvalidate)
  }

  return (
    <div className='flex flex-col justify-center items-center'>
      <H3>Une erreur est survenue</H3>
      <ButtonFill add={"mt-4"} onClick={handleClick} >Recharger</ButtonFill>
    </div>
  )
}

export default ProfileRecipesErrorToReload