import {useQuery} from 'react-query'
import { useDispatch } from 'react-redux';
import {get_recipes_to_cook} from '../../functions/requests/axiosRequests'
import useError from '../utils/useError';
import { setRecipesToCookFetched } from '../../redux/slices/recipesToCookSlice';

function useRecipesToCookQuery() {
  const dispatch = useDispatch();
  const { setError } = useError();

  const recipesToCookQuery = useQuery(
    "get_recipes_to_cook",
    get_recipes_to_cook,
    {
      staleTime: 1000 * 60 * 60, // 1 heure
      refetchOnWindowFocus: false,
      retry: 0,
      onSuccess: (data) => {
        const recipesToCook = data.recipesToCook.map((recipeToCook) => {
          return{...recipeToCook, selected: false}
        });
        dispatch(setRecipesToCookFetched(recipesToCook));
      },
      onError: (error) => {
        setError(error.response?.data?.message || "Une erreur s'est produite");
      },
    }
  );

  return recipesToCookQuery
}

export default useRecipesToCookQuery