import React from "react";
import clsx from "clsx";

function GlobalModal({children, zindex}) {
  const divBackgroundStyle = clsx(
    "fixed",
    zindex ? `z-${zindex}` : "z-40",
    "top-0 left-0",
    "h-screen w-screen",
    "bg-white bg-opacity-80",
    "flex justify-center items-center"
  );

  return (
    <div className={divBackgroundStyle}>{children}</div>
  );
}

export default GlobalModal;