import React, { useCallback, useEffect, useState } from "react";
import useFilterFood from "../../../hooks/utils/useFilterFood";
import {
  SLICE_EDIT_RECIPE,
  KEYS_EDIT_RECIPE,
  setAddIngredient,
} from "../../../redux/slices/editRecipeSlice";
import { useDispatch, useSelector } from "react-redux";
import { DivCol } from "../../elements/divs/Divs";
import { H2 } from "../../elements/texts/Texts";
import { TextInput } from "../../elements/inputs/TextInputs";
import FoodsFoundListModal from "../../elements/modals/FoodListModal";
import GroupOfSelectedIngredients from "./GroupOfSelectedIngredients";
import { useIsIngredientsEditRecipeFilled } from "../../../hooks/utils/useIsIngredientsEditRecipeFilled";

function IngredientsEditRecipe() {
  const { ingredients } = useSelector((state) => ({
    ingredients: state[SLICE_EDIT_RECIPE][KEYS_EDIT_RECIPE.INGREDIENTS],
  }));

  useIsIngredientsEditRecipeFilled(ingredients.value.length);

  return (
    <DivCol add="w-full sm:w-3/4 md:w-2/3 lg:w-1/2">
      <H2 add="pb-4">Ingrédients de la recette</H2>
      <SearchFoodField ingredients={ingredients} />
      <GroupOfSelectedIngredients ingredientsList={ingredients.value} />
    </DivCol>
  );
}

function SearchFoodField({ ingredients }) {
  const dispatch = useDispatch();

  const textInputSearchFoodData = {
    title: "Aliments",
    name: "searchFood",
    placeholder: "Rechercher un aliment",
    logo: require("../../../assets/icons/deco_icons/icons8-foods.svg").default,
  };

  const [searchingFoodName, setSearchingFoodName] = useState("");
  const [foodsFound, setFoodsFound] = useState([]);

  function updateSearchingFoodName(value) {
    setSearchingFoodName(value);
  }

  const filteredFoodFound = useFilterFood(searchingFoodName, ingredients.value);

  const addFoodFoundOnClick = useCallback(
    (food) => {
      const newIngredient = {
        ...food,
        quantity: food.step,
        min: 1,
      };
      dispatch(setAddIngredient(newIngredient));
      setSearchingFoodName("");
    },
    [dispatch, setSearchingFoodName]
  );

  useEffect(() => {
    if (searchingFoodName.length >= 3) {
      setFoodsFound(filteredFoodFound);
    }
  }, [filteredFoodFound, searchingFoodName]);

  return (
    <label className="relative w-full block">
      <TextInput
        item={textInputSearchFoodData}
        value={searchingFoodName}
        onTextChange={updateSearchingFoodName}
      />
      {searchingFoodName.length >= 3 && (
        <FoodsFoundListModal
          selectFood={addFoodFoundOnClick}
          foodsFound={foodsFound}
        />
      )}
    </label>
  );
}

export default IngredientsEditRecipe;
