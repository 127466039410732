import clsx from "clsx";

export const sH1 = clsx(
  "font-rubik",
  "text-3xl sm:text-5xl",
  "font-extrabold",
  "text-sky-300",
);

export const sH2 = clsx(
  "text-xl sm:text-3xl",
  "font-extrabold",
  "font-rubik",
  "text-sky-600",
);

export const sH3 = clsx(
  "font-mitr",
  "text-base sm:text-lg",
  "font-medium",
  "text-sky-800",
);

export const sP = clsx(
  "font-mitr",
  "text-sm sm:text-base",
  "font-normal",
  "text-sky-500",
);

export const sFcJcIc = clsx(
  "flex",
  "flex-col",
  "items-center",
  "justify-center",
);

export const sFcJc = clsx(
  "flex",
  "flex-col",
  "justify-center",
);

export const sFrJcIc = clsx(
  "flex",
  "flex-row",
  "items-center",
  "justify-center",
);
