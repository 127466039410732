import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { SLICE_RECIPES, KEYS_RECIPES, setAllRecipesDeselected, setAllRecipesSelected, setIsAllRecipesSelected } from '../../../redux/slices/recipesSlice';
import SelectionCheckBox from '../../elements/checkboxs/SelectionCheckBox';

function FullSelectionButton() {
  const dispatch = useDispatch();
  
  // const { recipesFetched } =
  // useSelector((state) => ({
  //   recipesFetched: state[SLICE_RECIPES][KEYS_RECIPES.RECIPES_FETCHED],
  // }));
  const recipesFetched = useSelector(
    (state) => state[SLICE_RECIPES][KEYS_RECIPES.RECIPES_FETCHED]
  );

  const [selected, setSelected] = useState(recipesFetched.some((recipe) => recipe.selected === false));

  useEffect(() => {
    if(recipesFetched.some((recipe) => recipe.selected === false)) {
      setSelected(false);
    }
    else {
      setSelected(true);
    }
  }, [recipesFetched]);
  

  function handleClick() {
    if(!selected) {
      handleAllSelected();
    }
    else {
      handleAllDeselected();
    }
  }

  function handleAllSelected() {
    dispatch(setAllRecipesSelected());
    dispatch(setIsAllRecipesSelected(true));
  }

  function handleAllDeselected() {
    dispatch(setAllRecipesDeselected());
    dispatch(setIsAllRecipesSelected(false));
  }

  return (
      <SelectionCheckBox selected={selected} 
        handleClick={handleClick}
        />
  );
}

export default FullSelectionButton