const ErrorCode = Object.freeze({
  SERVER_ERROR_PROCESSING_REQUEST: "Erreur du serveur lors du traitement de la requête.",
  
  // TOKEN ERR
  NO_AUTHORIZATION_HEADER: "Il n'y a pas de header Authorization dans l'en-tête de la requête.",
  NO_AUTHORIZATION_COOKIE: "Il n'y a pas de cookie dans la requête.",
  NO_TOKEN_PROVIDED: "Il n'y a pas de token dans le header Authorization.",
  UNAUTHORIZED: "L'utilisateur n'est pas autorisé à accéder à la ressource.",
  
  // USER ERR
  LINK_NOT_CREATED: "Le lien n'a pas pu être créé.",
  LINK_CONFIRM_ACCOUNT_EXPIRED: "Ce lien de confirmation de compte a expiré.",
  LINK_CONFIRM_ACCOUNT_EXPIRED_NEW_LINK: "Le lien de confirmation de compte a expiré. Un nouveau lien de confirmation a été envoyé à votre adresse mail.",
  LINK_CHANGE_PWD_EXPIRED: "Ce lien de changement de mot de passe a expiré.",
  NEW_EMAIL_SEND_FAILED: "L'email de confirmation de compte n'a pas pu être renvoyé.",
  LOGOUT_FAILED: "La déconnexion a échoué.",

  ACCOUNT_DELETION_FAILED: "Le compte n'a pas pu être supprimé.",
  BODY_DATA_MISSING: "Le body ne contient pas les données attendues.",
  
  EMAIL_OR_USERNAME_ALREADY_EXISTS: "Email ou pseudo déjà existant.",
  EMAIL_OR_PASSWORD_INCORRECT: "Email ou mot de passe incorrect.",
  ACCOUNT_WAIT_CONFIRM: "Le compte est en attente de validation.",
  NEW_EMAIL_ALREADY_EXISTS: "La nouvelle adresse mail est déjà utilisée.",
  
  REGISTRATION_INCOMPLETE: "La requête ne peut pas aboutir car la finalisation d'inscription n'est pas terminée.",
  EMAIL_SEND_FAILED: "L'email n'a pas pu être envoyé.",
  
  USER_NOT_FOUND: "L'utilisateur demandé n'a pas été trouvé avec les informations transmises.",
  PROFILE_INFO_FETCH_FAILED: "La récupération des informations du profil a échoué.",
  
  BAD_PWD: "Le mot de passe est incorrect.",
  BAD_EMAIL: "L'email est incorrect.",
  PASSWORD_UPDATE_FAILED: "La mise à jour du mot de passe à échouée.",
  EMAIL_UPDATE_FAILED: "La mise à jour de l'adresse mail à échouée.",
  
  REFRESH_TOKEN_CREATION_FAILED: "Echec lors de la création du refresh token.",
  REFRESH_TOKEN_DELETION_FAILED: "Echec lors de la suppression du refresh token.",
  
  ACCESS_TOKEN_CREATION_FAILED: "Echec lors de la création de l'access token.",
  ACCESS_TOKEN_DELETION_FAILED: "Echec lors de la suppression de l'access token.",
  
  USER_ACCOUNT_ALREADY_CONFIRMED: "L'utilisateur a déjà confirmé son compte.",
  USER_ACCOUNT_NOT_CONFIRMED:"Le compte n'a pas pu être confirmé.",
  
  ADD_RECIPESTOCOOK_USER_FAILED:"L'ajout des nouvelles recettes à cuisiner a échoué.",
  DELETE_RECIPESTOCOOK_USER_FAILED:"La suppression des recettes à cuisiner a échoué.",
  GET_RECIPESTOCOOK_USER_FAILED:"La récupération des recettes à cuisiner a échoué.",
  
  ADD_OWNRECIPE_USER_FAILED:"L'ajout de votre propre recette a échoué.",
  DELETE_OWNRECIPE_USER_FAILED:"La suppression de vos propres recettes a échoué.",
  GET_OWNRECIPE_USER_FAILED:"La récupération de vos recettes créées a échoué.",

  ADD_FAVORITE_RECIPE_USER_FAILED:"L'ajout de la recette en favoris a échoué.",
  GET_FAVORITE_RECIPE_USER_FAILED:"La récupération des recettes en favoris a échoué.",
  DELETE_FAVORITE_RECIPE_USER_FAILED:"La suppression de la recette en favoris a échoué.",

  ADD_FOODS_IN_LIST_FAILED: "L'ajout des aliments dans la liste a échoué.",
  DELETE_FOODS_IN_LIST_FAILED: "La suppression des aliments dans la liste a échoué.",
  UPDATE_FOODS_IN_LIST_FAILED: "La mise à jour des aliments dans la liste a échoué.",
  GENERATE_FOODS_IN_LIST_FAILED: "La génération des aliments dans la liste a échoué.",

  
  POPULATE_FAILED:"La population des données utilisateur a échoué.",

  // FOOD ERR
  FETCH_FOODS_FAILED: "La récupération de la liste des aliments a échoué.",

  // RECIPE ERR
  ADD_RECIPE_FAILED: "L'ajout de la récette a échoué.",
  DELETE_RECIPE_FAILED: "La suppression de la recette a échoué.",
  UPDATE_RECIPE_FAILED: "La mise à jour de la recette a échoué.",
  GET_RECIPES_FAILED: "Il n'y a pas de recettes correspondantes.",
  GET_TEMP_RECIPE_FAILED: "La récupération des recettes temporaires a échoué.",
});

module.exports = ErrorCode;