import React from "react";
import clsx from "clsx";
import { PROFILE, routesHeaderFct } from "../../../constants/routes";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { SLICE_USER_DATA, KEYS_USER_DATA } from "../../../redux/slices/userDataSlice";

const HorizontalNav = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const {avatar} = useSelector((state) => ({
    avatar: state[SLICE_USER_DATA][KEYS_USER_DATA.AVATAR]
  }))
  
  //#region styles
  const navStyle = clsx(
    "hidden sm:flex sm:flex-row",
    "sm:justify-center",
    "sm:items-center",
    "sm:space-x-2 md:space-x-6 lg:space-x-16"
  );

  const buttonsNavStyle = clsx(
    "flex flex-row items-center justify-center overflow-visible",
    "h-12 sm:h-14 md:h-16 lg:h-18",
    "w-12 sm:w-14 md:w-16 lg:w-18",
    "bg-lime-100 hover:bg-sky-100",
    "rounded-3xl hover:rounded-xl",
    "shadow-md",
    "transition-all duration-100 ease-linear",
    "relative",
    "group"
  );

  const activeButtonNavStyle = clsx("border border-slate-900", "scale-110");

  const imgsStyle = clsx(
    "h-10 sm:h-12 md:h-14 lg:h-16",
    "w-10 sm:w-12 md:w-14 lg:w-16"
  );

  const spansNavStyle = clsx(
    "absolute",
    "bg-sky-100 text-sky-700",
    "rounded-md",
    "w-auto",
    "px-7 py-2",
    "top-[65px]",
    "text-xl",
    "font-spin",
    "transition-all duration-100 scale-0 origin-top",
    "group-hover:scale-100"
  );

  const activeSpanNavStyle = clsx("border border-slate-900");

  //#endregion
  
  return (
    <nav className={navStyle}>
      {routesHeaderFct.map((route) => (
        <button
          key={route.name}
          onClick={() => navigate(route.route)}
          className={clsx(
            buttonsNavStyle,
            location.pathname === route.route ? activeButtonNavStyle : ""
          )}
          testid={`button_${route.route}`}
        >
          {route.name === PROFILE.name && (
              <img
              src={require(`../../../assets/icons/profile_icons/${avatar}.svg`)}
              className={imgsStyle}
              alt={`logo_${route.name}`}
              />
          )}
          {route.name !== PROFILE.name && (
            <img
              src={route.logo}
              className={imgsStyle}
              alt={`logo_${route.name}`}
            />
          )}
          <span
            className={clsx(
              spansNavStyle,
              location.pathname === route.route ? activeSpanNavStyle : ""
            )}
          >
            {route.name}
          </span>
        </button>
      ))}
    </nav>
  );
};

export default HorizontalNav;
