import { createSlice } from "@reduxjs/toolkit";

export const SLICE_RECIPES = "recipes";
export const KEYS_RECIPES = {
  RECIPES_QUERY_DONE: "recipesQueryDone",
  RECIPES_FETCHED: "recipesFetched",
  NO_RECIPE_FOUND: "noRecipeFound",
  IS_ALL_RECIPES_SELECTED: "isAllRecipesSelected",
  WHO_IS_DISPLAY: "whoIsDisplay",
  FILTERS_DISPLAYED: "filtersDisplayed",
  SHOW_RESUME_RECIPE: "showResumeRecipe",
  RECIPE_SHOWN: "recipeShown",
  INDEX_RECIPE_SHOWN: "indexRecipeShown",
  IMAGE_RECIPE_SHOWN: "imageRecipeShown",
  DISPLAY_SUCCESS: "displaySuccess",
  IS_END_OF_LIST: "isEndOfList",
  SCROLL_TO_BOTTOM: "scrollToBottom",
};

export const RECIPES_DISPLAYED = "recipesDisplayed";
export const FILTERS_DISPLAYED = "filtersDisplayed";
export const INFOS_DISPLAYED = "infosDisplayed";
export const INGREDIENTS_DISPLAYED = "ingredientsDisplayed";

const initialState = {
  recipesQueryDone: false,
  recipesFetched: [],
  noRecipeFound: false,
  isAllRecipesSelected: false,
  whoIsDisplay: RECIPES_DISPLAYED,
  filtersDisplayed: INFOS_DISPLAYED,
  showResumeRecipe: false,
  recipeShown: null,
  indexRecipeShown: null,
  imageRecipeShown: null,
  displaySuccess: false,
  isEndOfList: false,
  scrollToBottom: false,
};

const recipesSlice = createSlice({
  name: SLICE_RECIPES,
  initialState,
  reducers: {
    setRecipesQueryDone: (state, action) => {
      state.recipesQueryDone = true;
    },
    setRecipesFetched: (state, action) => {
      state.recipesFetched = action.payload;
    },
    setAddRecipesToRecipesFetched: (state, action) => {
      state.recipesFetched = [...state.recipesFetched, ...action.payload];
    },
    setNoRecipeFound: (state, action) => {
      state.noRecipeFound = action.payload;
    },
    setRecipeSelected: (state, action) => {
      const recipeId = action.payload;
      state.recipesFetched = state.recipesFetched.map(recipe => {
        if (recipe._id === recipeId) {
          state.recipeShown = {...state.recipeShown, selected: !recipe.selected};
          return {...recipe, selected: !recipe.selected};
        }
        return recipe;
      });
    },
    setAllRecipesSelected: (state, action) => {
      state.recipesFetched = state.recipesFetched.map((recipe) => ({
        ...recipe,
        selected: true,
      }));
    },
    setAllRecipesDeselected: (state, action) => {
      state.recipesFetched = state.recipesFetched.map((recipe) => ({
        ...recipe,
        selected: false,
      }));
    },
    setIsAllRecipesSelected: (state, action) => {
      state.isAllRecipesSelected = action.payload;
    },
    setRecipeLiked: (state, action) => {
      const recipeId = action.payload;
      state.recipesFetched = state.recipesFetched.map(recipe => {
        if (recipe._id === recipeId) {
          return {...recipe, isLiked: !recipe.isLiked};
        }
        return recipe;
      });
    },
    setWhoIsDisplay: (state, action) => {
      state.whoIsDisplay = action.payload;
    },
    setFiltersDisplay: (state, action) => {
      state.filtersDisplayed = action.payload;
    },
    setShowResumeRecipe: (state, action) => {
      state.showResumeRecipe = action.payload;
    },
    setRecipeShown: (state, action) => {
      state.recipeShown = action.payload;
    },
    setIndexRecipeShown: (state, action) => {
      state.indexRecipeShown = action.payload;
    },
    setImageRecipeShown: (state, action) => {
      state.imageRecipeShown = action.payload;
    },
    setDisplaySuccess: (state, action) => {
      state.displaySuccess = action.payload;
    },
    setIsEndOfList: (state, action) => {
      state.isEndOfList = action.payload;
    },
    setScrollToBottom: (state, action) => {
      state.scrollToBottom = action.payload;
    }
  },
});

export const {
  setRecipesQueryDone,
  setRecipesFetched,
  setAddRecipesToRecipesFetched,
  setNoRecipeFound,
  setRecipeSelected,
  setAllRecipesSelected,
  setAllRecipesDeselected,
  setIsAllRecipesSelected,
  setRecipeLiked,
  setWhoIsDisplay,
  setFiltersDisplay,
  setShowResumeRecipe,
  setRecipeShown,
  setIndexRecipeShown,
  setImageRecipeShown,
  setDisplaySuccess,
  setIsEndOfList,
  setScrollToBottom
} = recipesSlice.actions;

export default recipesSlice.reducer;
