import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  errorIsDisplayed: false,
  errorDescription: ""
}

export const SLICE_ERRORS = "errorsApp";
export const ERROR_IS_DISPLAYED = "errorIsDisplayed"
export const ERROR_DESCRIPTION = "errorDescription"

const errorsSlice = createSlice({
  name: SLICE_ERRORS,
  initialState,
  reducers: {
    setErrorsIsDisplayed: (state, action) => {
      state.errorIsDisplayed = action.payload;
    },
    setErrorDescription: (state, action) => {
      state.errorDescription = action.payload;
    },
  }
});

export const { 
  setErrorsIsDisplayed,
  setErrorDescription
  } = errorsSlice.actions;

export default errorsSlice.reducer;