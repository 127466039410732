import React from "react";
import clsx from "clsx";

export function FoodIcon({ iconName, altName, add, size }) {
  const styleImg = clsx(
    size ? size : "h-10 w-10",
    add ? add : ""
  )

  return (
    <img src={require(`../../../assets/icons/food_icons/${iconName}.svg`)} 
        alt={altName}
        className={styleImg}
    />
  );
}


export function DecoIcon({ iconName, altName, add, size, onClick=null }) {
  const styleImg = clsx(
    size ? size : "h-10 w-10",
    add ? add : "",
    onClick ? "cursor-pointer" : ""
  )

  return (
    <img src={require(`../../../assets/icons/deco_icons/${iconName}.svg`)} 
        alt={`icon_${altName}`}
        className={styleImg}
        onClick={onClick}
    />
  );
}