import React from "react";
import { ButtonNumberInputStore } from "../inputs/ButtonNumberInput";
import { useSelector } from "react-redux";
import { RANDOM_FETCH, setNumberOfRecipeToFetchInRandom } from "../../../redux/slices/filtersRecipesSlice";

function FieldNumberRandomRecipes() {
  const typeOfFetch = useSelector((state) => state.filtersRecipes.typeOfFetch);

  const numberOfRecipeToFetchInRandom = useSelector(
    (state) => state.filtersRecipes.numberOfRecipeToFetchInRandom
  );

  return (
    <>
      {typeOfFetch.value === RANDOM_FETCH && (
        <ButtonNumberInputStore state={numberOfRecipeToFetchInRandom} action={setNumberOfRecipeToFetchInRandom} />
      )}
    </>
  );
}

export default FieldNumberRandomRecipes;
