import React, { useCallback, useEffect, useMemo, useState } from "react";
import useOwnRecipesQuery from "../../../hooks/requests/useOwnRecipesQuery";
import { DivCol } from "../../elements/divs/Divs";
import { LoadingDiv } from "../../elements/loadings/Loadings";
import ProfileRecipesErrorToReload from "./ProfileRecipesErrorToReload";
import { useDispatch, useSelector } from "react-redux";

import CardRecipe from "../../elements/cards/cardRecipe/CardRecipe";
import { P } from "../../elements/texts/Texts";
import { ButtonEmpty, ButtonFill } from "../../elements/bouttons/Buttons";
import { EDITER } from "../../../constants/routes";
import {
  SLICE_OWN_RECIPES,
  setShowResumeRecipe,
  setIndexRecipeShown,
  setRecipeShown,
  setImageRecipeShown,
  setRecipeSelected,
  STARTERS,
  MEALS,
  DESSERTS,
  setType,
} from "../../../redux/slices/ownRecipesSlice";
import useHandleDisplayRecipe from "../../../hooks/utils/useHandleDisplayRecipe";
import useHandleFavoritesRecipes from "../../../hooks/utils/useHandleFavoritesRecipes";
import ResumeRecipeModal from "../../elements/modals/ResumeRecipeModal";
import {
  KEYS_EDIT_RECIPE,
  SLICE_EDIT_RECIPE,
  resetState,
  setRecipeToUpdate,
  setStepsFilledToTrue,
  setType as setTypeEditRecipe,
} from "../../../redux/slices/editRecipeSlice";
import LoadingModal from "../../elements/modals/LoadingModal";
import useDeleteOwnRecipeMutation from "../../../hooks/requests/useDeleteOwnRecipeMutation";
import FieldTypeOfRecipe from "../../elements/fields/FieldTypeOfRecipe";
import useWindowWidth from "../../../hooks/utils/useWindowWidth";
import clsx from "clsx";

function ProfileOwnRecipes() {
  const getOwnRecipesRequest = useOwnRecipesQuery();

  return (
    <>
      {getOwnRecipesRequest.isLoading && (
        <div className="pt-28">
          <LoadingDiv />
        </div>
      )}
      {getOwnRecipesRequest.isError && (
        <div className="pt-28">
          <ProfileRecipesErrorToReload
            queryKeyToInvalidate={"get_own_recipes"}
          />
        </div>
      )}

      {getOwnRecipesRequest.isSuccess && <OwnRecipesUserContent />}
    </>
  );
}
function OwnRecipesUserContent() {
  const states = useSelector((state) => state[SLICE_OWN_RECIPES]);

  const dispatch = useDispatch();

  const recipesToDisplay = useMemo(() => {
    if (states.type.value === STARTERS) {
      return states.ownRecipesFetched.filter(
        (recipe) => recipe.type === STARTERS
      );
    }
    if (states.type.value === MEALS) {
      return states.ownRecipesFetched.filter((recipe) => recipe.type === MEALS);
    }
    if (states.type.value === DESSERTS) {
      return states.ownRecipesFetched.filter(
        (recipe) => recipe.type === DESSERTS
      );
    }
  }, [states.type, states.ownRecipesFetched]);

  const { id } = useSelector((state) => ({
    id: state[SLICE_EDIT_RECIPE][KEYS_EDIT_RECIPE.ID],
  }));

  function closeModalCustom() {
    if (id !== null) {
      dispatch(resetState());
    }
  }

  const handleChangeLikeStatus = useHandleFavoritesRecipes();

  const handleDisplayRecipe = useHandleDisplayRecipe(
    setRecipeShown,
    setIndexRecipeShown,
    setShowResumeRecipe,
    setImageRecipeShown
  );

  const values = {
    typeValue: states.type.value,
    dishes: recipesToDisplay,
    indexRecipeShown: states.indexRecipeShown,
    imageRecipeShown: states.imageRecipeShown,
  };

  const handlers = {
    handleChangeLikeStatus,
    handleDisplayRecipe,
    dispatch,
  };

  return (
    <>
      <TypeOfRecipeToDisplay typeState={states.type} />
      <OwnRecipes values={values} handlers={handlers} />
      {states.showResumeRecipe && (
        <ResumeRecipeModal
          recipeShown={states.recipeShown}
          indexRecipeShown={states.indexRecipeShown}
          imageRecipeShown={states.imageRecipeShown}
          setShowResumeRecipe={setShowResumeRecipe}
          handleChangeLikeStatus={handleChangeLikeStatus}
          handleDisplayRecipe={handleDisplayRecipe}
          recipesToDisplay={recipesToDisplay}
          closeModalCustom={closeModalCustom}
          ButtonHandleActionOnRecipe={() => (
            <ButtonsHandleRecipe
              recipeShown={states.recipeShown}
              ownRecipes={states.ownRecipesFetched}
            />
          )}
        />
      )}
    </>
  );
}

function TypeOfRecipeToDisplay({ typeState }) {
  const divContainerStyle = clsx(
    "flex flex-row justify-center items-center",
    "w-full",
    // "px-2",
    "h-15",
    // "space-x-8",
    "fixed",
    "bottom-0 md:top-36",
    "left-0 right-0",
    "bg-lime-100",
    "z-20"
  );
  return (
    <div className={divContainerStyle}>
      <FieldTypeOfRecipe state={typeState} action={setType} />
    </div>
  );
}

function OwnRecipes({ values, handlers }) {
  return (
    <>
      {values.dishes.length === 0 && (
        <InfoToRedirectToCreateRecipe typeValue={values.typeValue} />
      )}
      {values.dishes.length > 0 && (
        <OwnRecipesList values={values} handlers={handlers} />
      )}
    </>
  );
}

function InfoToRedirectToCreateRecipe({ typeValue }) {
  const text = useMemo(() => {
    if (typeValue === STARTERS) {
      return "d'entrée";
    }
    if (typeValue === MEALS) {
      return "de plat";
    }
    if (typeValue === DESSERTS) {
      return "de dessert";
    }
  }, [typeValue]);

  const dispatch = useDispatch();

  const divContainerStyle = clsx(
    "w-full",
    "h-full",
    "flex",
    "flex-col",
    "justify-center",
    "items-center",
    "md:mt-36"
    // "border-2 border-black"
  );

  return (
    <div className={divContainerStyle}>
      <P add={"pt-8"}>Vous n'avez aucune recette {text} créée</P>
      <ButtonFill
        add={"mt-8"}
        nav={EDITER.route}
        onClick={() => dispatch(setTypeEditRecipe(typeValue))}
      >
        Lancez vous
      </ButtonFill>
    </div>
  );
}

function OwnRecipesList({ values, handlers }) {
  const { dishes, indexRecipeShown, imageRecipeShown } = values;

  const { dispatch, handleChangeLikeStatus, handleDisplayRecipe } = handlers;
  const isMobile = useWindowWidth();

  useUpdateRecipeDisplayedWhenUpdated(
    dispatch,
    dishes,
    indexRecipeShown,
    imageRecipeShown
  );

  const handleSelectionStatus = useCallback(
    (id) => {
      dispatch(setRecipeSelected(id));
    },
    [dispatch]
  );

  const divContainerStyle = clsx(
    "flex",
    "flex-wrap",
    "w-full",
    "h-full",
    "pt-16 md:pt-32",
    "pb-16 md:pb-0",
    "justify-center",
    "items-center"
  );

  return (
    <div className={divContainerStyle}>
      {dishes.map((recipe, index) => {
        return (
          <CardRecipe
            key={recipe._id}
            recipe={recipe}
            index={index}
            openCard={handleDisplayRecipe}
            couvert={recipe.couvert}
            changeRecipeSelectionStatus={handleSelectionStatus}
            changeLikeStatus={handleChangeLikeStatus}
            selectionDisabled={true}
            isMobile={isMobile}
          />
        );
      })}
    </div>
  );
}

function useUpdateRecipeDisplayedWhenUpdated(
  dispatch,
  dishes,
  indexRecipeShown,
  imageRecipeShown,
) {
  
  useEffect(() => {
    dispatch(setRecipeShown(dishes[indexRecipeShown]));
    dispatch(setIndexRecipeShown(indexRecipeShown));
    dispatch(setImageRecipeShown(imageRecipeShown));
  }, [dispatch, dishes, indexRecipeShown, imageRecipeShown]);
}


function ButtonsHandleRecipe({ recipeShown, ownRecipes }) {
  return (
    <DivCol add={"mt-2"}>
      <UpdateRecipeButton recipeShown={recipeShown} />
      <DeleteRecipeButton recipeShown={recipeShown} ownRecipes={ownRecipes} />
    </DivCol>
  );
}

function UpdateRecipeButton({ recipeShown }) {
  const dispatch = useDispatch();

  const handleClick = useCallback(() => {
    dispatch(setRecipeToUpdate(recipeShown));
    dispatch(setStepsFilledToTrue());
  }, [dispatch, recipeShown]);

  return (
    <ButtonFill onClick={handleClick} nav={EDITER.route}>
      Modifier
    </ButtonFill>
  );
}

function DeleteRecipeButton({ recipeShown, ownRecipes }) {
  const [confirmDelete, setConfirmDelete] = useState(false);
  const deleteOwnRecipeMutation = useDeleteOwnRecipeMutation(ownRecipes);

  useEffect(() => {
    let timer = null;
    if (confirmDelete) {
      timer = setTimeout(() => {
        setConfirmDelete(false);
      }, 3000);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [confirmDelete]);

  function handleClick() {
    if (!confirmDelete) {
      setConfirmDelete(true);
    } else {
      deleteOwnRecipeMutation.mutate({
        recipesToDelete: [recipeShown._id],
      });
    }
  }

  return (
    <>
      <ButtonEmpty
        onClick={handleClick}
        add={"my-1 md:my-4"}
        col={confirmDelete ? "bg-red-400 text-white" : ""}
      >
        {confirmDelete ? "Confirmer la suppression" : "Supprimer la recette"}
      </ButtonEmpty>
      {deleteOwnRecipeMutation.isLoading && <LoadingModal />}
    </>
  );
}

export default ProfileOwnRecipes;
