import { useCallback } from 'react';
import useLocalStorage from './useLocalStorage';

const useFilterFood = (searchingFood, ingredientsAlreadyInRecipe) => {
  const [foodList,] = useLocalStorage('foodList', []);

  const removeAccents = (str) => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  }

  const foodsFound = useCallback(() => {
    if (searchingFood.length < 3) {
      return [];
    }

    // Find foods in db with input value
    const matchedFoods = foodList.filter((food) => {
      const searchString = removeAccents(searchingFood.toLowerCase().trim());
      const regex = new RegExp("\\b" + searchString, "i");

      return regex.test(removeAccents(food.name));
    });

    // Filter Foods which not already in ingredientFields
    const foodsFoundToDisplay = matchedFoods.filter((food) => {
      return !ingredientsAlreadyInRecipe.some((ingredientObj) => {
        return removeAccents(food.name) === removeAccents(ingredientObj.name);
      });
    });

    return foodsFoundToDisplay.map((food) => {
      return food;
    });
  }, [searchingFood, foodList, ingredientsAlreadyInRecipe]);

  return foodsFound;
};

export default useFilterFood;
