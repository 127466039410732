import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { useDispatch } from "react-redux";
import {
  setAllRecipesDeselected,
  setAllRecipesSelected,
  setIsAllDessertsSelected,
  setIsAllMealsSelected,
  setIsAllStartersSelected,
  setType,
} from "../../../redux/slices/recipesToCookSlice";
import SelectionCheckBox from "../checkboxs/SelectionCheckBox";
import useWindowWidth from "../../../hooks/utils/useWindowWidth";
import { H1 } from "../texts/Texts";
import FieldTypeOfRecipe from "../fields/FieldTypeOfRecipe";
import { ButtonEmpty } from "../bouttons/Buttons";
import useDeleteRecipesToCookMutation from "../../../hooks/requests/useDeleteRecipesToCookMutation";
import { DecoIcon } from "../images/IconImage";

function NavBarRecipesToCook({ states }) {
  const isMobile = useWindowWidth();

  return (
    <>
      {!isMobile && <DesktopNavBarRecipesToCook states={states} />}
      {isMobile && <MobileNavBarRecipesToCook states={states} />}
    </>
  );
}

function DesktopNavBarRecipesToCook({ states }) {
  const divContainerStyle = clsx(
    "flex md:flex-row justify-center items-center",
    "w-full",
    // "px-2",
    "h-28 md:h-18",
    // "border-2 border-black",
    "md:space-x-4 xl:space-x-8",
    "fixed",
    "bottom-0 md:top-20",
    "left-0 right-0",
    "bg-lime-100",
    "z-20"
  );
  const divTitleStyle = clsx(
    "flex flex-row",
    "justify-center items-center",
    // "basis-2/4",
  );
  const sizeText = clsx("md:text-2xl lg:text-2xl xl:text-3xl 2xl:text-5xl");

  return (
    <div className={divContainerStyle}>
      <div className={divTitleStyle}>
        <DecoIcon
          iconName={"icons8-cutting-a-carrot"}
          altName={"icon_cuisiner"}
          size={"md:h-15 md:w-15 lg:h-16 w-16 xl:h-18 xl:w-18"}
        />
        <H1 size={sizeText}>Vos recettes à cuisiner</H1>
      </div>
      <div>
        <FieldTypeOfRecipe state={states.type} action={setType} />
      </div>
      {/* <div className="basis-1/3 flex flex-row"> */}
      {states.type.value === "entree" && (
        <FullRecipesToCookSelectionButton
          recipesToCookFetched={states.recipesToCookFetched}
          typeValue={states.type.value}
          setIsAllSelectedMethod={setIsAllStartersSelected}
        />
      )}
      {states.type.value === "plat" && (
        <FullRecipesToCookSelectionButton
          recipesToCookFetched={states.recipesToCookFetched}
          typeValue={states.type.value}
          setIsAllSelectedMethod={setIsAllMealsSelected}
        />
      )}
      {states.type.value === "dessert" && (
        <FullRecipesToCookSelectionButton
          recipesToCookFetched={states.recipesToCookFetched}
          typeValue={states.type.value}
          setIsAllSelectedMethod={setIsAllDessertsSelected}
        />
      )}

      <DeletionButton
        typeValue={states.type.value}
        recipesToCookFetched={states.recipesToCookFetched}
      />
      {/* </div> */}
    </div>
  );
}

function MobileNavBarRecipesToCook({ states }) {
  const divContainerStyle = clsx(
    "flex flex-col md:flex-row justify-center items-center",
    "w-full",
    "px-2",
    "h-28 md:h-18",
    // "border-2 border-black",
    "md:space-x-4",
    "fixed",
    "bottom-0 md:top-20",
    "left-0 right-0",
    "bg-lime-100",
    "z-20",
    "py-2"
  );

  return (
    <div className={divContainerStyle}>
      <div className="w-full h-12 flex flex-row justify-center items-center">
        <DeletionButton
          typeValue={states.type.value}
          recipesToCookFetched={states.recipesToCookFetched}
        />
        {states.type.value === "entree" && (
          <FullRecipesToCookSelectionButton
            recipesToCookFetched={states.recipesToCookFetched}
            typeValue={states.type.value}
            setIsAllSelectedMethod={setIsAllStartersSelected}
          />
        )}
        {states.type.value === "plat" && (
          <FullRecipesToCookSelectionButton
            recipesToCookFetched={states.recipesToCookFetched}
            typeValue={states.type.value}
            setIsAllSelectedMethod={setIsAllMealsSelected}
          />
        )}
        {states.type.value === "dessert" && (
          <FullRecipesToCookSelectionButton
            recipesToCookFetched={states.recipesToCookFetched}
            typeValue={states.type.value}
            setIsAllSelectedMethod={setIsAllDessertsSelected}
          />
        )}
      </div>
      <div className="w-full h-16 pt-1">
        <FieldTypeOfRecipe state={states.type} action={setType} />
      </div>
    </div>
  );
}

function FullRecipesToCookSelectionButton({
  recipesToCookFetched,
  typeValue,
  setIsAllSelectedMethod,
}) {
  const dispatch = useDispatch();

  const [selected, setSelected] = useState(() => {
    const recipesOfType = recipesToCookFetched.filter(
      (recipe) => recipe.type === typeValue
    );
    return (
      recipesOfType.length > 0 &&
      recipesOfType.every((recipe) => recipe.selected)
    );
  });

  useEffect(() => {
    const recipesOfType = recipesToCookFetched.filter(
      (recipe) => recipe.type === typeValue
    );
    setSelected(
      recipesOfType.length > 0 &&
        recipesOfType.every((recipe) => recipe.selected)
    );
  }, [recipesToCookFetched, typeValue]);

  function handleClick() {
    if (!selected) {
      handleAllSelected();
    } else {
      handleAllDeselected();
    }
  }

  function handleAllSelected() {
    dispatch(setAllRecipesSelected(typeValue));
    dispatch(setIsAllSelectedMethod(true));
  }

  function handleAllDeselected() {
    dispatch(setAllRecipesDeselected(typeValue));
    dispatch(setIsAllSelectedMethod(false));
  }

  return (
    <div className="h-18 w-18 flex justify-center items-center">
      <SelectionCheckBox selected={selected} handleClick={handleClick} />
    </div>
  );
}

function DeletionButton({ typeValue, recipesToCookFetched }) {
  const deleteRecipesToCookMutation =
    useDeleteRecipesToCookMutation(recipesToCookFetched);

  const [confirmDelete, setConfirmDelete] = useState(false);
  function handleClickDeletion() {
    if (!confirmDelete) {
      setConfirmDelete(true);
    } else {
      const recipesIdToDelete = extractRecipesIdToDelete();
      deleteRecipesToCookMutation.mutate({
        recipesToDelete: recipesIdToDelete,
      });
    }
  }

  function extractRecipesIdToDelete() {
    return recipesToCookFetched
      .filter((recipe) => recipe.selected === true && recipe.type === typeValue)
      .map((recipe) => recipe.idRecipeToCook);
  }

  useEffect(() => {
    let timer = null;
    if (confirmDelete) {
      timer = setTimeout(() => {
        setConfirmDelete(false);
      }, 3000);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [confirmDelete]);

  return (
    <ButtonEmpty
      col={confirmDelete ? "bg-red-400 text-white" : ""}
      onClick={handleClickDeletion}
      disabled={
        !recipesToCookFetched.some(
          (recipe) => recipe.selected === true && recipe.type === typeValue
        )
      }
    >
      Supprimer
    </ButtonEmpty>
  );
}

export default NavBarRecipesToCook;
