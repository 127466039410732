import React, { useEffect } from "react";
import { DivCol } from "../../elements/divs/Divs";
import { H2 } from "../../elements/texts/Texts";
import {
  SLICE_EDIT_RECIPE,
  KEYS_EDIT_RECIPE,
  setNameRecipe,
  setCouvert,
  setComplexity,
  setCuissonTime,
  setPreparationTime,
  setRefrigerationTime,
  setReposTime,
  setRegime,
  setSeasons,
  setType,
  setImageDataUrl,
  setImageFile,
  setFileName,
  setFileSizeError,
  setTotalTime,
} from "../../../redux/slices/editRecipeSlice";
import { useDispatch, useSelector } from "react-redux";
import FieldNameOfRecipe from "../../elements/fields/FieldNameOfRecipe";
import FieldNumberOfCouverts from "../../elements/fields/FieldNumberOfCouverts";
import FieldTypeOfRecipe from "../../elements/fields/FieldTypeOfRecipe";
import FieldRegimeOfRecipe from "../../elements/fields/FieldRegimeOfRecipe";
import FieldComplexityOfRecipe from "../../elements/fields/FieldComplexityOfRecipe";
import FieldSeasonOfRecipe from "../../elements/fields/FieldSeasonOfRecipe";
import FieldPreparationTimeOfRecipe from "../../elements/fields/FieldPreparationTimeOfRecipe";
import FieldCuissonTimeOfRecipe from "../../elements/fields/FieldCuissonTimeOfRecipe";
import FieldRefrigerationTimeOfRecipe from "../../elements/fields/FieldRefrigerationTimeOfRecipe";
import FieldReposTimeOfRecipe from "../../elements/fields/FieldReposTimeOfRecipe";
import FieldImageOfRecipe from "../../elements/fields/FieldImageOfRecipe";
import { useIsInfosEditRecipeFilled } from "../../../hooks/utils/useIsInfosEditRecipeFilled";

function InfosEditRecipe() {
  const {
    nameRecipe,
    couvert,
    type,
    complexity,
    regime,
    seasons,
    preparationTime,
    cuissonTime,
    refrigerationTime,
    reposTime,
    imageRecipe,
    id
  } = useSelector((state) => ({
    nameRecipe: state[SLICE_EDIT_RECIPE][KEYS_EDIT_RECIPE.NAME_RECIPE],
    couvert: state[SLICE_EDIT_RECIPE][KEYS_EDIT_RECIPE.COUVERT],
    type: state[SLICE_EDIT_RECIPE][KEYS_EDIT_RECIPE.TYPE],
    complexity: state[SLICE_EDIT_RECIPE][KEYS_EDIT_RECIPE.COMPLEXITY],
    regime: state[SLICE_EDIT_RECIPE][KEYS_EDIT_RECIPE.REGIME],
    seasons: state[SLICE_EDIT_RECIPE][KEYS_EDIT_RECIPE.SEASONS],
    preparationTime:
      state[SLICE_EDIT_RECIPE][KEYS_EDIT_RECIPE.PREPARATION_TIME],
    cuissonTime: state[SLICE_EDIT_RECIPE][KEYS_EDIT_RECIPE.CUISSON_TIME],
    refrigerationTime:
      state[SLICE_EDIT_RECIPE][KEYS_EDIT_RECIPE.REFRIGERATION_TIME],
    reposTime: state[SLICE_EDIT_RECIPE][KEYS_EDIT_RECIPE.REPOS_TIME],
    imageRecipe: state[SLICE_EDIT_RECIPE][KEYS_EDIT_RECIPE.IMAGE_RECIPE],
    id: state[SLICE_EDIT_RECIPE][KEYS_EDIT_RECIPE.ID],
  }));
  const dispatch = useDispatch();
  
  useIsInfosEditRecipeFilled(nameRecipe.value.length);

  useEffect(() => {
    const totalTime =
      preparationTime.value +
      cuissonTime.value +
      refrigerationTime.value +
      reposTime.value;
    dispatch(setTotalTime(totalTime));
  }, [
    dispatch,
    preparationTime.value,
    cuissonTime.value,
    refrigerationTime.value,
    reposTime.value,
  ]);

  useEffect(() => {
    if(id) {
      //scroll au top de la page
      window.scrollTo(0, 0);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  return (
    <>
      <DivCol add="w-full sm:w-3/4 md:w-2/3 lg:w-1/2">
        <H2 add="pt-4">Informations de la recette</H2>
        <FieldNameOfRecipe state={nameRecipe} action={setNameRecipe} />
      </DivCol>

      <FieldNumberOfCouverts state={couvert} action={setCouvert} />

      <FieldTypeOfRecipe state={type} action={setType} />

      <FieldRegimeOfRecipe state={regime} action={setRegime} />

      <FieldComplexityOfRecipe state={complexity} action={setComplexity} />

      <FieldSeasonOfRecipe state={seasons} action={setSeasons} />

      <FieldPreparationTimeOfRecipe
        state={preparationTime}
        action={setPreparationTime}
      />

      <FieldCuissonTimeOfRecipe state={cuissonTime} action={setCuissonTime} />

      <FieldRefrigerationTimeOfRecipe
        state={refrigerationTime}
        action={setRefrigerationTime}
      />

      <FieldReposTimeOfRecipe state={reposTime} action={setReposTime} />

      <FieldImageOfRecipe
        state={imageRecipe}
        setImageDataUrl={setImageDataUrl}
        setImageFile={setImageFile}
        setFileName={setFileName}
        setFileSizeError={setFileSizeError}
      />
    </>
  );
}

export default InfosEditRecipe;
