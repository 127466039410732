import { useMutation } from "react-query";
import useError from "../utils/useError";
import {
  add_favorite_recipe,
  delete_favorite_recipe,
} from "../../functions/requests/axiosRequests";
import { useQueryClient } from "react-query";

function useFavoriteRecipesMutation() {
  const { setError } = useError();
  const queryClient = useQueryClient();

  const addFavoriteRecipeMutation = useMutation(
    "add_favorite_recipe",
    add_favorite_recipe,
    {
      onSuccess: () => {
        queryClient.invalidateQueries("get_favorite_recipes");
      },
      onError: (error) => {
        setError(error.response?.data?.message || "Une erreur s'est produite");
      },
    }
  );

  const deleteFavoriteRecipeMutation = useMutation(
    "delete_favorite_recipe",
    delete_favorite_recipe,
    {
      onSuccess: () => {
        queryClient.invalidateQueries("get_favorite_recipes");
      },
      onError: (error) => {
        setError(error.response?.data?.message || "Une erreur s'est produite");
      },
    }
  );

  return { addFavoriteRecipeMutation, deleteFavoriteRecipeMutation };
}

export default useFavoriteRecipesMutation;