import React, { useCallback, useEffect, useState } from "react";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import {
  KEYS_FILTERS_RECIPES,
  SLICE_FILTERS_RECIPES,
  setComplexity,
  setCouvert,
  setFavorite,
  setRegime,
  setRepeat,
  setShowMoreFilters,
  setType,
} from "../../../redux/slices/filtersRecipesSlice";
import { DecoIcon } from "../../elements/images/IconImage";
import ButtonIconSelection from "../../elements/bouttons/ButtonIconSelection";
import { ButtonNumberInputStore } from "../../elements/inputs/ButtonNumberInput";
import useWindowWidth from "../../../hooks/utils/useWindowWidth";
import useDebounce from "../../../hooks/utils/useDebounce";
import FullSelectionButton from "./FullSelectionButton";
import AddRecipesButton from "../../elements/bouttons/AddRecipesButton";
import FieldTypeRecipeToFetch from "../../elements/fields/FieldTypeRecipeToFetch";
import FieldNumberRandomRecipes from "../../elements/fields/FieldNumberRandomRecipes";
import FieldTotalTimeOfRecipe from "../../elements/fields/FieldTotalTimeOfRecipe";
import FieldSeasonOfRecipe from "../../elements/fields/FieldSeasonOfRecipe";
import FieldSearchFood from "../../elements/fields/FieldSearchFood";
import ButtonDeleteAllIngredients from "../../elements/bouttons/ButtonDeleteAllIngredients";
import ListIngredients from "../../elements/lists/ListIngredients";
import {
  INFOS_DISPLAYED,
  INGREDIENTS_DISPLAYED,
  KEYS_RECIPES,
  RECIPES_DISPLAYED,
  SLICE_RECIPES,
  setFiltersDisplay,
} from "../../../redux/slices/recipesSlice";
import useRecipesMutation from "../../../hooks/requests/useRecipesMutation";
import useSubmitFilter from "../../../hooks/requests/useSubmitFilter";

function Filters() {
  const buttonConfigs = [
    { key: KEYS_FILTERS_RECIPES.TYPE, setter: setType },
    { key: KEYS_FILTERS_RECIPES.REGIME, setter: setRegime },
    { key: KEYS_FILTERS_RECIPES.COMPLEXITY, setter: setComplexity },
  ];

  const inputsConfigs = [
    {
      key: KEYS_FILTERS_RECIPES.COUVERT,
      setter: setCouvert,
      text: "Couverts des recettes",
    },
    // {
    //   key: KEYS_FILTERS_RECIPES.REPEAT,
    //   setter: setRepeat,
    //   text: "Répétitions des recettes",
    // },
  ];

  const [activeButton, setActiveButton] = useState(null);

  const divContainerStyle = clsx(
    "w-full",
    "h-28 md:h-18",
    "fixed",
    "bottom-0 md:top-20 left-0 right-0",
    "bg-lime-100",
    "z-30",
    "flex flex-col md:flex-row justify-center items-center",
    "space-x-0 md:space-x-2 lg:space-x-6",
    "space-y-2 md:space-y-0",
    "px-2 lg:px-4 xl:px-6"
  );

  const divMobileInfosStyle = clsx(
    "flex flex-row items-center justify-center md:order-1",
    "space-x-2 md:space-x-2 lg:space-x-6",
    "px-1"
  );

  const divInputStyle = clsx(
    "flex flex-row md:order-2 items-center justify-center",
    "space-x-1 xs:space-x-2 lg:space-x-6"
  );

  const divDesktopButtonsStyle = clsx(
    "hidden md:flex flex-row items-center justify-center md:order-3",
    "space-x-2 md:space-x-2 lg:space-x-6"
  );

  return (
    <>
      <div className={divContainerStyle}>
        <div className={divMobileInfosStyle}>
          {buttonConfigs.map((config) => (
            <GeneralizedButton
              key={config.key}
              dataKey={config.key}
              setter={config.setter}
              isActive={activeButton === config.key}
              setActiveButton={setActiveButton}
            />
          ))}

          <ButtonFavorite
            isActive={activeButton === "favorite"}
            setActiveButton={setActiveButton}
          />

          <div className="flex items-center justify-center md:hidden">
            <FullSelectionButton />
          </div>

          <div className="md:hidden">
            <PlusButtonFilter />
          </div>

          <div className="md:hidden">
            <ResearchButton />
          </div>
        </div>

        <div className={divInputStyle}>
          <div className="flex flex-row-reverse md:flex-row items-center justify-center space-x-1 xs:space-x-2 lg:space-x-6">
            {inputsConfigs.map((config) => (
              <GeneralizedInputNumber
                key={config.key}
                dataKey={config.key}
                setter={config.setter}
                isActive={activeButton === config.key}
                setActiveButton={setActiveButton}
                text={config.text}
              />
            ))}
          </div>
          <div className="md:hidden">
            <AddRecipesButton />
          </div>
        </div>
        <div className={divDesktopButtonsStyle}>
          <FullSelectionButton />
          <PlusButtonFilter />
          <ResearchButton />
          <AddRecipesButton />
        </div>
      </div>
      <MoreFiltersDiv />
    </>
  );
}

const GeneralizedButton = React.memo(
  ({ dataKey, setter, isActive, setActiveButton }) => {
    const dispatch = useDispatch();
    const data = useSelector((state) => state[SLICE_FILTERS_RECIPES][dataKey]);

    const applyData = useCallback(
      (value) => dispatch(setter(value)),
      [dispatch, setter]
    );

    function applyActivateButton() {
      setActiveButton((prev) => (prev === data.name ? null : data.name));
    }

    return (
      <ButtonIconSelection
        selectionList={data}
        setSelection={applyData}
        isActive={isActive}
        onActive={applyActivateButton}
      />
    );
  }
);

const ButtonFavorite = React.memo(({ isActive, setActiveButton }) => {
  const dispatch = useDispatch();
  const favorite = useSelector(
    (state) => state[SLICE_FILTERS_RECIPES][KEYS_FILTERS_RECIPES.FAVORITE]
  );
  const isMobile = useWindowWidth();

  const buttonStyle = clsx(
    "flex flex-row items-center justify-center overflow-visible",
    "h-10 sm:h-12 md:h-14 lg:h-16",
    "w-10 sm:w-12 md:w-14 lg:w-16",
    "shadow-md",
    !isMobile ? "bg-lime-100 rounded-md hover:bg-sky-100 hover:rounded-xl" : "",
    isMobile && isActive ? "bg-sky-100 rounded-xl" : "",
    isMobile && !isActive ? "bg-lime-100 rounded-md" : "",
    "transition-all duration-100 ease-linear",
    "relative",
    "border-2 border-sky-900",
    "group",
    "p-2"
  );

  const spanStyle = clsx(
    "absolute",
    "bg-sky-100 text-sky-700",
    "rounded-md",
    "w-64 md:w-64",
    "p-2",
    "text-center",
    "-top-[46px] -left-24 md:top-[65px]",
    "text-xl",
    "font-spin",
    "transition-all duration-100 scale-0 origin-top",
    !isMobile ? "group-hover:scale-100" : "",
    isMobile && isActive ? "scale-100" : "",
    isMobile && !isActive ? "scale-0" : ""
  );

  const applyFavorite = useCallback(() => {
    dispatch(setFavorite(!favorite));
    setActiveButton("favorite");
  }, [dispatch, favorite, setActiveButton]);

  const [debounceActivate, cancelDebounce] = useDebounce(() => {
    setActiveButton(false);
  }, 1000);

  useEffect(() => {
    if (isActive) {
      debounceActivate();
    } else {
      cancelDebounce();
    }
  }, [isActive, debounceActivate, cancelDebounce]);

  return (
    <button className={buttonStyle} onClick={applyFavorite}>
      <DecoIcon
        iconName={favorite ? "full-heart" : "empty-heart"}
        altName={"favoris"}
        size={"h-full w-full"}
      />
      <span className={spanStyle}>
        {favorite ? "Favoris uniquement" : "Avec favoris"}
      </span>
    </button>
  );
});

const GeneralizedInputNumber = React.memo(
  ({ dataKey, setter, isActive, setActiveButton, text }) => {
    const data = useSelector((state) => state[SLICE_FILTERS_RECIPES][dataKey]);
    const isMobile = useWindowWidth();

    const divStyle = clsx(
      "relative",
      "transition-all duration-100 ease-linear",
      "group",
      "mb-0 md:mb-2",
      "px-1"
    );

    const spanStyle = clsx(
      "absolute",
      "bg-sky-100 text-sky-700",
      "rounded-md",
      "w-64 md:w-auto",
      "p-2",
      "text-center",
      "-top-[46px] -left-8 md:top-[60px] md:left-0",
      "text-xl",
      "font-spin",
      "transition-all duration-100 scale-0 origin-top",
      !isMobile ? "group-hover:scale-100" : "",
      isMobile && isActive ? "scale-100" : "",
      isMobile && !isActive ? "scale-0" : ""
    );

    function applyActivateButton() {
      setActiveButton(dataKey);
    }

    const [debounceActivate, cancelDebounce] = useDebounce(() => {
      setActiveButton(false);
    }, 1000);

    useEffect(() => {
      if (isActive) {
        debounceActivate();
      } else {
        cancelDebounce();
      }
    }, [isActive, debounceActivate, cancelDebounce]);

    return (
      <div
        className={divStyle}
        testid={`div_${dataKey}`}
        onClick={applyActivateButton}
      >
        <ButtonNumberInputStore state={data} action={setter} />
        <span className={spanStyle}>{text}</span>
      </div>
    );
  }
);

const PlusButtonFilter = React.memo(() => {
  const dispatch = useDispatch();
  const showMoreFilters = useSelector(
    (state) =>
      state[SLICE_FILTERS_RECIPES][KEYS_FILTERS_RECIPES.SHOW_MORE_FILTERS]
  );
  const isMobile = useWindowWidth();

  function handleShowMoreFilters() {
    dispatch(setShowMoreFilters(!showMoreFilters));
  }

  const buttonStyle = clsx(
    "w-10 h-10 md:w-12 md:h-12",
    "bg-sky-300",
    "border-2 border-sky-700",
    "rounded-full",
    "flex items-center justify-center",
    "relative",
    "group"
  );

  const spanStyle = clsx(
    "absolute",
    "bg-sky-100 text-sky-700",
    "rounded-md",
    "w-64 md:w-40",
    "p-2",
    "text-center",
    "-top-[46px] -left-12 md:top-[50px]",
    "text-xl",
    "font-spin",
    "transition-all duration-100 scale-0 origin-top",
    !isMobile ? "group-hover:scale-100" : ""
  );

  return (
    <button className={buttonStyle} onClick={handleShowMoreFilters}>
      <DecoIcon
        iconName={showMoreFilters ? "icons8-moins" : "icons8-plus"}
        altName={"plus"}
        size={"h-12 w-12"}
      />
      <span className={spanStyle}>
        {showMoreFilters ? "Moins de filtres" : "Plus de filtres"}
      </span>
    </button>
  );
});

const ResearchButton = React.memo(() => {
  const dispatch = useDispatch();
  const recipesMutation = useRecipesMutation();
  
  const submitFilters = useSubmitFilter(recipesMutation.mutate);
  
  function handleClick() {
    submitFilters();
    dispatch(setShowMoreFilters(false));
  }

  const buttonStyle = clsx(
    "h-10 sm:h-12 md:h-14 lg:h-16",
    "w-10 sm:w-12 md:w-14 lg:w-16",
    "bg-lime-100 hover:bg-sky-100",
    "border-2 border-sky-900",
    "rounded-md hover:rounded-2xl",
    "transition-all duration-100 ease-linear",
    "flex items-center justify-center"
  );

  return (
    <button className={buttonStyle} onClick={handleClick}>
      <DecoIcon
        iconName={"icons8-chercher"}
        altName={"recherche"}
        size={"h-full w-full"}
      />
    </button>
  );
});

const MoreFiltersDiv = React.memo(() => {
  //#region hooks
  const dispatch = useDispatch();
  
  const showMoreFilters = useSelector(
    (state) =>
      state[SLICE_FILTERS_RECIPES][KEYS_FILTERS_RECIPES.SHOW_MORE_FILTERS]
  );

  const filtersDisplayed = useSelector(
    (state) => state[SLICE_RECIPES][KEYS_RECIPES.FILTERS_DISPLAYED]
  );

  const handleClickInfos = useCallback(() => {
    if (filtersDisplayed !== INFOS_DISPLAYED) {
      dispatch(setFiltersDisplay(INFOS_DISPLAYED));
    }
  }, [filtersDisplayed, dispatch]);

  const handleClickIngredients = useCallback(() => {
    if (filtersDisplayed !== INGREDIENTS_DISPLAYED) {
      dispatch(setFiltersDisplay(INGREDIENTS_DISPLAYED));
    }
  }, [filtersDisplayed, dispatch]);
  //#endregion hooks

  //#region styles
  const divDesktopStyle = clsx(
    "w-full",
    "h-full",
    "fixed",
    "top-38 left-0 right-0",
    "mb-28 ",
    "bg-lime-100",
    "z-20",
    "hidden md:flex md:flex-row justify-center items-start",
    "space-x-2 lg:space-x-6",
    "space-y-0",
    "px-4 xl:px-6"
  );

  const divMobileStyle = clsx(
    "flex flex-col justify-start items-center md:hidden",
    "top-15 sm:top-20 left-0 right-0",
    "w-full",
    "h-full",
    "fixed",
    "z-20",
    "bg-lime-100",
    "pt-4",
    "mb-28",
    "px-8",
    // "overflow-y-auto",
  );

  const styleInfosButton = clsx(
    filtersDisplayed === INFOS_DISPLAYED
      ? "border-2 border-sky-300 rounded shadow-xl"
      : "shadow-inner"
  );

  const styleIngredientsButton = clsx(
    filtersDisplayed === INGREDIENTS_DISPLAYED
      ? "border-2 border-sky-300 rounded shadow-xl"
      : "shadow-inner"
  );

  const sizeIcon = clsx("h-12 md:h-14", "w-12 md:w-14");
  //#endregion

  return (
    <>
      {showMoreFilters && (
        <>
          <div className={divDesktopStyle}>
            <div className="flex flex-col w-1/2">
              <FieldTypeRecipeToFetch />
              <FieldNumberRandomRecipes />
              <FieldTotalTimeOfRecipe />
              <FieldSeasonOfRecipe />
            </div>
            <div className="flex flex-col w-1/2 justify-center items-center">
              <IngredientsFilter />
            </div>
          </div>

          <div className={divMobileStyle}>
            <div className="w-full flex flex-row items-center justify-center space-x-6">
              <DecoIcon
                iconName={"icons8-detail"}
                altName={"infos_button"}
                size={sizeIcon}
                onClick={handleClickInfos}
                add={styleInfosButton}
              />
              <DecoIcon
                iconName={"icons8-foods"}
                altName={"foods_button"}
                size={sizeIcon}
                onClick={handleClickIngredients}
                add={styleIngredientsButton}
              />
            </div>
            {filtersDisplayed === INFOS_DISPLAYED && (
              <div className="flex flex-col w-full">
                <FieldTypeRecipeToFetch />
                <FieldNumberRandomRecipes />
                <FieldTotalTimeOfRecipe />
                <FieldSeasonOfRecipe />
              </div>
            )}
            {filtersDisplayed === INGREDIENTS_DISPLAYED && (
              <div className="flex flex-col w-full items-center">
                <IngredientsFilter />
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
});

const IngredientsFilter = React.memo(() => {
  const ingredients = useSelector(
    (state) => state[SLICE_FILTERS_RECIPES][KEYS_FILTERS_RECIPES.INGREDIENTS]
  );
  return (
    <>
      <FieldSearchFood ingredients={ingredients.value} />
      <ButtonDeleteAllIngredients />
      <div className="w-full h-96 overflow-scroll">
        <ListIngredients ingredients={ingredients.value} />
      </div>
    </>
  );
});

export default React.memo(Filters);
