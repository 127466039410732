import { useMutation } from 'react-query'
import useError from '../utils/useError'
import { new_recipe } from '../../functions/requests/axiosRequests'
import { useDispatch } from 'react-redux'
import { setDisplayAddSuccess, setDisplayResumeRecipe, resetState } from '../../redux/slices/editRecipeSlice'

function useNewRecipeMutation() {
  const dispatch = useDispatch()
  const {setError} = useError()

  const newRecipeMutation = useMutation(new_recipe, {
    onSuccess:() => {
      dispatch(resetState())
      dispatch(setDisplayResumeRecipe(false))
      dispatch(setDisplayAddSuccess(true))
    },
    onError: (error) => {
      setError(error.response?.data?.message || "Une erreur s'est produite");
    },
})

  return newRecipeMutation
}

export default useNewRecipeMutation