import {useCallback} from 'react'
import { useDispatch } from 'react-redux';
import useFavoriteRecipesMutation from '../requests/useFavoriteRecipesMutation';
import { setRecipeLiked as setRecipeLikedInRecipesToCook } from '../../redux/slices/recipesToCookSlice';
import { setRecipeLiked as setRecipeLikedInRecipes} from '../../redux/slices/recipesSlice';
import { setRecipeLiked as setRecipeLikedInOwnRecipes} from '../../redux/slices/ownRecipesSlice';

function useHandleFavoritesRecipes() {
  const dispatch= useDispatch()
  const { addFavoriteRecipeMutation, deleteFavoriteRecipeMutation } = useFavoriteRecipesMutation();

    const handleChangeLikeStatus = useCallback(
    (idRecipe, isLiked) => {
      if (!isLiked) {
        addFavoriteRecipeMutation.mutate(idRecipe);
      } else {
        deleteFavoriteRecipeMutation.mutate(idRecipe);
      }
      dispatch(setRecipeLikedInRecipesToCook(idRecipe));
      dispatch(setRecipeLikedInRecipes(idRecipe));
      dispatch(setRecipeLikedInOwnRecipes(idRecipe));
    },
    [addFavoriteRecipeMutation, deleteFavoriteRecipeMutation, dispatch]
  );

  return handleChangeLikeStatus
}

export default useHandleFavoritesRecipes