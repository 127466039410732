import { DivCol, DivRow } from "../../elements/divs/Divs";
import { DecoIcon } from "../../elements/images/IconImage";
import { H3, P } from "../../elements/texts/Texts";

export function PreparationTime({ value }) {
  const preparationTime = {
    name: "preparationTime",
    title: "Préparation",
    value: value,
    logo: "icons8-sablier-yellow"
      ,
  };

  return <DisplayTimeRecipe timeObject={preparationTime} testid={"preparation_time_recipe"}/>;
}

export function CuissonTime({ value }) {
  const cuissonTime = {
    name: "cuissonTime",
    title: "Cuisson",
    value: value,
    logo: "icons8-sablier-red"
  };

  return <DisplayTimeRecipe timeObject={cuissonTime} testid={"cuisson_time_recipe"}/>;
}

export function RefrigerationTime({ value }) {
  const refrigerationTime = {
    name: "refrigerationTime",
    title: "Réfrigération",
    value: value,
    logo: "icons8-sablier-blue"
  }

  return <DisplayTimeRecipe timeObject={refrigerationTime} testid={"refrigeration_time_recipe"}/>;
}

export function ReposTime({ value }) {
  const reposTime = {
    name: "reposTime",
    title: "Repos",
    value: value,
    logo: "icons8-sablier-green"
  }

  return <DisplayTimeRecipe timeObject={reposTime} testid={"repos_time_recipe"}/>;
}

export function TotalTime({ value }) {
  const totalTime = {
    name: "totalTime",
    title: "Temps total",
    value: value,
    logo: "icons8-sand-timer"
  }

  return <DisplayTimeRecipe timeObject={totalTime} testid={"total_time_recipe"}/>;
}

function DisplayTimeRecipe({ timeObject, testid }) {
  const timeInHour = convertTimeInHour(timeObject.value);

  return (
    <>
      {timeObject.value > 0 && (
        <DivCol>
          <DivRow>
            <DecoIcon
              iconName={timeObject.logo}
              altName={timeObject.name}
              size={"h-8 w-8"}
            />
            <H3 testid={testid}>{timeObject.title}</H3>
          </DivRow>
          <P testid={`value_${testid}`}>{timeInHour}</P>
        </DivCol>
      )}
    </>
  );
}

function convertTimeInHour(timeToConvert) {
  let hour = Math.floor(timeToConvert / 60);
  let min = timeToConvert % 60;
  let hourMin = "";
  if (hour > 0) {
    hourMin = hour + "h";
  }
  if (min > 0) {
    hourMin = hourMin + min + "min";
  }
  return hourMin;
}
