import React from "react";
import useWindowWidth from "../../../hooks/utils/useWindowWidth";
import clsx from "clsx";
import { H1 } from "../texts/Texts";
import { useDispatch, useSelector } from "react-redux";
import { INFOS_USER, RECIPES_USER, TEMP_RECIPES, SLICE_PROFILE_PAGE, setDataToShow } from "../../../redux/slices/profilePageSlice";
import { KEYS_USER_DATA, SLICE_USER_DATA } from "../../../redux/slices/userDataSlice";

function NavbarProfile() {
  const isMobile = useWindowWidth();
  const profileStates = useSelector((state) => state[SLICE_PROFILE_PAGE]);
  const dispatch = useDispatch()

  function onMyAccountClick() {
    dispatch(setDataToShow(INFOS_USER))
  }

  function onMyRecipesClick() {
    dispatch(setDataToShow(RECIPES_USER))
  }

  function onTempRecipesClick() {
    dispatch(setDataToShow(TEMP_RECIPES))
  }

  const handlers = {
    onMyAccountClick,
    onMyRecipesClick,
    onTempRecipesClick
  }
  return (
    <>
      {!isMobile && <DesktopNavBarProfile profileStates={profileStates} handlers={handlers}/>}
      {isMobile && <MobileNavBarProfile   profileStates={profileStates} handlers={handlers}/>}
    </>
  );
}

function DesktopNavBarProfile({ profileStates, handlers }) {
  const divContainerStyle = clsx(
    "flex md:flex-row justify-center items-center",
    "w-full",
    "px-2",
    "h-28 md:h-18",
    // "border-2 border-black",
    "md:space-x-24",
    "fixed",
    "bottom-0 md:top-20",
    "left-0 right-0",
    "bg-lime-100",
    "z-20"
  );

  const textSize = clsx(
    "text-3xl"
  )
  const textMyAccountStyle = clsx(
    profileStates.dataToShow === INFOS_USER ? "border-b-2 border-sky-300" : "border-b-2 border-transparent"
  )
  const textMyRecipesStyle = clsx(
    profileStates.dataToShow === RECIPES_USER ? "border-b-2 border-sky-300" : "border-b-2 border-transparent"
  )
  const textTempRecipesStyle = clsx(
    profileStates.dataToShow === TEMP_RECIPES ? "border-b-2 border-sky-300" : "border-b-2 border-transparent"
  )

  const author = useSelector((state) => state[SLICE_USER_DATA][KEYS_USER_DATA.AUTHOR])

  return (<div className={divContainerStyle}>
      <H1 add={textMyRecipesStyle} size={textSize} onClick={handlers.onMyRecipesClick}>Mes Créations</H1>
      <H1 add={textMyAccountStyle} size={textSize} onClick={handlers.onMyAccountClick}>Mon Compte</H1>
      {author === "Mealster" && <H1 add={textTempRecipesStyle} size={textSize} onClick={handlers.onTempRecipesClick}>En attente</H1>}
  </div>)
  ;
}

function MobileNavBarProfile({ profileStates, handlers }) {
  const divContainerStyle = clsx(
    "flex flex-row justify-center items-center",
    "w-full",
    "px-2",
    "h-14",
    "space-x-8",
    "fixed",
    "top-15 sm:top-20",
    "left-0 right-0",
    "bg-lime-100",
    "z-20"
  );
  const textSize = clsx(
    "text-lg sm:text-2xl"
  )
  const textMyAccountStyle = clsx(
    profileStates.dataToShow === INFOS_USER ? "border-b-2 border-sky-300" : "border-b-2 border-transparent"
  )
  const textMyRecipesStyle = clsx(
    profileStates.dataToShow === RECIPES_USER ? "border-b-2 border-sky-300" : "border-b-2 border-transparent"
  )
  const textTempRecipesStyle = clsx(
    profileStates.dataToShow === TEMP_RECIPES ? "border-b-2 border-sky-300" : "border-b-2 border-transparent"
  )

  const author = useSelector((state) => state[SLICE_USER_DATA][KEYS_USER_DATA.AUTHOR])

  return(
    <div className={divContainerStyle}>
      <H1 add={textMyAccountStyle} size={textSize} onClick={handlers.onMyAccountClick}>Mon Compte</H1>
      <H1 add={textMyRecipesStyle} size={textSize} onClick={handlers.onMyRecipesClick}>Mes Créations</H1>
      {author === "Mealster" && <H1 add={textTempRecipesStyle} size={textSize} onClick={handlers.onTempRecipesClick}>En attente</H1>}
    </div>
  )
}

export default NavbarProfile;
