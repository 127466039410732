import MobileDetect from "mobile-detect";
import { useNavigate } from "react-router-dom";

import clsx from "clsx";

const md = new MobileDetect(window.navigator.userAgent);
const isMobile = md.mobile() !== null; // Vérifie si l'utilisateur est sur un périphérique mobile
const isPhone = md.phone() !== null; // Vérifie si l'utilisateur est sur un téléphone mobile


export const ButtonFunction = ({
  children,
  add,
  onClick,
  testid,
  nav,
  type="button",
  disabled = false,
}) => {
  const navigate = useNavigate();
  const handleClick = (e) => {
    e.preventDefault();
    if (onClick) {
      onClick();
    }
    if (nav) {
      navigate(nav);
    }
  };

  
const buttonStyleBase = clsx(
  "rounded",
  "px-1 py-3",
  "text-base font-mitr",
  disabled ? "opacity-50" : "focus:outline-none focus:ring active:bg-indigo-500",
  isMobile || isPhone ? "" : disabled ? "" : "transition hover:scale-110",
);

  return (
    <button
      testid={testid}
      type={type}
      className={clsx(
        buttonStyleBase,
        add ? add : ""
      )}
      onClick={handleClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export const ButtonFill = ({ children, add, col, size, ...rest }) => {
  return (
    <ButtonFunction
      add={clsx(
        add ? add : "",
        col ? col : "bg-sky-600",
        "text-lime-50",
        size ? size : "w-52"
      )}
      {...rest}
    >
      {children}
    </ButtonFunction>
  );
};

export const ButtonEmpty = ({ children, add, col, ...rest }) => {
  return (
    <ButtonFunction
      add={clsx(
        "border border-current",
        "w-52",
        add ? add : "",
        col ? col : "text-sky-600"
      )}
      {...rest}
    >
      {children}
    </ButtonFunction>
  );
};
