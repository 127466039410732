import clsx from "clsx";
import React, { useState, useEffect, useCallback } from "react";
import BouttonPressed from "../bouttons/BouttonPressed";
import Moins from "../../../assets/icons/deco_icons/icons8-moins.svg";
import Plus from "../../../assets/icons/deco_icons/icons8-plus.svg";
import { H3, P } from "../texts/Texts";
import { DivRow } from "../divs/Divs";
import { useDispatch } from "react-redux";
import { DecoIcon } from "../images/IconImage";

export const ButtonNumberInput = ({
  item,
  onNumberChange,
  logoTitle = true,
}) => {
  const maxValueString = item.max ? item.max.toString() : "1000";
  const maxValue = item.max ? item.max : 1000;

  ////////////
  // Functions
  ////////////
  //#region
  function decrement() {
    if (item.value - item.step <= item.min) {
      onNumberChange(item.min);
    } else {
      onNumberChange(item.value - item.step);
    }
  }

  function increment() {
    if (item.value + item.step > maxValue) {
      onNumberChange(maxValue);
    } else {
      onNumberChange(item.value + item.step);
    }
  }
  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    const newValue = parseInt(inputValue, 10);
    if(isNaN(newValue)) {
      onNumberChange(undefined);
    }
    else {
      if (newValue >= item.min && newValue <= maxValue) {
        onNumberChange(newValue);
      } else if (newValue > 1000) {
        onNumberChange(1000);
      } else {
        onNumberChange(item.min);
      }
      
    }
  };

  const handleInputBlur = (e) => {
    const inputValue = e.target.value;  
    if (inputValue === "") {
      onNumberChange(item.min);
    }
  };
  
  //#endregion
  ////////////
  // Renders
  ////////////

  return (
    <div className={clsx("", "flex", "flex-col w-full")} testid={item.name}>
      {item.title && (
        <DivRow add="pt-4">
          {item.logo && <DecoIcon iconName={item.logo} altName={item.name} size={"h-8 w-8"}/>}
          <H3 size={"md:text-lg lg:text-lg"}>{item.title}</H3>
        </DivRow>
      )}

      <DivRow add="space-x-2 mt-4">
        <BouttonPressed handleClick={decrement} srcImg={Moins} testid="decrease"/>

        <DivRow add={"space-x-2"}>
          <input
            className={clsx(
              // "md:h-6 lg:h-10",
              "mb-2", //margin move white area of input not the value
              maxValue < 100 ? "w-8": "w-14",
              "bg-lime-100",
              "font-spin",
              item?.unite && item.value < 10 ? "pl-5" : item.unite && item.value < 1000 ? "pl-2" : "pl-0",
              item.logo_unite && item.value < 10 ? "pl-4" : item.value < 100 ? "pl-1" : "pl-3",
              "text-gray-800 text-lg sm:text-xl ",
              item.value < 10 ? "text-center" : "text-center"
            )}
            type="number"
            onChange={handleInputChange}
            value={item.value}
            name={item.name}
            max={maxValueString}
            onBlur={handleInputBlur}
            testid={`input_${item.name}`}
          />
          {item.unite && <P add="pb-2 pl-1 pr-2">{item.unite}</P>}
          {item.logo_unite && 
            <DecoIcon iconName={item.logo_unite} altName={item.name} size={"h-7 w-7 md:h-10 md:w-10"} add={"mb-2"}/>
          }
        </DivRow>

        <BouttonPressed handleClick={increment} srcImg={Plus} testid="increase"/>
      </DivRow>
    </div>
  );
};

export function ButtonNumberInputStore({state, action}) {
  const dispatch = useDispatch();

  const onChange = useCallback((value) => {
    dispatch(action(value));
  }, [dispatch, action]);


  return (
    <ButtonNumberInput item={state} onNumberChange={onChange} />
  )
}

export const QtyInputNumber = ({ onValueChange, foodObject, index }) => {
  const [inputValue, setInputValue] = useState(foodObject.quantity);

  useEffect(() => {
    setInputValue(foodObject.quantity);
  }, [foodObject.quantity]);

  const increaseQuantity = useCallback(() => {
    if (
      foodObject.unite !== "pièce" &&
      foodObject.quantity + foodObject.step > 1000
    ) {
      onValueChange(index, 1000);
    } else if (
      foodObject.unite === "pièce" &&
      foodObject.quantity + foodObject.step > 99
    ) {
      onValueChange(index, 99);
    } else {
      if (foodObject.unite !== "pièce" && foodObject.quantity < foodObject.step) {
        onValueChange(index, foodObject.step);
      } else {
        onValueChange(index, foodObject.quantity + foodObject.step);
      }
    }
  }, [
    foodObject.quantity,
    foodObject.step,
    foodObject.unite,
    index,
    onValueChange,
  ]);

  const decreaseQuantity = useCallback(() => {
    if (foodObject.quantity - foodObject.step < foodObject.min) {
        onValueChange(index, 1);
    } else {
      onValueChange(index, foodObject.quantity - foodObject.step);
  }}, [
    foodObject.quantity,
    foodObject.step,
    foodObject.min,
    index,
    onValueChange,
  ]);

  const handleInputChange = useCallback(
    (e) => {
      const newValue = parseInt(e.target.value, 10);
      if(isNaN(newValue)) {
        onValueChange(index, undefined);
      }
      else {
        if (newValue > 0 && newValue <= 1000) {
          onValueChange(index, newValue);
        } else if (newValue > 1000) {
          onValueChange(index, 1000);
        } else {
          onValueChange(index, foodObject.step);
        }
      }
    },
    [foodObject.step, index, onValueChange]
  );

  const handleInputBlur = useCallback((e) => {
    const inputValue = e.target.value;
  
    if (inputValue === "") {
      setInputValue(foodObject.step);
      onValueChange(index, foodObject.step);
    }
  }, [foodObject.step, index, onValueChange]);
  
  return (
    <DivRow>
      <BouttonPressed handleClick={decreaseQuantity} srcImg={Moins} testid="decrease"/>
      <input
        className={clsx(
          foodObject.unite === "pièce" ? "w-9" : " w-18",
          "h-7 ml-2 mb-2 mr-1",
          "bg-transparent",
          "font-spin",
          inputValue < 10 ? "pl-5" : inputValue < 1000 ? "pl-2" : "pl-0",
          "text-gray-800 text-lg sm:text-xl ",
          inputValue < 10 ? "text-center" : "text-center"
        )}
        type="number"
        max="1000"
        value={inputValue}
        onChange={handleInputChange}
        onBlur={handleInputBlur}
        name={foodObject.name}
        testid={`input_${foodObject.name}`}
      />
      <H3 add="mb-2 pr-1 mr-2">
        {foodObject.unite}
        <span style={{ display: "inline-block", width: "8px" }}>
          {foodObject.quantity > 1 && foodObject.unite === "pièce" ? "s" : ""}
        </span>
      </H3>

      <BouttonPressed handleClick={increaseQuantity} srcImg={Plus} testid="increase"/>
    </DivRow>
  );
};
