import { useMutation } from 'react-query'
import useError from '../utils/useError'
import { forgot_pwd } from '../../functions/requests/axiosRequests'

function useForgotPwdMutation() {
  const {setError} = useError()
  
  const forgotPwdMutation = useMutation(forgot_pwd, {
        onError: (err) => {
      setError(err.response.data.message);
    },
    mutationKey: "forgot_pwd",
  })

  return forgotPwdMutation
}

export default useForgotPwdMutation