import {useState, useEffect} from 'react';

function useWindowWidth() {
  const MD_WIDTH_PX = 768;
  const [isMobile, setIsMobile] = useState(window.innerWidth < MD_WIDTH_PX);

  useEffect(() => {
    const checkSize = () => setIsMobile(window.innerWidth < MD_WIDTH_PX);

    window.addEventListener('resize', checkSize);
    return () => window.removeEventListener('resize', checkSize);
  }, []);

  return isMobile;
}

export default useWindowWidth;