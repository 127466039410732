import { Outlet } from "react-router-dom";
import clsx from "clsx";
import ErroRequestModal from "../elements/modals/ErrorRequestModal";

const Layout = () => {
  const backgroundApp = clsx(
    "fixed",
    "h-screen w-screen",
    "top-0 left-0",
    "-z-50",
    "bg-lime-50"
  );

  return (
    <main className="flex flex-col items-center w-full h-full">
      <div className={backgroundApp}></div>
      <Outlet />
      <ErroRequestModal/>
    </main>
  );
};

export default Layout;
