export const OFFSET_LIST_RECIPE = 10;
export const OFFSET_LIST_RECIPE_RESET = 0;
export const name = {
  name: "name",
  title: "Nom de la recette",
  placeholder: "Nom de votre recette d'au moins 8 caractères...",
  defaultValue: "",
  logo: require("../assets/icons/deco_icons/icons8-pencil-drawing.svg").default,
}

export const couvert = {
  name: "couvert", 
  title: "Couverts", 
  defaultValue: 1,
  unite: "",
  step: 1,
  min: 1,
  logo: require("../assets/icons/deco_icons/icons8-cutlery.svg").default,
}

export const type = {
  title: "Type",
  name: "type",
  defaultValue: "plat",
  logo: require("../assets/icons/deco_icons/icons8-tapas.svg").default,
  data: [
    { name: "type", value: "entree", label: 'Entrées', isDefault: false, logo: require("../assets/icons/deco_icons/icons8-greek-salad.svg").default},
    { name: "type", value: "plat", label: 'Plats', isDefault: true, logo: require("../assets/icons/deco_icons/icons8-paella.svg").default},
    { name: "type", value: "dessert", label: 'Desserts', isDefault: false, logo: require("../assets/icons/deco_icons/icons8-banana-split.svg").default}
  ]
}

export const complexity = {
  title: "Compléxité",
  name: "complexity",
  defaultValue: "facile",
  logo: require("../assets/icons/deco_icons/icons8-ok-hand.svg").default,
  data:
  [
    { name: "complexity", value: "facile", label: "Facile", isDefault: true, logo: require("../assets/icons/deco_icons/icons8-take-it-easy.svg").default},
    { name: "complexity", value: "moyen", label: "Moyen", isDefault: false, logo: require("../assets/icons/deco_icons/icons8-two-fingers.svg").default},
    { name: "complexity", value: "difficile", label: "Difficile", isDefault: false, logo: require("../assets/icons/deco_icons/icons8-mayura-gesture.svg").default}
  ]
}

export const regime = {
  title: "Régime",
  name: "regime",
  defaultValue: "omnivore",
  logo: require("../assets/icons/deco_icons/icons8-real-food-for-meals.svg").default,
  data: [
    { name: "regime", value: "omnivore", label: "Omnivore", isDefault: true, logo: require("../assets/icons/deco_icons/icons8-thanksgiving.svg").default},
    { name: "regime", value: "vegetarien", label: "Végétarien", isDefault: false, logo: require("../assets/icons/deco_icons/icons8-vegetarian-food.svg").default},
    { name: "regime", value: "vegetalien", label: "Végétalien", isDefault: false, logo: require("../assets/icons/deco_icons/icons8-vegan-food.svg").default}
  ]
}

export const seasons = {
  title: "Saisons",
  name: "seasons",
  logo: require("../assets/icons/deco_icons/icons8-four-seasons.svg").default,
  defaultValue: [],
  data:
  [
    { name: "printemps", label: "Printemps", defaultValue: "printemps", logo: require("../assets/icons/deco_icons/icons8-sakura.svg").default},
    { name: "ete", label: "Eté", defaultValue: "ete", logo: require("../assets/icons/deco_icons/icons8-sun.svg").default},
    { name: "automne", label: "Automne", defaultValue: "automne", logo: require("../assets/icons/deco_icons/icons8-autumn.svg").default},
    { name: "hiver", label: "Hiver", defaultValue: "hiver", logo: require("../assets/icons/deco_icons/icons8-snow.svg").default}
  ],
}

export const preparationTime = {
  name: "preparationTime",
  title: "Temps de préparation",
  defaultValue: 5,
  unite: "min",
  step: 5,
  min: 5,
  logo: require("../assets/icons/deco_icons/icons8-sablier-yellow.svg").default,
}

export const cuissonTime = {
  name: "cuissonTime",
  title: "Temps de cuisson",
  defaultValue: 0,
  unite: "min",
  step: 5,
  min: 0,
  logo: require("../assets/icons/deco_icons/icons8-sablier-red.svg").default,
}

export const refrigerationTime = {
  name: "refrigerationTime",
  title: "Temps de réfrigération",
  defaultValue: 0,
  unite: "min",
  step: 5,
  min: 0,
  logo: require("../assets/icons/deco_icons/icons8-sablier-blue.svg").default
}

export const reposTime = {
  name: "reposTime",
  title: "Temps de repos",
  defaultValue: 0,
  unite: "min",
  step: 5,
  min: 0,
  logo: require("../assets/icons/deco_icons/icons8-sablier-green.svg").default
}

export const totalTime = {
  name: "totalTime",
  title: "Temps total",
  defaultValue: 5,
  unite: "min",
  step: 5,
  min: 5,
  logo: require("../assets/icons/deco_icons/icons8-sand-timer.svg").default
}

export const ingredients = {
  title: "Liste des ingrédients",
  name: "ingredients",
  defaultValue: [],
}

export const etapes = {
  title: "Etapes de préparation",
  name: "etapes",
  defaultValue: ["",""],
}

export const imageRecipe = {
  title: "Photo de la recette",
  name: "imageRecipe",
}

export const nameSearch = {
  name: "name",
  title: "Nom d'une recette",
  placeholder: "Entrer le nom d'une recette à chercher",
  defaultValue: "",
  logo: require("../assets/icons/deco_icons/icons8-pencil-drawing.svg").default,
}

export const offset = {
  name: "offset",
  defaultValue: 0,
}

export const typeOfFetch = {
  name: "typeOfFetch",
  defaultValue: "random",
}

export const numberOfRecipe = {
  name: "numberOfRecipe",
  title: "Recettes à tirer",
  defaultValue: 7,
  unite: "",
  step: 1,
  min: 1,
  logo: require("../assets/icons/deco_icons/icons8-dice_1.svg").default,
}

export const recipeFormValues = {
  name,
  couvert,
  type,
  complexity,
  regime,
  seasons,
  preparationTime,
  cuissonTime,
  refrigerationTime,
  reposTime,
  totalTime,
  ingredients,
  etapes,
  imageRecipe,
}

export const filterRecipeFormValues = {
  nameSearch,
  type,
  complexity,
  regime,
  seasons,
  totalTime,
  ingredients,
  offset,
  typeOfFetch,
  numberOfRecipe
}