import React from 'react'
import { DecoIcon } from '../../images/IconImage';
import { H3 } from '../../texts/Texts';
import clsx from 'clsx';

function CouvertCardRecipe({ couvert }) {
  const divContainerStyle= clsx(
    "static md:absolute md:-top-0 md:-left-2",
    "flex flex-col justify-center items-center",
  )

  const divOutsideDisheStyle = clsx(
    "absolute flex flex-row justify-center items-center",
    "rounded-full",
    "bg-sky-100",
    "w-6 h-6 md:w-8 md:h-8",
    "border-2 border-sky-600"
  )

  const divInsideDisheStyle = clsx(
    "flex flex-row justify-center items-center",
    "rounded-full",
    "bg-sky-50",
    "w-4 h-4 md:w-6 md:h-6"
  )

  return (
    <div className={divContainerStyle}>
      <DecoIcon
        iconName={"icons8-cutlery"}
        altName={"couvert"}
        size={"h-10 w-10 md:h-14 md:w-14"}
      />
      <div className={divOutsideDisheStyle}>
        <div className={divInsideDisheStyle}>
          <H3
            add={"flex flex-row item-center justify-center top-8"}
            weight={"font-extrabold"}
            size={"text-sm md:text-base"}
          >
            {couvert}
          </H3>
        </div>
      </div>
    </div>
  );
}

export default CouvertCardRecipe