import React, { useEffect, useMemo, useState } from "react";
import clsx from "clsx";
import { H2 } from "../../elements/texts/Texts";
import { useLocation, useNavigate } from "react-router-dom";
import { PROFILE, routesHeaderFct } from "../../../constants/routes";
import { useTransition, animated } from "react-spring";
import { useDispatch, useSelector } from "react-redux";
import {
  KEYS_USER_DATA,
  SLICE_USER_DATA,
  setMobileRouteHeader,
} from "../../../redux/slices/userDataSlice";
import LogoLoading from "../../../assets/icons/deco_icons/icons8-meowth.svg";

function MobileHeaderNav() {
  const divContainerStyle = clsx(
    "flex flex-row justify-end items-center ",
    "h-full w-full",
    // "border-2 border_black",
    "space-x-8",
    "px-2",
  );

  const divBaseImgStyle = clsx(
    "rounded-xl",
    "border border-black",
    "flex flex-row items-center justify-center",
    "h-14",
    "w-14"
  );

  const divMainImgStyle = clsx(
    divBaseImgStyle,
    "bg-sky-100",
  );

  const divOtherImgStyle = clsx(
    divBaseImgStyle,
    "bg-lime-100",
  );

  const imgStyle = clsx("h-14 w-14");

  const animatedDivStyle = clsx(
    "w-screen",
    "h-18",
    "top-15",
    "right-0",
    // "left-0",
    "flex flex-row justify-between items-end",
    "bg-lime-200",
    "px-2",
    "absolute",
    "-z-10",
    "pb-2"
  );


  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch()
  const mobileRouteHeader = useSelector(state => state.userData[KEYS_USER_DATA.MOBILE_ROUTE_HEADER])
    
  useEffect(() => {
    if (mobileRouteHeader !== location.pathname) {
      dispatch(setMobileRouteHeader(location.pathname))
    }
  }, [dispatch, location.pathname, mobileRouteHeader])
  
  const { avatar } = useSelector((state) => ({
    avatar: state[SLICE_USER_DATA][KEYS_USER_DATA.AVATAR],
  }));

  const elmtToDisplay = useMemo(() => {
    const result = routesHeaderFct.find((route) => {
      if(route.route.includes(PROFILE.route) ) {
        return PROFILE.route
      }
      else {
        return route.route === location.pathname;
      }
    });
    return result;
  }, [location.pathname]);


  const [show, setShow] = useState(false);

  const transitions = useTransition(show, {
    from: {
      transform: "translate3d(0, -100%, 0)",
      transformOrigin: "top right",
    },
    enter: { transform: "translate3d(0%, 0, 0)" },
    leave: {
      transform: "translate3d(100%, 0, 0)",
      onStart: () => {
        if (elmtToDisplay.route !== mobileRouteHeader) {
          navigate(mobileRouteHeader);
        }
      },
    },
  });

  function handleOpeningMenu() {
    setShow(!show);
  }

  function closeVerticalNav(route) {
    handleOpeningMenu();

    if (elmtToDisplay.route !== route) {
      dispatch(setMobileRouteHeader(route))
    }
  }

  return (
    <>
    <div className={divContainerStyle} onClick={handleOpeningMenu}>
      <H2 size={"text-3xl"}>
        {elmtToDisplay ? elmtToDisplay.name : "Mealster"}
      </H2>
      <div className={divMainImgStyle}>
        {(elmtToDisplay.route.includes(PROFILE.route)) && (
          <img
            src={require(`../../../assets/icons/profile_icons/${avatar}.svg`)}
            className={imgStyle}
            alt={`logo_${elmtToDisplay.name}`}
          />
        )}
        {!elmtToDisplay.route.includes(PROFILE.route) && (
          <img
            src={elmtToDisplay ? elmtToDisplay.logo : LogoLoading}
            className={imgStyle}
            alt={`logo ${elmtToDisplay ? elmtToDisplay.name : "Mealster"}`}
          />
        )}
      </div>
    </div>
    {transitions((style, item) => {
        if (item) {
          return (
            <animated.div style={style} className={animatedDivStyle}>
              {
                // eslint-disable-next-line array-callback-return
                routesHeaderFct
                  .filter((route) => {
                    if (location.pathname.includes('/profile')) {
                      return !route.route.includes('/profile') && route.route !== location.pathname;
                    } else {
                      return route.route !== location.pathname;
                    }
                  })
                  .map((route) => {
                    const isProfilePathInclude = route.route.includes(PROFILE.route);
                    return (
                      <button
                        key={route.name}
                        // className={buttonsMapStyle}
                        onClick={() => closeVerticalNav(route.route)}
                      >
                        <div className={divOtherImgStyle}>
                          {(isProfilePathInclude) && (
                            <img
                              src={require(`../../../assets/icons/profile_icons/${avatar}.svg`)}
                              className={imgStyle}
                              alt={`logo_${route.name}`}
                            />
                          )}
                          {!isProfilePathInclude && (
                            <img
                              src={route.logo}
                              className={imgStyle}
                              alt={`logo_${route.name}`}
                            />
                          )}
                        </div>
                      </button>
                    );
                  })
              }
            </animated.div>
          );
        } else return null;
      })}
    </>
  );
}

export default MobileHeaderNav;
