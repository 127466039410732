import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setStepFilled, INGREDIENTS_STEP } from "../../redux/slices/editRecipeSlice";

export function useIsIngredientsEditRecipeFilled(lengthOfIngredients) {
  const dispatch = useDispatch();

  useEffect(() => {
    if (lengthOfIngredients >= 2) {
      dispatch(setStepFilled({ stepIndex: INGREDIENTS_STEP, isFilled: true }));
    } else {
      dispatch(setStepFilled({ stepIndex: INGREDIENTS_STEP, isFilled: false }));
    }
  }, [lengthOfIngredients, dispatch]);
}
