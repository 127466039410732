import { useCallback, useState, useEffect, useMemo } from "react";
import { useQuery, useMutation } from "react-query";
import {
  food_user_list,
  foods_bought,
  foods_user_list_regenerate,
} from "../../functions/requests/axiosRequests";
import useError from "../../hooks/utils/useError";
import { DivRow } from "../elements/divs/Divs";
import { SectionCol } from "../elements/sections/Sections";
import { H1, H3, P } from "../elements/texts/Texts";
import { Li, UlCol } from "../elements/lists/Lists";
import { CheckBoxInput } from "../elements/inputs/CheckBoxInputs";
import { FoodIcon } from "../elements/images/IconImage";
import { ButtonFill } from "../elements/bouttons/Buttons";
import useDebounce from "../../hooks/utils/useDebounce";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import { RECETTES } from "../../constants/routes";
import { KEYS_RECIPES_TO_COOK, SLICE_RECIPES_TO_COOK, setARecipeToCookIsUpdated } from "../../redux/slices/recipesToCookSlice";
import { useDispatch, useSelector } from "react-redux";

function Courses() {
  const { setError } = useError();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [foodsFetched, setFoodsFetched] = useState([]);
  const [hasRecipeToCook, setHasRecipeToCook] = useState(false);
  const {aRecipeToCookIsUpdated} = useSelector((state) => ({
    aRecipeToCookIsUpdated: state[SLICE_RECIPES_TO_COOK][KEYS_RECIPES_TO_COOK.A_RECIPE_TO_COOK_IS_UPDATED]
  }));
  // console.log('aRecipeToCookIsUpdated', aRecipeToCookIsUpdated)
  const shouldUpdate = useMemo(() => {
    return aRecipeToCookIsUpdated
  }, [aRecipeToCookIsUpdated])

  const {
    isLoading,
    isFetching,
    isSuccess,
    isError,
    refetch: refetchFoodsList,
  } = useQuery("courses", food_user_list, {
    retry: 0,
    onSuccess: (data) => {
      setFoodsFetched(
        data.foodsList.map((foodItem) => {
          return { ...foodItem, selected: false };
        })
      );
      setHasRecipeToCook(data.hasRecipesToCook);
    },
    onError: (error) => {
      setError(error.response?.data?.message || "Une erreur s'est produite");
    },
  });

  const foodsBought = useMutation(foods_bought, {
    onSuccess: (data) => {
      setFoodsFetched((prevState) =>
        prevState.filter((foodItem) => !foodItem.selected)
      );
    },
    onError: (error) => {
      setError(
        error.response?.data?.message ||
          "Une erreur s'est produite lors de la mise à jour des ingrédients sélectionnés"
      );
    },
  });

  const [onDebounce, cancelDebounce] = useDebounce((foodsSelected) => {
    foodsBought.mutate({
      foodsBought: foodsSelected.map((foodItem) => foodItem.food._id),
    });
  }, 2000);

  const handleSelect = useCallback(
    (index) => {
      const foodsFetchedSelected = foodsFetched.map((foodItem, i) => {
        if (i === index) {
          return { ...foodItem, selected: !foodItem.selected };
        }
        return foodItem;
      });
      setFoodsFetched(foodsFetchedSelected);

      const foodsSelected = foodsFetchedSelected.filter(
        (foodItem) => foodItem.selected
      );
      if (foodsSelected.length > 0) {
        onDebounce(foodsSelected);
      } else {
        cancelDebounce();
      }
    },
    [onDebounce, cancelDebounce, foodsFetched]
  );

  const regenerateList = useMutation(foods_user_list_regenerate, {
    onSuccess: (data) => {
      refetchFoodsList();
    },
    onError: (error) => {
      setError(error.response?.data?.message || "Une erreur s'est produite");
    },
  });

  useEffect(() => {
    // console.log('shouldUpdate', shouldUpdate)
    if (shouldUpdate) {
      dispatch(setARecipeToCookIsUpdated(false))
      regenerateList.mutate()
    }
  }, [shouldUpdate, regenerateList, dispatch]);


  return (
    <SectionCol>
      <H1 add="mt-4">Liste de courses</H1>
      {(isLoading || isFetching) && <P>Chargement...</P>}
      {isSuccess && (
        <UlCol it="items-start" add="space-y-4 mt-8">
          {foodsFetched.map((foodItem, index) => (
            <FoodToTake
              key={foodItem.food._id}
              foodItem={foodItem}
              index={index}
              selecter={handleSelect}
            />
          ))}
        </UlCol>
      )}
      {isError && <P>Une erreur s'est produite</P>}
      {hasRecipeToCook && (
        <ButtonFill add={"mt-8"} onClick={regenerateList.mutate}>
          Regénérer la liste
        </ButtonFill>
      )}
      {!hasRecipeToCook && (
        <>
          <H3>
            Il n'y a pas de liste car vous n'avez pas de recettes à préparer
          </H3>
          <ButtonFill add={"mt-8"} onClick={() => navigate(RECETTES.route)}>
            Choisir des recettes
          </ButtonFill>
        </>
      )}
    </SectionCol>
  );
}

function FoodToTake({ foodItem, index, selecter }) {
  const selection = {
    name: `food-${foodItem.food._id}`,
    type: "checkbox",
    logo: require("../../assets/icons/deco_icons/icons8-check-icon.svg")
      .default,
  };

  const handleSelect = () => {
    selecter(index);
  };
  let uniteToDisplay =
    foodItem.food.unite === "pièce" && foodItem.total_quantity > 1
      ? "pièces"
      : foodItem.food.unite;

  return (
    <Li add="cursor-pointer">
      <DivRow ju="justify-start">
        <CheckBoxInput
          item={selection}
          value={foodItem.selected}
          setter={handleSelect}
        />
        <DivRow add="pl-4" onClick={handleSelect}>
          <FoodIcon
            iconName={foodItem.food.icon}
            altName={foodItem.food.name}
          />
          <P
            add={clsx(
              "pl-2",
              foodItem.selected ? "line-through decoration-2" : ""
            )}
          >
            {foodItem.food.name} : {foodItem.total_quantity} {uniteToDisplay}
          </P>
        </DivRow>
      </DivRow>
    </Li>
  );
}

export default Courses;
