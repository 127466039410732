import clsx from "clsx";

export const DivCol = ({ it, ju, add, children, onClick, testid }) => {
  const sDiv = clsx(
    "flex",
    "flex-col",
    it === "no" ? "" : it ? it : "items-center",
    ju === "no" ? "" : ju ? ju : "justify-center",
    add ? add : "",
  );

  return <div className={sDiv} onClick={onClick} testid={testid}>{children}</div>;
}

export const DivRow = ({ it, ju, add, children, onClick, testid }) => {
  const sDiv = clsx(
    "flex",
    "flex-row",
    it === "no" ? "" : it ? it : "items-center",
    ju === "no" ? "" : ju ? ju : "justify-center",
    add ? add : "",
  );

  return <div className={sDiv} onClick={onClick} testid={testid} >{children}</div>;
}

export const DivColRow = ({ it, ju, add, children, onClick, testid }) => {
  const sDiv = clsx(
    "flex",
    "flex-col sm:flex-row",
    it === "no" ? "" : it ? it : "items-center",
    ju === "no" ? "" : ju ? ju : "justify-center",
    add ? add : "",
  );

  return <div className={sDiv} onClick={onClick} testid={testid}>{children}</div>;
}

export const DivColRowRev = ({ it, ju, add, children, testid }) => {
  const sDiv = clsx(
    "flex",
    "flex-col-reverse sm:flex-row",
    it === "no" ? "" : it ? it : "items-center",
    ju === "no" ? "" : ju ? ju : "justify-center",
    add ? add : "",
  );

  return <div className={sDiv} testid={testid}>{children}</div>;
}

export const DivGrid = ({ add, children, testid }) => {
  const sDiv = clsx(
    "grid",
    "grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5",
    add ? add : "",
  );
  return <div className={sDiv} testid={testid}>{children}</div>;
};

export const DivFixed = ({ z, opa, col, add, children, testid }) => {
  const sDiv = clsx(
    "fixed",
    "top-0 left-0",
    "h-full w-full",
    z ? z : "z-20",
    opa ? `opacity-${opa}` : "bg-opacity-80",
    add ? add : "",
    col ? col : "bg-white",
  )

  return <div className={sDiv} testid={testid}>{children}</div>;
}