import React from "react";
import GlobalModal from "./GlobalModal";
import clsx from "clsx";
import { useMutation } from "react-query";
import { emailConfirm } from "../../../functions/requests/axiosRequests";
import { sH1, sH2, sH3 } from "../../../styles/styles";
import { Spans, Span } from "../spans/Spans";
import { ButtonFill } from "../bouttons/Buttons";
import { DivCol } from "../divs/Divs";
import ButtonCloseModal from "../bouttons/ButtonCloseModal";
import { setDisplayPopupEmailVerif } from "../../../redux/slices/loginDataSlice";
import { useDispatch } from "react-redux";

const EmailVerificationModal = ({ data }) => {
  const dispatch = useDispatch();
  const {
    mutate: postEmailConfirm,
    isSuccess,
    isLoading,
    isError,
    error,
  } = useMutation(emailConfirm, { mutationKey: "emailConfirm" });

  const divDisplayInfoStyle = clsx(
    "absolute",
    "bg-lime-100",
    "w-4/5 lg:w-2/5",
    "h-3/5 lg:h-3/5",
    "rounded-lg",
    "drop-shadow-3xl",
    "px-4",
    "lg:pb-0"
  );

  function closeModal() {
    dispatch(setDisplayPopupEmailVerif(false));
  }

  return (
    <GlobalModal>
      <div className={divDisplayInfoStyle}>
        <ButtonCloseModal closeModal={closeModal} />
        <DivCol>
          <h1 className={clsx(sH1, "pt-12")}>Validation du compte</h1>
          <h2 className={clsx(sH2, "pt-2")}>
            Merci de vérifier votre adresse email !
          </h2>
          <div className="flex flex-col items-center space-y-4">
            <h3 className={clsx(sH3, "pt-12")}>Non reçu ?</h3>
            <ButtonFill testid="renvoi" onClick={() => postEmailConfirm(data)}>
              Renvoyer le mail
            </ButtonFill>
            <Spans>
              {isSuccess && <Span>Email renvoyé !</Span>}
              {isLoading && <Span>En attente de réponse...</Span>}
              {isError && <Span data-testid="error-message">{error}</Span>}
            </Spans>
          </div>
        </DivCol>
      </div>
    </GlobalModal>
  );
};

export default EmailVerificationModal;
