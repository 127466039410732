import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AuthProvider from "./context/AuthProvider";
import ErrorProvider from "./context/ErrorProvider";
import { QueryClient, QueryClientProvider } from "react-query";
import { Provider } from 'react-redux';
import store from './redux/store';

const queryClient = new QueryClient();

createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <ErrorProvider>
          <AuthProvider>
          <Provider store={store}>
            <Routes>
              <Route path="/*" element={<App />} />
            </Routes>
            </Provider>
          </AuthProvider>
        </ErrorProvider>
      </QueryClientProvider>
    </BrowserRouter>
  </React.StrictMode>
);
