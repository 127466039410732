import { createSlice} from "@reduxjs/toolkit";

export const INFOS_USER = "infos";
export const RECIPES_USER = "recipes";
export const TEMP_RECIPES = "temp_recipes";
export const USER_RECIPES_CREATED = "created";
export const FAVORITES_RECIPES_USER = "favorites";

const initialState = {
  dataToShow: RECIPES_USER,
  recipesToShow: USER_RECIPES_CREATED,
  displayRecipe: false,
}

export const SLICE_PROFILE_PAGE = "profilePage";
export const KEYS_PROFILE_PAGE = {
  DATA_TO_SHOW: "dataToShow",
  RECIPES_TO_SHOW: "recipesToShow",
};

const profilePageSlice = createSlice({
  name: SLICE_PROFILE_PAGE,
  initialState,
  reducers: {
    setDataToShow: (state, action) => {
      state.dataToShow = action.payload;
    },
    setRecipesToShow: (state, action) => {
      state.recipesToShow = action.payload;
    },
    setDisplayRecipe: (state, action) => {
      state.displayRecipe = action.payload;
    }
  },
});

export const {
  setDataToShow,
  setRecipesToShow,
  setDisplayRecipe
} = profilePageSlice.actions;

export default profilePageSlice.reducer;