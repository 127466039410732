import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  email: {
    title: "Adresse email",
    name: "email",
    type: "email",
    placeholder: "Veuillez renseigner votre email",
    value:"",
  },
  author: {
    title: "Pseudonyme",
    name: "author",
    type: "text",
    placeholder: "Veuillez renseigner votre pseudo",
    value: "",
  },
  pwd: {
    title: "Mot de passe",
    name: "pwd",
    type: "password",
    placeholder: "Entrez votre mot de passe",
    value: "",
  },
  cpwd: {
    title: "Confirmation mot de passe",
    name: "cpwd",
    type: "password",
    placeholder: "Confirmer votre mot de passe",
    value: "",
  },
  invalidLogin: false,
  invalidEmailLogin: false,
  pwdError: false,
  emptyPwdLogin: false,
  pwdNotValid: false,
  pwdsNotEqual: false,
  authorEmpty: false,
  existingEmailRegister: false,
  existingAuthorRegister: false,
  displayPopupEmailVerif: false
};

export const SLICE_LOGIN_DATA = "loginData";
export const KEYS_LOGIN_DATA = {
  EMAIL: "email",
  AUTHOR: "author",
  PWD: "pwd",
  CONFIRM_PWD: "cpwd",
  INVALID_LOGIN: "invalidLogin",
  INVALID_EMAIL_LOGIN: "invalidEmailLogin",
  PWD_ERROR: "pwdError",
  EMPTY_PWD_LOGIN: "emptyPwdLogin",
  PWD_LENGTH_ERROR: "pwdLengthError",
  AUTHOR_EMPTY: "authorEmpty",
  EXISTING_EMAIL_REGISTER: "existingEmailRegister",
  EXISTING_AUTHOR_REGISTER: "existingAuthorRegister",
  DISPLAY_POPUP_EMAIL_VERIF: "displayPopupEmailVerif",
};

const loginDataSlice = createSlice({
  name: SLICE_LOGIN_DATA,
  initialState,
  reducers: {
    setEmailAuth: (state, action) => {
      state.email.value = action.payload;
    },
    setAuthorAuth: (state, action) => {
      state.author.value = action.payload;
    },
    setPwdAuth: (state, action) => {
      state.pwd.value = action.payload;
    },
    setCpwdAuth: (state, action) => {
      state.cpwd.value = action.payload;
    },
    setInvalidLogin: (state, action) => {
      state.invalidLogin = action.payload;
    },
    setInvalidEmailLogin: (state, action) => {
      state.invalidEmailLogin = action.payload;
    },
    setPwdError: (state, action) => {
      state.pwdError = action.payload;
    },
    setAuthorEmpty: (state, action) => {
      state.authorEmpty = action.payload;
    },
    setExistingEmailRegister: (state, action) => {
      state.existingEmailRegister = action.payload;
    },
    setExistingAuthorRegister: (state, action) => {
      state.existingAuthorRegister = action.payload;
    },
    setDisplayPopupEmailVerif: (state, action) => {
      state.displayPopupEmailVerif = action.payload;
    }
  },
});

export const { 
  setEmailAuth,
  setAuthorAuth,
  setPwdAuth,
  setCpwdAuth,
  setInvalidLogin,
  setInvalidEmailLogin,
  setPwdError,
  setExistingEmailRegister,
  setExistingAuthorRegister,
  setDisplayPopupEmailVerif,
 } = loginDataSlice.actions;

export default loginDataSlice.reducer;
