import clsx from "clsx";
import { DecoIcon } from "../images/IconImage";
import { P } from "../texts/Texts";
import { ButtonFill } from "./Buttons";
import useAddRecipesToCookMutation from "../../../hooks/requests/useAddRecipesToCookMutation";
import { useSelector } from "react-redux";
import {
  KEYS_FILTERS_RECIPES,
  SLICE_FILTERS_RECIPES,
} from "../../../redux/slices/filtersRecipesSlice";
import {
  KEYS_RECIPES,
  SLICE_RECIPES,
} from "../../../redux/slices/recipesSlice";

function AddRecipesButton({ recipeShown = null }) {

  const couvert = useSelector(
    (state) => state[SLICE_FILTERS_RECIPES][KEYS_FILTERS_RECIPES.COUVERT]
  );
  const repeat = useSelector(
    (state) => state[SLICE_FILTERS_RECIPES][KEYS_FILTERS_RECIPES.REPEAT]
  );
  const recipesFetched = useSelector(
    (state) => state[SLICE_RECIPES][KEYS_RECIPES.RECIPES_FETCHED]
  );

  const addRecipesToCookMutation = useAddRecipesToCookMutation();

  const isDisabled =
    recipeShown !== null
      ? false
      : !recipesFetched.some((recipe) => recipe.selected === true)
      ? true
      : false;

  function handleValidationSelection() {
    let chosenRecipesToSend = {};
    if (recipeShown !== null) {
      chosenRecipesToSend = [
        {
          recipe: recipeShown._id,
          couvert: couvert.value,
          repeat: repeat.value,
        },
      ];
    } else {
      chosenRecipesToSend = recipesFetched.reduce((acc, recipe) => {
        if (recipe.selected) {
          acc.push({
            recipe: recipe._id,
            couvert: couvert.value,
            repeat: repeat.value,
          });
        }
        return acc;
      }, []);
    }
    addRecipesToCookMutation.mutate({ chosenRecipes: chosenRecipesToSend });
  }

  const buttonAddStyle = clsx(
    recipeShown !== null
      ? "flex flex-row justify-center items-center"
      : "flex md:flex-col lg:flex-row justify-center items-center",
    ""
  );

  const sizeTextStyle = clsx(
    // "text-sm sm:text-base"
    "text-xs md:text-sm lg:text-base"
  );

  const sizeButtonStyle = clsx(
    recipeShown !== null ? "w-full" : "h-12 w-auto md:h-15"
  );

  const sizeIcon = clsx("h-8 md:h-8 lg:h-10", "w-8 md:w-8 lg:w-10");

  return (
    <ButtonFill
      add={buttonAddStyle}
      col={"bg-green-400"}
      size={sizeButtonStyle}
      disabled={isDisabled}
      onClick={handleValidationSelection}
    >
      <P col={"text-white"} size={sizeTextStyle}>
        Ajouter à
      </P>
      <DecoIcon
        iconName={"icons8-cutting-a-carrot"}
        altName={"icon_add"}
        size={sizeIcon}
      />
    </ButtonFill>
  );
}

export default AddRecipesButton;
