import React from 'react'
import { H2 } from '../../elements/texts/Texts'
import clsx from 'clsx';


function NameRecipe({ nameRecipe }) {
    const divNameRecipeStyle = clsx(
        "text-base",
        "sm:text-lg",
        "md:text-2xl",
        "lg:text-3xl",
    )

    return <H2 
    add="text-center line-clamp-2"
    size={divNameRecipeStyle} 
    testid="name_recipe"
    >{nameRecipe}</H2>;
}

export default React.memo(NameRecipe)