import clsx from "clsx";

export const Span = ({ children, testid, col, add, onClick}) => {
  const spanStyle = clsx(
    col ? col : "text-sky-600",
    "text-sm sm:text-base",
    "font-spin",
    "font-normal",
    add ? add : ""
  );

  return <span className={spanStyle} testid={testid} onClick={onClick}>{children}</span>;
};

export const Spans = ({ children }) => {
  const divStyle = clsx(
    // apply a default heigth even if children hidden
    "h-3"
  );

  return <div className={divStyle}>{children}</div>;
  // return (
  // <div className={divStyle}>
  //   {errorsToDisplay.map((error) => (
  //     <Span key={error.errMess} isDisplay={error.isDisplay}>
  //       {error.errMess}
  //     </Span>
  //   ))}
  // </div>
  // );
};
