import { useMutation } from "react-query";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "../../functions/requests/axiosRequests";
import { setEmail } from "../../redux/slices/userDataSlice";
import { setEmailAuth, setPwdAuth } from "../../redux/slices/loginDataSlice";
import useLocalStorage from "../utils/useLocalStorage";
import { LOGIN } from "../../constants/routes";
import { useEffect } from "react";
import { useQueryClient } from "react-query";

function useLogoutMutation() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [value, setValue] = useLocalStorage("persist", false);

  const logoutRequest = useMutation(logout);

  useEffect(() => {
    if (logoutRequest.isSuccess || logoutRequest.isError) {
      setValue(false);
      dispatch(setEmailAuth(""));
      dispatch(setPwdAuth(""));
      if (value === false) {
        dispatch(setEmail(""));
        queryClient.invalidateQueries();
        navigate(LOGIN.route);
      }
    }
  }, [
    logoutRequest.isSuccess,
    logoutRequest.isError,
    dispatch,
    navigate,
    value,
    setValue,
    queryClient,
  ]);

  return logoutRequest;
}

export default useLogoutMutation;
