import React from "react";
import { DecoIcon } from "../images/IconImage";
import clsx from "clsx";
import { H3 } from "../texts/Texts";
import useWindowWidth from "../../../hooks/utils/useWindowWidth";

function ButtonIconSelection({
  selectionList,
  setSelection,
  isActive,
  onActive,
}) {
  const isMobile = useWindowWidth();

  function handleSelection(value) {
    setSelection(value);
    if(isMobile) {
      onActive();
    }
  }

  //#region
  const buttonIconStyle = clsx(
    "flex flex-row items-center justify-center overflow-visible",
    "h-10 sm:h-12 md:h-14 lg:h-16",
    "w-10 sm:w-12 md:w-14 lg:w-16",
    isMobile && !isActive ? "bg-lime-100 rounded-md" : "",
    isMobile && isActive ? "bg-sky-100 rounded-xl" : "",
    !isMobile ? "bg-lime-100 rounded-md hover:bg-sky-100 hover:rounded-xl" : "",
    "shadow-md",
    "transition-all duration-100 ease-linear",
    "relative",
    "border-2 border-sky-900",
    "group",
    "cursor-pointer"
  );

  const spanTextStyle = clsx(
    // showSelectionMenu ? "hidden" : "",
    "absolute",
    "bg-sky-100 text-sky-700",
    "rounded-md",
    "w-auto",
    "px-7 py-2",
    "-top-[46px] md:top-[70px]",
    "text-xl",
    "font-spin",
    "transition-all duration-100 scale-0 origin-top",
    isMobile && isActive ? "scale-100" : "",
    // isMobile && showSelectionMenu ? "scale-100" : "",
    !isMobile ? "group-hover:scale-100" : ""
    // showSelectionMenu ?
    // "border-2 border-sky-700"
  );

  const divListMenuStyle = clsx(
    "absolute",
    "-top-[160px] md:top-[105px]",
    "transition-all duration-100 scale-0 origin-top",
    isMobile && isActive ?"scale-100" : "",
    !isMobile ? "group-hover:scale-100" : "",
    "flex flex-col items-center justify-center",
    "bg-sky-100",
    "rounded-md",
    // "shadow-md",
    "w-40"
  );

  const buttonMapStyle = (isSelected) =>
    clsx(
      "cursor-pointer",
      "flex flex-row items-center justify-center",
      isSelected ? "bg-sky-200" : "bg-sky-100",
      "hover:bg-sky-300",
      "w-full",
      "rounded-md"
    );

  //#endregion

  return (
    <div className={buttonIconStyle}>
      <DecoIcon
        iconName={
          selectionList.data.find((item) => item.value === selectionList.value)
            ?.logo
        }
        size={"h-full w-full"}
        onClick={() => {
          if (isMobile) {
            onActive(); // Activez ce bouton
          }
        }}
      />
      <span className={spanTextStyle}>{selectionList.title}</span>
      <div className={divListMenuStyle}>
        {selectionList.data.map((item) => {
          const isSelected = item.value === selectionList.value;
          return (
            <button
              className={buttonMapStyle(isSelected)}
              onClick={() => handleSelection(item.value)}
              key={item.value}
            >
              <DecoIcon iconName={item.logo} altName={item.label} />
              <H3>{item.label}</H3>
            </button>
          );
        })}
      </div>
    </div>
  );
}

export default React.memo(ButtonIconSelection);