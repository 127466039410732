import { useSelector } from "react-redux";
import { SectionCol } from "../../elements/sections/Sections";
import {
  SLICE_PROFILE_PAGE,
  KEYS_PROFILE_PAGE,
  INFOS_USER,
  RECIPES_USER,
  TEMP_RECIPES
} from "../../../redux/slices/profilePageSlice";
import ProfileInfos from "./ProfileInfos";
import ProfileRecipes from "./ProfileRecipes";
import NavbarProfile from "../../elements/navbars/NavbarProfile";
import TempRecipes from "./TempRecipes";


function Profile() {
  const { dataToShow } = useSelector((state) => ({
    dataToShow: state[SLICE_PROFILE_PAGE][KEYS_PROFILE_PAGE.DATA_TO_SHOW],
  }));

  
  return (
    <SectionCol>
      {/* <ProfileNavBar dataToShow={dataToShow} /> */}
      <NavbarProfile />
      
      <ProfileContent dataToShow={dataToShow} />
      
    </SectionCol>
  );
}

function ProfileContent({ dataToShow }) {
  
  return (
    <>
      {dataToShow === INFOS_USER && <ProfileInfos />}
      {dataToShow === RECIPES_USER && <ProfileRecipes />}
      {dataToShow === TEMP_RECIPES && <TempRecipes />}
      </>
  );
}

export default Profile;
