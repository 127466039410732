import clsx from "clsx";

export const H1 = ({ font, size, weight, col, add, children, onClick, testid }) => {
  const sH1 = clsx(
    onClick ? "cursor-pointer" : "",
    font ? font : "font-rubik",
    size ? size : "text-2xl sm:text-3xl md:text-5xl",
    weight ? weight : "font-extrabold",
    col ? col : "text-sky-300",
    add ? add : "",
  );

  return <h1 className={sH1} onClick={onClick} testid={testid}>{children}</h1>;
}

export const H2 = ({ font, size, weight, col, add, children, onClick, testid }) => {
  const sH2 = clsx(
    onClick ? "cursor-pointer hover:bg-sky-100 transition duration-200 ease-in-out" : "",
    font ? font : "font-rubik",
    size ? size : "text-xl sm:text-2xl md:text-3xl",
    weight ? weight : "font-extrabold",
    col ? col : "text-sky-600",
    add ? add : "",
  );

  return <h2 className={sH2} onClick={onClick} testid={testid}>{children}</h2>;
}

export const H3 = ({ font, size, weight, col, add, children, onClick, testid }) => {
  const sH3 = clsx(
    onClick ? "cursor-pointer" : "",
    font ? font : "font-mitr",
    size ? size : "text-base sm:text-lg",
    weight ? weight : "font-medium",
    col ? col : "text-sky-800",
    add ? add : "",
  );

  return <h3 className={sH3} onClick={onClick} testid={testid}>{children}</h3>;
}

export const P = ({ font, size, weight, col, add, children, onClick, testid }) => {
  const sP = clsx(
    onClick ? "cursor-pointer" : "",
    font ? font : "font-mitr",
    size ? size : "text-sm sm:text-base",
    weight ? weight : "font-normal",
    col ? col : "text-sky-500",
    add ? add : "",
  );

  return <h3 className={sP} testid={testid}>{children}</h3>;
}