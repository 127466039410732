import { useMutation, useQuery } from 'react-query'
import { useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import useError from '../utils/useError'
import { checkLinkValidityResetPwd, reset_pwd } from '../../functions/requests/axiosRequests'
import { setErrorRequest } from '../../redux/slices/resetPwdSlice'

function useResetPwdQueries() {
  const dispatch = useDispatch()
  const location = useLocation()
  const { setError } = useError()

  const linkValidityResetPwdQuery = useQuery({
    queryKey: "checkLinkValidityResetPwd",
    queryFn: () => checkLinkValidityResetPwd(location),
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    retry: false,
    onError: (err) => {
      if (
        err.response.data.status
      ) {
        dispatch(setErrorRequest(err.response.data.message))
      } else {
        setError(err.response.data.message);
      }
    },
  });

  const resetPwdMutation = useMutation(reset_pwd, {
    onError: (err) => {
      if (err.response.data.status) {
        dispatch(setErrorRequest(err.response.data.message))
      }
      else {
        setError(err.response.data.message);
      }
    },
    mutationKey: "forgot_pwd",
  });

  return {linkValidityResetPwdQuery, resetPwdMutation}
}

export default useResetPwdQueries