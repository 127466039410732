import { useState } from "react";
import { useSelector } from "react-redux";
import { AvatarsListModal, ConfirmDeleteAccountModal } from "../../elements/modals/Modals";
import { DivCol, DivColRowRev } from "../../elements/divs/Divs";
import { H2, H3, P } from "../../elements/texts/Texts";
import { KEYS_USER_DATA, SLICE_USER_DATA } from "../../../redux/slices/userDataSlice";
import { LogoutAndDeleteButtons, UpdateUserDatasButtons } from "../../elements/bouttons/ProfileButtons";
import clsx from "clsx";

function ProfileInfos() {
  const [displayAvatars, setDisplayAvatars] = useState(false);
  const [displayDeleteAccount, setDisplayDeleteAccount] = useState(false);

const divContainerStyle = clsx(
    "w-full",
    "pb-4 md:pb-0",
    "md:pt-20 md:mb-0",
    "flex flex-col items-center justify-center",
    "overflow-hidden"
  );
  

  return (
    <div className={divContainerStyle}>
      <ProfileInfosContent
        setDisplayAvatars={setDisplayAvatars}
        setDisplayDeleteAccount={setDisplayDeleteAccount}
      />
      {displayAvatars && (
        <AvatarsListModal
          title={"Choisissez votre avatar"}
          setDisplayAvatars={setDisplayAvatars}
        />
      )}
      {displayDeleteAccount && (
        <ConfirmDeleteAccountModal
          setDisplayDeleteAccount={setDisplayDeleteAccount}
        />
      )}
    </div>
  );
}

function ProfileInfosContent({ setDisplayAvatars, setDisplayDeleteAccount }) {
  return (
    <DivCol add="pt-16 md:pt-4">
      <H2>Informations du compte</H2>
      <DivCol>
        <UserDatasAndUpdate setDisplayAvatars={setDisplayAvatars} />
        <LogoutAndDeleteButtons
          setDisplayDeleteAccount={setDisplayDeleteAccount}
        />
      </DivCol>
    </DivCol>
  );
}

function UserDatasAndUpdate({setDisplayAvatars}) {
  const { email, author, avatar } = useSelector((state) => ({
    email: state[SLICE_USER_DATA][KEYS_USER_DATA.EMAIL],
    author: state[SLICE_USER_DATA][KEYS_USER_DATA.AUTHOR],
    avatar: state[SLICE_USER_DATA][KEYS_USER_DATA.AVATAR],
  }));

  return (
    <DivColRowRev add="pt-8 space-y-8 md:space-y-0 md:justify-start md:items-start">
      <DivCol add="space-y-4 basis-2/3">
        <EmailAndAuthorUser email={email} author={author} />
        <UpdateUserDatasButtons />
      </DivCol>
      <DivCol add="space-y-4 basis-1/3">
        <AvatarUser avatar={avatar} setDisplayAvatars={setDisplayAvatars} />
      </DivCol>
    </DivColRowRev>
  );
}

function EmailAndAuthorUser({ email, author }) {
  return (
    <>
      <DivCol add={"pt-4 sm:pt-0"}>
        <H3>Adresse email</H3>
        <P>{email}</P>
      </DivCol>
      <DivCol>
        <H3>Pseudonyme</H3>
        <P>{author}</P>
      </DivCol>
    </>
  );
}

function AvatarUser({ avatar, setDisplayAvatars }) {
  const pathAvatar = require(`../../../assets/icons/profile_icons/${avatar}.svg`);

  return (
    <>
      <H3>Avatar</H3>
      <DivCol ju="justify-start" add="w-1/2 w-1/2 sm:w-full sm:h-full">
        <img
          src={pathAvatar}
          alt="avatar_utilisateur"
          className="w-full h-full cursor-pointer hover:scale-110"
          onClick={() => setDisplayAvatars(true)}
        />
      </DivCol>
    </>
  );
}

export default ProfileInfos;