import React, { useState } from "react";
import { Blurhash } from "react-blurhash";
import clsx from "clsx";
import { H3 } from "../../texts/Texts";
import { DecoIcon } from "../../images/IconImage";

function ImageAndTitleCardRecipe({
  srcImage,
  blurhash,
  title,
  seasons,
  id,
  type,
}) {
  const divImgStyle = clsx(
    "w-48 md:w-56",
    "h-50 md:h-56",
    "border-4 border-lime-900 rounded-lg",
    "relative",
    "flex flex-col",
    "items-center"
  );

  return (
    <div className={divImgStyle}>
      <ImageCardRecipe
        srcImage={srcImage}
        blurhash={blurhash}
        alt={title}
        type={type}
      />
      <TitleAndSeasonsCardRecipe title={title} seasons={seasons} id={id} />
    </div>
  );
}

function ImageCardRecipe({ srcImage, blurhash, alt, type }) {
  const [loaded, setLoaded] = useState(false);
  const altImage = `image de la recette ${alt}`;
  const blurhashValue = blurhash
    ? blurhash
    : type === "entree"
    ? "USOWG}%2~pR*tQR*afnj~VkCn4RjWVnPaypH"
    : type === "plat"
    ? "UsN^3kWC?ca{n%ofogf6_4j?oLf8W=aeWBWB"
    : "UcO{~vof?vRkxukCRiWA_NofsljYWAt7f9WC";

  const imgStyle = clsx(
    "rounded",
    "bg-white",
    "min-h-full min-w-full",
    "object-cover object-center",
    loaded ? "" : "hidden"
  );

  const blurhashStyle = {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
  };

  return (
    <>
      {!loaded && <Blurhash hash={blurhashValue} style={blurhashStyle} />}
      <img
        src={srcImage}
        alt={altImage}
        onLoad={() => setLoaded(true)}
        className={imgStyle}
      />
    </>
  );
}

function TitleAndSeasonsCardRecipe({ title, seasons, id }) {
  const divContainerStyle = clsx(
    "absolute",
    "bg-yellow-200",
    "rounded-3xl",
    "border-4 border-lime-50",
    "flex flex-row",
    "justify-center items-center",
    "px-2 py-2",
    "h-12 md:h-16",
    "w-36 sm:w-40 md:w-48",
    "bottom-2 md:-bottom-6",
    "overflow-hidden",
    "shadow-lg"
  );

  const divTitleStyle = clsx(
    "relative",
    "flex",
    "justify-center items-center",
    "bg-transparent",
    "h-full w-full"
  );

  return (
    <div className={divContainerStyle}>
      <div className={divTitleStyle}>
        <TitleCardRecipe title={title} />
        {seasons.length > 0 && <SeasonsDecoration seasons={seasons} id={id} />}
      </div>
    </div>
  );
}

function TitleCardRecipe({ title }) {
  const addH3Style = clsx(
    "line-clamp-2",
    "text-center overflow-ellipsis",
    "z-10"
  );
  return (
    <H3
      add={addH3Style}
      col={"text-sky-600"}
      size={"text-xs sm:text-sm md:text-base"}
    >
      {title}
    </H3>
  );
}

function SeasonsDecoration({ seasons, id }) {
  const printempsStyle = clsx(
    "absolute",
    "opacity-75",
    "-top-4 -left-3",
    "sm:-top-6 sm:-left-3",
    "md:-top-7 md:-left-2",
    "z-0"
  );

  const eteStyle = clsx(
    "absolute",
    "opacity-75",
    "-bottom-4 -left-2",
    "sm:-bottom-5 sm:-left-3",
    "md:-bottom-6 md:-left-5",
    "z-0"
  );

  const automneStyle = clsx(
    "absolute",
    "opacity-75",
    "-bottom-4 -right-3",
    "sm:-bottom-5 sm:-right-3.5",
    "md:-bottom-6 md:-right-4",
    "z-0"
  );

  const hiverStyle = clsx(
    "absolute",
    "opacity-75",
    "-top-3 -right-4",
    "sm:-top-4 sm:-right-5",
    "md:-top-5 md:-right-6",
    "z-0"
  );

  const seasonData = [
    {
      name: "printemps",
      icon: "icons8-sakura",
      style: printempsStyle,
    },
    {
      name: "ete",
      icon: "icons8-sun",
      style: eteStyle,
    },
    {
      name: "automne",
      icon: "icons8-autumn",
      style: automneStyle,
    },
    {
      name: "hiver",
      icon: "icons8-snow",
      style: hiverStyle,
    },
  ];

  return (
    <>
      {seasonData.map((season) =>
        seasons.includes(season.name) ? (
          <DecoIcon
            key={season.name}
            iconName={season.icon}
            altName={`${season.name}_icon_${id}`}
            add={season.style}
            size={"h-6 w-6 sm:h-8 sm:w-8 md:h-10 md:w-10"}
          />
        ) : null
      )}
    </>
  );
}
function areEqual(prevProps, nextProps) {
  let arePropsEqual = true;
  
  for (let key in prevProps) {
    if (prevProps[key] !== nextProps[key]) {
      // console.log(`Prop ${key} a changé de ${prevProps[key]} à ${nextProps[key]}`);
      arePropsEqual = false;
    }
  }
  
  return arePropsEqual;
}
export default React.memo(ImageAndTitleCardRecipe, areEqual);
