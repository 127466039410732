import React from "react";
import ImageInput from "../inputs/ImageInput";

function FieldImageOfRecipe({
  state,
  setImageDataUrl,
  setFileName,
  setImageFile,
  setFileSizeError,
}) {
  return (
    <ImageInput
      imageRecipe={state}
      setImageDataUrl={setImageDataUrl}
      setImageFile={setImageFile}
      setFileName={setFileName}
      setFileSizeError={setFileSizeError}
    />
  );
}

export default React.memo(FieldImageOfRecipe);
