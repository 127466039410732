import React from "react";
import clsx from "clsx";
import { DecoIcon } from "../../images/IconImage";
import { H3 } from "../../texts/Texts";

function TimeCardRecipe({ totalTime }) {
  const hours = Math.floor(totalTime / 60);
  const minutes = totalTime % 60;

  const timeString =
    (hours > 0 ? `${hours}h ` : "") + (minutes > 0 ? `${minutes} min` : "");

  const divContainerStyle = clsx(
    "group",
    "h-10 w-10 md:h-12 md:w-12",
    "relative"
  );

  const divDecoIconStyle = clsx(
    "group-hover:rotate-180 transition-all",
    "h-full w-full",
    "absolute",
    "opacity-75"
  );

  const styleH3 = clsx(
    "absolute line-clamp-1 text-center",
    "top-3",
    "-left-3 md:-left-2",
    "rounded-2xl",
    totalTime < 60 ? "w-18 md:w-16" : "w-16 md:w-16",
  );

  return (
    <div className={divContainerStyle}>
      <div className={divDecoIconStyle}>
        <DecoIcon
          iconName={"icons8-sand-timer"}
          altName={"time"}
          size={"h-10 w-10 md:h-12 md:w-12"}
        />
      </div>
      <H3
        add={styleH3}
        weight={"font-medium"}
        col={"text-lime-50"}
        size={"text-sm"}
      >
        {timeString}
      </H3>
    </div>
  );
}

export default React.memo(TimeCardRecipe);
