import React, { useEffect, useState } from "react";
import { SectionCol } from "../elements/sections/Sections";
import { H2, H3 } from "../elements/texts/Texts";
import { FormAuth } from "../elements/forms/Forms";

import { actualPwd, newPwd, confirmNewPwd } from "../../constants/authent";
import { TextInput } from "../elements/inputs/TextInputs";
import { Span, Spans } from "../elements/spans/Spans";
import { useMutation } from "react-query";
import { change_pwd } from "../../functions/requests/axiosRequests";
import ErrorCode from "../../constants/httpErrorCodes";
import { SubmitAndProfileButton } from "../elements/bouttons/ProfileButtons";

function ChangePwd() {
  return (
    <SectionCol add="sm:pt-10">
      <H2>Changer le mot de passe</H2>
      <FormChangePwd />
    </SectionCol>
  );
}

function FormChangePwd() {
  const [_actualPwd, setActualPwd] = useState(actualPwd.defaultValue);
  const [_newPwd, setNewPwd] = useState(newPwd.defaultValue);
  const [_confirmNewPwd, setConfirmNewPwd] = useState(
    confirmNewPwd.defaultValue
  );
  const [formOk, setFormOk] = useState(false);
  const [badPwdAccount, setBadPwdAccount] = useState(false);
  const [errorRequest, setErrorRequest] = useState("");

  const changePwdRequest = useMutation(change_pwd, {
    onSuccess: () => {
      setBadPwdAccount(false);
      setErrorRequest("");
      setActualPwd("");
      setNewPwd("");
      setConfirmNewPwd("");
    },
    onError: (err) => {
      if (err.response.data.status) {
        if (err.response.data.message === ErrorCode.BAD_PWD) {
          setBadPwdAccount(true);
        } else if (
          err.response.data.message === ErrorCode.PASSWORD_UPDATE_FAILED
        ) {
          setErrorRequest(ErrorCode.PASSWORD_UPDATE_FAILED);
        } else {
          setErrorRequest(err.response.data.message);
        }
      } else {
        setErrorRequest(err.response.data.message);
      }
    },
  });

  function onSubmit() {
    changePwdRequest.mutate({
      actualPwd: _actualPwd,
      newPwd: _newPwd,
    });
  }

  useEffect(() => {
    if (
      _actualPwd.length >= 6 &&
      _newPwd.length >= 6 &&
      _newPwd === _confirmNewPwd
    ) {
      setFormOk(true);
    } else {
      setFormOk(false);
    }
  }, [_actualPwd, _newPwd, _confirmNewPwd]);

  return (
    <FormAuth>
      <ActualPwdField value={_actualPwd} setter={setActualPwd} />
      <ErrorActualPwdField badPwdAccount={badPwdAccount} />

      <NewPwdField value={_newPwd} setter={setNewPwd} />
      <ErrorNewPwdField _newPwd={_newPwd} />

      <ConfirmNewPwdField value={_confirmNewPwd} setter={setConfirmNewPwd} />

      <Spans>
        <ErrorConfirmNewPwdField
          _newPwd={_newPwd}
          _confirmNewPwd={_confirmNewPwd}
        />
        <ServerResponseMessages
          mutation={changePwdRequest}
          errorRequest={errorRequest}
          _actualPwdLength={_actualPwd.length}
        />
      </Spans>

      <SubmitAndProfileButton formOk={formOk} onSubmit={onSubmit} />
    </FormAuth>
  );
}

function ActualPwdField({ value, setter }) {
  return (
    <>
      <H3>Mot de passe actuel</H3>
      <TextInput item={actualPwd} value={value} onTextChange={setter} />
    </>
  );
}

function ErrorActualPwdField({ badPwdAccount }) {
  return (
    <Spans>
      {badPwdAccount && (
        <Span testid="actualPwd_wrong">Le mot de passe est incorrect</Span>
      )}
    </Spans>
  );
}

function NewPwdField({ value, setter }) {
  return (
    <>
      <H3>Nouveau mot de passe</H3>
      <TextInput item={newPwd} value={value} onTextChange={setter} />
    </>
  );
}

function ErrorNewPwdField({ _newPwd }) {
  return (
    <Spans>
      {_newPwd.length > 0 && _newPwd.length < 6 && (
        <Span testid="newPwd_short">Mot de passe d'au moins 6 caractères</Span>
      )}
    </Spans>
  );
}

function ConfirmNewPwdField({ value, setter }) {
  return (
    <>
      <H3>Confirmer le nouveau mot de passe</H3>
      <TextInput item={confirmNewPwd} value={value} onTextChange={setter} />
    </>
  );
}

function ErrorConfirmNewPwdField({ _newPwd, _confirmNewPwd }) {
  return (
    <Spans>
      {_newPwd !== _confirmNewPwd && _confirmNewPwd.length > 0 && (
        <Span testid="confirmNewPwd">
          Les mots de passe ne sont pas identiques
        </Span>
      )}
    </Spans>
  );
}

function ServerResponseMessages({ mutation, errorRequest, _actualPwdLength }) {
  return (
    <>
      {mutation.isLoading && <Span>En attente du serveur...</Span>}
      {mutation.isSuccess && _actualPwdLength === 0 && (
        <Span>Mot de passe modifié !</Span>
      )}
      {errorRequest.length > 0 && <Span>{errorRequest}</Span>}
    </>
  );
}

export default ChangePwd;
