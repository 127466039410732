import { useState, useEffect } from "react";
import clsx from "clsx";
import Meowth from "../../../assets/icons/deco_icons/icons8-meowth.svg";

const DotsImgAnimation = () => {
  const [dots, setDots] = useState("");

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (dots.length === 3) {
        setDots("");
      } else {
        setDots(`${dots}.`);
      }
    }, 500);

    return () => clearInterval(intervalId);
  }, [dots]);

  const divMain = clsx(
    "flex",
    "flex-col",
    "sm:flex-row",
    "items-center",
    "justify-start",
    "space-y-12 sm:space-y-0 sm:space-x-12"
  );

  return (
    <div className={divMain}>
      <img
        src={Meowth}
        alt="Dots animation meowth 1"
        className={`animate-spin-slow h-12 w-12 ${
          dots.length > 0 ? "inline" : "invisible"
        }`}
      />
      <img
        src={Meowth}
        alt="Dots animation meowth 2 "
        className={`animate-spin-slow h-12 w-12 ${
          dots.length > 1 ? "inline" : "invisible"
        }`}
      />
      <img
        src={Meowth}
        className={`animate-spin-slow h-12 w-12 ${
          dots.length > 2 ? "inline" : "invisible"
        }`}
        alt="Dots animation meowth 3"
      />
    </div>
  );
};

export default DotsImgAnimation;
