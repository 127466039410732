import { createSlice } from "@reduxjs/toolkit";

export const SLICE_RECIPES_TO_COOK = "recipesToCook";
export const KEYS_RECIPES_TO_COOK = {
  RECIPES_TO_COOK_FETCHED: "recipesToCookFetched",
  STARTERS_TO_COOK_FETCHED: "startersToCookFetched",
  MEALS_TO_COOK_FETCHED: "mealsToCookFetched",
  DESSERTS_TO_COOK_FETCHED: "dessertsToCookFetched",
  IS_ALL_RECIPES_SELECTED: "isAllRecipesSelected",
  SHOW_RESUME_RECIPE: "showResumeRecipe",
  RECIPE_SHOWN: "recipeShown",
  INDEX_RECIPE_SHOWN: "indexRecipeShown",
  IMAGE_RECIPE_SHOWN: "imageRecipeShown",
  TYPE: "type",
  IS_ALL_STARTERS_SELECTED: "isAllStartersSelected",
  IS_ALL_MEALS_SELECTED: "isAllMealsSelected",
  IS_ALL_DESSERTS_SELECTED: "isAllDessertsSelected",
  A_RECIPE_TO_COOK_IS_UPDATED: "aRecipeToCookIsUpdated"
};

export const STARTERS = "entree";
export const MEALS = "plat";
export const DESSERTS = "dessert";

const initialState = {
  recipesToCookFetched: [],
  showResumeRecipe: false,
  recipeShown: null,
  isAllStartersSelected: false,
  isAllMealsSelected: false,
  isAllDessertsSelected: false,
  indexRecipeShown: null,
  imageRecipeShown: null,
  aRecipeToCookIsUpdated: false,
  type: {
    // title: "Type",
    name: "type",
    value: "plat",
    // logo: "icons8-tapas",
    data: [
      {
        name: "type",
        value: "entree",
        label: "Entrée",
        isDefault: false,
        logo: "icons8-greek-salad",
      },
      {
        name: "type",
        value: "plat",
        label: "Plat",
        isDefault: true,
        logo: "icons8-paella",
      },
      {
        name: "type",
        value: "dessert",
        label: "Dessert",
        isDefault: false,
        logo: "icons8-banana-split",
      },
    ],
  },
};

const recipesToCookSlice = createSlice({
  name: "recipesToCook",
  initialState,
  reducers: {
    setRecipesToCookFetched: (state, action) => {
      state.recipesToCookFetched = action.payload;
    },
    setRecipeLiked: (state, action) => {
      const recipeId = action.payload;
      state.recipesToCookFetched = state.recipesToCookFetched.map(recipe => {
        if (recipe._id === recipeId) {
          return {...recipe, isLiked: !recipe.isLiked};
        }
        return recipe;
      });
    },
    setShowResumeRecipe: (state, action) => {
      state.showResumeRecipe = action.payload;
    },
    setRecipeShown: (state, action) => {
      state.recipeShown = action.payload;
    },
    setIndexRecipeShown: (state, action) => {
      state.indexRecipeShown = action.payload;
    },
    setImageRecipeShown: (state, action) => {
      state.imageRecipeShown = action.payload;
    },
    setType: (state, action) => {
      state.type.value = action.payload;
    },
    setAllRecipesSelected: (state, action) => {
      state.recipesToCookFetched = state.recipesToCookFetched.map((recipe) => {
        if (recipe.type === action.payload) {
          return { ...recipe, selected: true };
        }
        return recipe;
      });
    },
    setAllRecipesDeselected: (state, action) => {
      state.recipesToCookFetched = state.recipesToCookFetched.map((recipe) => {
        if (recipe.type === action.payload) {
          return { ...recipe, selected: false };
        }
        return recipe;
      });
    },
    
    setIsAllStartersSelected: (state, action) => {
      state.isAllStartersSelected = action.payload;
    },
    
    setIsAllMealsSelected: (state, action) => {
      state.isAllMealsSelected = action.payload;
    },
    
    setIsAllDessertsSelected: (state, action) => {
      state.isAllDessertsSelected = action.payload;
    },
    setRecipeSelected: (state, action) => {
      const recipeId = action.payload;
      state.recipesToCookFetched = state.recipesToCookFetched.map(recipe => {
        if (recipe.idRecipeToCook === recipeId) {
          return {...recipe, selected: !recipe.selected};
        }
        return recipe;
      });
    },
    setARecipeToCookIsUpdated: (state, action) => {
      state.aRecipeToCookIsUpdated = action.payload;
    }
  },
});

export const {
  setRecipesToCookFetched,
  setRecipeLiked,
  setShowResumeRecipe,
  setRecipeShown,
  setIndexRecipeShown,
  setImageRecipeShown,
  setType,
  setAllRecipesSelected,
  setAllRecipesDeselected,
  setIsAllStartersSelected,
  setIsAllMealsSelected,
  setIsAllDessertsSelected,
  setRecipeSelected,
  setARecipeToCookIsUpdated
} = recipesToCookSlice.actions;

export default recipesToCookSlice.reducer;
