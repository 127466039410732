import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { get_own_recipes } from "../../functions/requests/axiosRequests";
import { setOwnRecipesFetched } from "../../redux/slices/ownRecipesSlice";
import useError from "../utils/useError";

function useOwnRecipesQuery() {
  const dispatch = useDispatch();
  const { setError } = useError();

  const getOwnRecipesRequest = useQuery("get_own_recipes", get_own_recipes, {
    staleTime: Infinity,
    cacheTime: Infinity,
    onSuccess: (data) => {
      dispatch(
        setOwnRecipesFetched([
          ...data.ownRecipes.map((recipe) => ({
            ...recipe,
            selected: false,
          })),
        ])
      );
    },
    onError: (error) => {
      setError(error.response?.data?.message || "Une erreur s'est produite");
    },
  });

  return getOwnRecipesRequest;
}

export default useOwnRecipesQuery;
