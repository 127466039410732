import { createSlice } from "@reduxjs/toolkit";

export const INFOS_STEP = 0;
export const INGREDIENTS_STEP = 1;
export const ETAPES_STEP = 2;

const initialState = {
  nameRecipe: {
    name: "name",
    title: "Nom de la recette",
    placeholder: "Nom entre 8 et 50 caractères...",
    value: "",
    logo: "icons8-pencil-drawing",
  },
  couvert: {
    name: "couvert",
    title: "Couverts",
    value: 1,
    unite: "",
    step: 1,
    min: 1,
    logo: "icons8-cutlery",
  },
  type: {
    title: "Type",
    name: "type",
    value: "plat",
    logo: "icons8-tapas",
    data: [
      {
        name: "type",
        value: "entree",
        label: "Entrée",
        isDefault: false,
        logo: "icons8-greek-salad",
      },
      {
        name: "type",
        value: "plat",
        label: "Plat",
        isDefault: true,
        logo: "icons8-paella",
      },
      {
        name: "type",
        value: "dessert",
        label: "Dessert",
        isDefault: false,
        logo: "icons8-banana-split",
      },
    ],
  },
  complexity: {
    title: "Compléxité",
    name: "complexity",
    value: "facile",
    logo: "icons8-ok-hand",
    data: [
      {
        name: "complexity",
        value: "facile",
        label: "Facile",
        isDefault: true,
        logo: "icons8-take-it-easy",
      },
      {
        name: "complexity",
        value: "moyen",
        label: "Moyen",
        isDefault: false,
        logo: "icons8-two-fingers",
      },
      {
        name: "complexity",
        value: "difficile",
        label: "Difficile",
        isDefault: false,
        logo: "icons8-mayura-gesture",
      },
    ],
  },
  regime: {
    title: "Régime",
    name: "regime",
    value: "omnivore",
    logo: "icons8-real-food-for-meals",
    data: [
      {
        name: "regime",
        value: "omnivore",
        label: "Omnivore",
        isDefault: true,
        logo: "icons8-thanksgiving",
      },
      {
        name: "regime",
        value: "vegetarien",
        label: "Végétarien",
        isDefault: false,
        logo: "icons8-vegetarian-food",
      },
      {
        name: "regime",
        value: "vegetalien",
        label: "Végétalien",
        isDefault: false,
        logo: "icons8-vegan-food",
      },
    ],
  },
  seasons: {
    title: "Saisons",
    name: "seasons",
    logo: "icons8-four-seasons",
    value: [],
    data: [
      {
        name: "printemps",
        label: "Printemps",
        defaultValue: "printemps",
        logo: "icons8-sakura",
      },
      {
        name: "ete",
        label: "Eté",
        defaultValue: "ete",
        logo: "icons8-sun",
      },
      {
        name: "automne",
        label: "Automne",
        defaultValue: "automne",
        logo: "icons8-autumn",
      },
      {
        name: "hiver",
        label: "Hiver",
        defaultValue: "hiver",
        logo: "icons8-snow",
      },
    ],
  },
  preparationTime: {
    name: "preparationTime",
    title: "Préparation",
    value: 5,
    unite: "min",
    step: 5,
    min: 5,
    logo: "icons8-sablier-yellow",
  },
  cuissonTime: {
    name: "cuissonTime",
    title: "Cuisson",
    value: 0,
    unite: "min",
    step: 5,
    min: 0,
    logo: "icons8-sablier-red",
  },
  refrigerationTime: {
    name: "refrigerationTime",
    title: "Réfrigération",
    value: 0,
    unite: "min",
    step: 5,
    min: 0,
    logo: "icons8-sablier-blue",
  },
  reposTime: {
    name: "reposTime",
    title: "Repos",
    value: 0,
    unite: "min",
    step: 5,
    min: 0,
    logo: "icons8-sablier-green",
  },
  totalTime: {
    name: "totalTime",
    title: "Temps total",
    value: 5,
    unite: "min",
    step: 5,
    min: 5,
    logo: "icons8-sand-timer",
  },
  ingredients: {
    title: "Liste des ingrédients",
    name: "ingredients",
    value: [],
  },
  etapes: {
    title: "Etapes de préparation",
    name: "etapes",
    value: [""],
  },
  imageRecipe: {
    titleInput: "Photo de la recette",
    name: "imageRecipe",
    dataUrl: null,
    imageFile: null,
    fileName: null,
    fileSizeError: null,
  },
  steps: {
    current: INFOS_STEP,
    list: [
      {
        name: "Infos",
        current: INFOS_STEP,
        isFilled: false,
        logo: "icons8-left-handed",
      },
      {
        name: "Ingrédients",
        current: INGREDIENTS_STEP,
        isFilled: false,
        logo: "icons8-ingredients-for-cooking",
      },
      {
        name: "Étapes",
        current: ETAPES_STEP,
        isFilled: false,
        logo: "icons8-ambition",
      },
    ],
  },
  id: null,
  imageRecipeNameToUpdate: null,
  displayResumeRecipe: false,
  displayAddSuccess: false,
  displayUpdateSuccess: false,
};

export const SLICE_EDIT_RECIPE = "editRecipe";
export const KEYS_EDIT_RECIPE = {
  NAME_RECIPE: "nameRecipe",
  COUVERT: "couvert",
  TYPE: "type",
  COMPLEXITY: "complexity",
  REGIME: "regime",
  SEASONS: "seasons",
  PREPARATION_TIME: "preparationTime",
  CUISSON_TIME: "cuissonTime",
  REFRIGERATION_TIME: "refrigerationTime",
  REPOS_TIME: "reposTime",
  TOTAL_TIME: "totalTime",
  INGREDIENTS: "ingredients",
  ETAPES: "etapes",
  IMAGE_RECIPE: "imageRecipe",
  STEPS: "steps",
  ID: "id",
  IMAGE_RECIPE_NAME_TO_UPDATE: "imageRecipeNameToUpdate",
  DISPLAY_RESUME_RECIPE: "displayResumeRecipe",
  DISPLAY_ADD_SUCCESS: "displayAddSuccess",
  DISPLAY_UPDATE_SUCCESS: "displayUpdateSuccess",
};

const editRecipeSlice = createSlice({
  name: SLICE_EDIT_RECIPE,
  initialState: initialState,
  reducers: {
    setNameRecipe: (state, action) => {
      state.nameRecipe.value = action.payload;
    },
    setCouvert: (state, action) => {
      state.couvert.value = action.payload;
    },
    setType: (state, action) => {
      state.type.value = action.payload;
    },
    setComplexity: (state, action) => {
      state.complexity.value = action.payload;
    },
    setRegime: (state, action) => {
      state.regime.value = action.payload;
    },
    setSeasons: (state, action) => {
      state.seasons.value = action.payload;
    },
    setPreparationTime: (state, action) => {
      state.preparationTime.value = action.payload;
    },
    setCuissonTime: (state, action) => {
      state.cuissonTime.value = action.payload;
    },
    setRefrigerationTime: (state, action) => {
      state.refrigerationTime.value = action.payload;
    },
    setReposTime: (state, action) => {
      state.reposTime.value = action.payload;
    },
    setTotalTime: (state, action) => {
      state.totalTime.value = action.payload;
    },
    setAddIngredient: (state, action) => {
      state.ingredients.value = [...state.ingredients.value, action.payload];
    },
    setRemoveIngredient: (state, action) => {
      state.ingredients.value = state.ingredients.value.filter(
        (ingredient) => ingredient.name !== action.payload.name
      );
    },
    setUpdateQtyIngredient: (state, action) => {
      const { index, newQuantity } = action.payload;
      state.ingredients.value[index].quantity = newQuantity;
    },
    setAddEtape: (state) => {
      state.etapes.value = [...state.etapes.value, ""];
    },
    setUpdateEtape: (state, action) => {
      const { index, newEtape } = action.payload;
      state.etapes.value[index] = newEtape;
    },
    setRemoveEtape: (state, action) => {
      if (state.etapes.value.length > 1) {
        state.etapes.value = state.etapes.value.filter(
          (etape, index) => index !== action.payload
        );
      }
    },
    setImageDataUrl: (state, action) => {
      state.imageRecipe.dataUrl = action.payload;
    },
    setImageFile: (state, action) => {
      state.imageRecipe.imageFile = action.payload;
    },
    setFileName: (state, action) => {
      state.imageRecipe.fileName = action.payload;
    },
    setFileSizeError: (state, action) => {
      state.imageRecipe.fileSizeError = action.payload;
    },
    setCurrentStep: (state, action) => {
      state.steps.current = action.payload;
    },
    setStepFilled: (state, action) => {
      const { stepIndex, isFilled } = action.payload;
      if (stepIndex >= 0 && stepIndex < state.steps.list.length) {
        state.steps.list[stepIndex].isFilled = isFilled;
      }
    },
    setStepsFilledToTrue: (state, action) => {
      // set true all steps for isFilled
      state.steps.list.forEach((step) => {
        step.isFilled = true;
      });
    },
    setRecipeToUpdate: (state, action) => {
      const recipe = action.payload;
      state.nameRecipe.value = recipe.name;
      state.couvert.value = recipe.couvert;
      state.type.value = recipe.type;
      state.complexity.value = recipe.complexity;
      state.regime.value = recipe.regime;
      state.seasons.value = recipe.seasons;
      state.preparationTime.value = recipe.preparationTime;
      state.cuissonTime.value = recipe.cuissonTime;
      state.refrigerationTime.value = recipe.refrigerationTime;
      state.reposTime.value = recipe.reposTime;
      state.totalTime.value = recipe.totalTime;
      state.ingredients.value = recipe.ingredients.map((ingredient) => {
        return {
          calorie: ingredient.food.calorie,
          group: ingredient.food.group,
          icon: ingredient.food.icon,
          name: ingredient.food.name,
          step: ingredient.food.step,
          unite: ingredient.food.unite,
          _id: ingredient.food._id,
          quantity: ingredient.quantity,
          min: 1,
        };
      });
      state.etapes.value = recipe.etapes;
      state.imageRecipe.dataUrl = recipe.imageRecipe.dataUrl;
      state.imageRecipe.imageFile = recipe.imageRecipe.imageFile;
      state.imageRecipe.fileName = recipe.imageRecipe;
      state.imageRecipeNameToUpdate = recipe.imageRecipe;
      state.steps.current = INFOS_STEP;
      state.id = recipe._id;
    },
    setDisplayResumeRecipe: (state, action) => {
      state.displayResumeRecipe = action.payload;
    },
    setDisplayAddSuccess: (state, action) => {
      state.displayAddSuccess = action.payload;
    },
    setDisplayUpdateSuccess: (state, action) => {
      state.displayUpdateSuccess = action.payload;
    },
    resetState: (state) => {
      Object.assign(state, initialState);
    },
  },
});

export const {
  setNameRecipe,
  setCouvert,
  setType,
  setComplexity,
  setRegime,
  setSeasons,
  setPreparationTime,
  setCuissonTime,
  setRefrigerationTime,
  setReposTime,
  setTotalTime,
  setAddIngredient,
  setRemoveIngredient,
  setUpdateQtyIngredient,
  setAddEtape,
  setUpdateEtape,
  setRemoveEtape,
  setImageDataUrl,
  setImageFile,
  setFileName,
  setFileSizeError,
  setCurrentStep,
  setStepFilled,
  setStepsFilledToTrue,
  setRecipeToUpdate,
  setDisplayResumeRecipe,
  setDisplayAddSuccess,
  setDisplayUpdateSuccess,
  resetState,
} = editRecipeSlice.actions;

export default editRecipeSlice.reducer;
