import React, { useEffect, useState } from "react";
import { SectionCol } from "../elements/sections/Sections";
import { H2, H3 } from "../elements/texts/Texts";
import { FormAuth } from "../elements/forms/Forms";

import {
  actualEmail,
  newEmail,
  confirmNewEmail,
} from "../../constants/authent";
import { TextInput } from "../elements/inputs/TextInputs";
import { isValidEmail } from "../../functions/utils/isValidEmail";
import { Span, Spans } from "../elements/spans/Spans";
import { useMutation } from "react-query";
import { change_email } from "../../functions/requests/axiosRequests";
import { setEmail } from "../../redux/slices/userDataSlice";
import ErrorCode from "../../constants/httpErrorCodes";
import { SubmitAndProfileButton } from "../elements/bouttons/ProfileButtons";
import { useDispatch } from "react-redux";

function ChangeEmail() {
  return (
    <SectionCol>
      <H2>Changer l'adresse email</H2>
      <FormChangeEmail />
    </SectionCol>
  );
}

function FormChangeEmail() {
  const dispatch = useDispatch();
  const [_actualEmail, setActualEmail] = useState(actualEmail.defaultValue);
  const [_newEmail, setNewEmail] = useState(newEmail.defaultValue);
  const [_confirmNewEmail, setConfirmNewEmail] = useState(
    confirmNewEmail.defaultValue
  );
  const [formOk, setFormOk] = useState(false);
  const [badEmailAccount, setBadEmailAccount] = useState(false);
  const [emailAlreadyExist, setEmailAlreadyExist] = useState(false);
  const [errorRequest, setErrorRequest] = useState("");

  const changeEmailRequest = useMutation(change_email, {
    onSuccess: () => {
      setBadEmailAccount(false);
      setEmailAlreadyExist(false);
      setErrorRequest("");
      dispatch(setEmail(_newEmail));
      setActualEmail("");
      setNewEmail("");
      setConfirmNewEmail("");
    },
    onError: (err) => {
      if (err.response.data.status) {
        if (err.response.data.message === ErrorCode.BAD_EMAIL) {
          setBadEmailAccount(true);
        }
        if (err.response.data.message === ErrorCode.NEW_EMAIL_ALREADY_EXISTS) {
          setBadEmailAccount(false);
          setEmailAlreadyExist(true);
        }
      } else {
        setErrorRequest(err.response.data.message);
      }
    },
  });

  function onSubmit() {
    changeEmailRequest.mutate({
      actualEmail: _actualEmail,
      newEmail: _newEmail,
    });
  }

  useEffect(() => {
    if (
      isValidEmail(_actualEmail) &&
      isValidEmail(_newEmail) &&
      _newEmail === _confirmNewEmail
    ) {
      setFormOk(true);
    } else {
      setFormOk(false);
    }
  }, [_actualEmail, _newEmail, _confirmNewEmail]);

  return (
    <FormAuth>
      <ActualEmailField value={_actualEmail} setter={setActualEmail} />
      <ErrorActualEmailField
        _actualEmail={_actualEmail}
        badEmailAccount={badEmailAccount}
      />

      <NewEmailField value={_newEmail} setter={setNewEmail} />
      <ErrorNewEmailField
        _newEmail={_newEmail}
        emailAlreadyExist={emailAlreadyExist}
      />

      <ConfirmNewEmailField
        value={_confirmNewEmail}
        setter={setConfirmNewEmail}
      />

      <Spans>
        <ErrorConfirmNewEmailField
          _newEmail={_newEmail}
          _confirmNewEmail={_confirmNewEmail}
        />
        <ServerResponseMessages
          mutation={changeEmailRequest}
          errorRequest={errorRequest}
          _actualEmailLength={_actualEmail.length}
        />
      </Spans>

      <SubmitAndProfileButton formOk={formOk} onSubmit={onSubmit} />
    </FormAuth>
  );
}

function ActualEmailField({ value, setter }) {
  return (
    <>
      <H3>Adresse email actuelle</H3>
      <TextInput item={actualEmail} value={value} onTextChange={setter} />
    </>
  );
}

function ErrorActualEmailField({ _actualEmail, badEmailAccount }) {
  return (
    <Spans>
      {!isValidEmail(_actualEmail) && _actualEmail.length > 0 && (
        <Span testid="span_actualEmail">Format de l'email incorrect</Span>
      )}
      {badEmailAccount && (
        <Span testid="span_actualEmail">L'email du compte est incorrect</Span>
      )}
    </Spans>
  );
}

function NewEmailField({ value, setter }) {
  return (
    <>
      <H3>Nouvelle adresse email</H3>
      <TextInput item={newEmail} value={value} onTextChange={setter} />
    </>
  );
}

function ErrorNewEmailField({ _newEmail, emailAlreadyExist }) {
  return (
    <Spans>
      {!isValidEmail(_newEmail) && _newEmail.length > 0 && (      
        <Span testid="span_newEmail">Format de l'email incorrect</Span>
      )}
      {emailAlreadyExist && (
        <Span testid="span_newEmail">L'email est déjà utilisé</Span>
      )}
    </Spans>
  );
}

function ConfirmNewEmailField({ value, setter }) {
  return (
    <>
      <H3>Confirmer la nouvelle adresse email</H3>
    <TextInput item={confirmNewEmail} value={value} onTextChange={setter} />
    </>
  );
}

function ErrorConfirmNewEmailField({ _newEmail, _confirmNewEmail }) {
  return (
    <Spans>
      {_newEmail !== _confirmNewEmail && _confirmNewEmail.length > 0 && (
        <Span add="text-center" testid="span_confirmNewEmail">
          Les emails ne sont pas identiques
        </Span>
      )}
    </Spans>
  );
}

function ServerResponseMessages({
  mutation,
  errorRequest,
  _actualEmailLength,
}) {
  return (
    <>
      {mutation.isLoading && <Span>En attente du serveur...</Span>}
      {mutation.isSuccess && _actualEmailLength === 0 && (
        <Span add="text-center">L'adresse email a bien été modifiée</Span>
      )}
      {errorRequest.length > 0 && <Span>{errorRequest}</Span>}
    </>
  );
}

export default ChangeEmail;
