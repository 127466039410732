import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { confirmAccount } from "../../functions/requests/axiosRequests";
import useError from "../utils/useError";
import { PROFILE } from "../../constants/routes";
import useAuth from "../utils/useAuth";

const useConfirmAccount = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { setError } = useError();
  const [errorInfo, setErrorInfo] = useState("");
  const {setUser} = useAuth()

  const postConfirmAccount = useQuery({
    queryKey: "confirmAccount",
    queryFn: () => confirmAccount(location),
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    onSuccess: (data) => {
      setUser({
        email: data.email,
        author: data.author,
        avatar: data.avatar,
      })
    },
    onError: (err) => {
      if (
        err.response.data.status
      ) {
        setErrorInfo(err.response.data.message);
      } else {
        setError(err.response.data.message);
      }
    },
  });

  useEffect(() => {
    if (postConfirmAccount.isSuccess) {
      setTimeout(() => {
        navigate(PROFILE.route);
      }, 3000);
    }
  }, [postConfirmAccount.isSuccess, navigate]);

  return { postConfirmAccount, errorInfo };
};

export default useConfirmAccount;
