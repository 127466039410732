import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import GlobalModal from "./GlobalModal";
import clsx from "clsx";
import { sH1, sH2 } from "../../../styles/styles";
import useError from "../../../hooks/utils/useError";
import { SLICE_ERRORS, ERROR_IS_DISPLAYED, ERROR_DESCRIPTION } from "../../../redux/slices/errorsSlice";
import DeadCat from "../../../assets/icons/deco_icons/icons8-dead-cat.svg";


const ErroRequestModal = () => {
  const {setError} = useError();
  const errorIsDisplayed = useSelector((state) => state[SLICE_ERRORS][ERROR_IS_DISPLAYED]);
  const errorDescription = useSelector((state) => state[SLICE_ERRORS][ERROR_DESCRIPTION]);
  

  useEffect(() => {
    if (errorIsDisplayed) {
      setTimeout(() => {
        setError("");
      }, 3000);
    }
  }, [errorIsDisplayed, setError]);

  //#region Styles
  const divDisplayErrorStyle = clsx(
    "absolute",
    "bg-red-700",
    "w-4/5 lg:w-2/5",
    "h-3/5 lg:h-3/5",
    "rounded-lg",
    "drop-shadow-3xl",
    "px-4",
    "lg:pb-0",
    "flex",
    "flex-col",
    "justify-around",
    "items-center",
  );

  const imgStyle = clsx(
    "pt-8",
    "w-1/3",
    "h-1/3",
    "object-contain",
    "object-center",
    "mx-auto"
  );

  //#endregion

  return (
    <>
      {errorIsDisplayed && (
        <GlobalModal>
          <div className={divDisplayErrorStyle}>
            <h1 className={sH1}>Erreur serveur</h1>
            <h2 className={clsx(sH2, "text-white")}>{errorDescription}</h2>
            <img src={DeadCat} className={imgStyle} alt="DeadCat with cross eyes" />
            <h2 className={clsx(sH2, "text-white")}>Réessayer !</h2>
          </div>
        </GlobalModal>
      )}
    </>
  );
};

export default ErroRequestModal;
