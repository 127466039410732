import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  newPwd: {
    title: "Nouveau mot de passe",
    name: "newPwd",
    placeholder: "",
    type: "password",
    value: "",
  },
  confirmNewPwd: {
    title: "Confirmer le mot de passe",
    name: "confirmNewPwd",
    placeholder: "",
    type: "password",
    value: "",
  },
  errorRequest: "",
};

export const SLICE_RESET_PWD_DATA = "resetPwdData";
export const KEYS_RESET_PWD_DATA = {
  NEW_PWD: "newPwd",
  CONFIRM_NEW_PWD: "confirmNewPwd",
  ERROR_REQUEST: "errorRequest",
};

const resetPwdDataSlice = createSlice({
  name: SLICE_RESET_PWD_DATA,
  initialState,
  reducers: {
    setNewPwd: (state, action) => {
      state.newPwd.value = action.payload;
    },
    setConfirmNewPwd: (state, action) => {
      state.confirmNewPwd.value = action.payload;
    },
    setErrorRequest: (state, action) => {
      state.errorRequest = action.payload;
    }
  },
});

export const { 
  setNewPwd,
  setConfirmNewPwd,
  setErrorRequest
 } = resetPwdDataSlice.actions;

export default resetPwdDataSlice.reducer;
