import React from "react";
import clsx from "clsx";
import useConfirmAccount from "../../hooks/routes/useConfirmAccount";
import { LoadingSection } from "../elements/loadings/Loadings";
import CatApprouve from "../../assets/images/cat_approuve.png";
import { sH1, sH2 } from "../../styles/styles";
import { REGISTER } from "../../constants/routes";
import { ButtonFill } from "../elements/bouttons/Buttons";
import DeadCat from "../../assets/icons/deco_icons/icons8-dead-cat.svg";

const ConfirmAccount = () => {
  const { postConfirmAccount, errorInfo } = useConfirmAccount();

  //#region Styles
  const sectionStyle = clsx(
    "flex",
    "flex-col",
    "items-center",
    "justify-center",
    "mt-20 mx-8",
  );

  const divImgSuccessStyle = clsx("rounded-full", "w-60 h-60");

  const imgSuccessStyle = clsx("rounded-full");

  const imgErrorStyle = clsx(
    "pt-8",
    "w-32",
    "h-32",
    "object-contain",
    "object-center"
    );
  //#endregion

  //#region RENDERS

  //#region Loading
  if (postConfirmAccount.isLoading) {
    return <LoadingSection textToShow="Validation du compte" />;
  }
  //#endregion

  //#region Success
  if (postConfirmAccount.isSuccess) {
    return (
      <section className={sectionStyle}>
        <h1 className={sH1}>Mealster</h1>
        <h1 className={clsx(sH1, "pt-8")}>Compte validé</h1>

        <div className={divImgSuccessStyle}>
          <img
            className={imgSuccessStyle}
            src={CatApprouve}
            alt="Cat approuve"
          />
        </div>
        <h2 className={clsx(sH2, "mt-6")}>Redirection sur l'application !</h2>
      </section>
    );
  }
  //#endregion

  //#region Error
  if (postConfirmAccount.isError) {
    if (errorInfo) {
      return (
        <section className={sectionStyle}>
          <h1 className={sH1}>La confirmation du compte a échoué</h1>
          <img src={DeadCat} className={imgErrorStyle} alt="DeadCat with cross eyes" />
          <h2 className={clsx(sH2, "pt-8 pb-8")}>{errorInfo}</h2>
          <ButtonFill nav={REGISTER.route} testid="back_inscription">
            {REGISTER.name}
          </ButtonFill>
        </section>
      );
    } else {
      // Faire une redirection vers la page welcome au bout de 3 secondes
      return (
        <section className={sectionStyle}>
          <h1 className={sH1}>Erreur survenue</h1>
          <h2 className={clsx(sH2, "pt-8")}>Recharger la page...</h2>
        </section>
      );
    }
  }
  //#endregion
  //#endregion
};

export default ConfirmAccount;
