import React from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { Spans, Span } from "../spans/Spans";
import {
  setPwdAuth,
  setCpwdAuth,
  KEYS_LOGIN_DATA,
  SLICE_LOGIN_DATA,
} from "../../../redux/slices/loginDataSlice";
import { LOGIN, REGISTER } from "../../../constants/routes";
import {
  KEYS_RESET_PWD_DATA,
  SLICE_RESET_PWD_DATA,
  setNewPwd,
  setConfirmNewPwd,
} from "../../../redux/slices/resetPwdSlice";
import FieldText from "./FieldText";

export const AuthPwdFieldAndError = React.memo(function AuthPwdFieldAndError() {
  const { pwd, invalidLogin } = useSelector((state) => ({
    pwd: state[SLICE_LOGIN_DATA][KEYS_LOGIN_DATA.PWD],
    invalidLogin: state[SLICE_LOGIN_DATA][KEYS_LOGIN_DATA.INVALID_LOGIN],
  }));

  return (
    <>
      <AuthPwdField pwd={pwd} />
      <Spans>
        <AuthPwdFieldError pwd={pwd} invalidLogin={invalidLogin} />
      </Spans>
    </>
  );
});

export function AuthPwdField({ pwd }) {
  const fieldIsOk = pwd.value.length >= 6;

  return (
    <>
      <FieldText state={pwd} action={setPwdAuth} fieldIsOk={fieldIsOk} />
    </>
  );
}

export function AuthPwdFieldError({ pwd, invalidLogin }) {
  const pathname = useLocation().pathname;

  return (
    <>
      {pathname === REGISTER.route &&
        pwd.value.length > 0 &&
        pwd.value.length < 6 && (
          <Span>Le mot de passe doit contenir au moins 6 caractères</Span>
        )}
      {invalidLogin && pathname === LOGIN.route && (
        <Span>Identifiants incorrects, veuillez réessayer</Span>
      )}
    </>
  );
}

export const AuthCpwdFieldAndError = React.memo(
  function AuthCpwdFieldAndError() {
    const pwds = useSelector((state) => ({
      pwd: state[SLICE_LOGIN_DATA][KEYS_LOGIN_DATA.PWD],
      cpwd: state[SLICE_LOGIN_DATA][KEYS_LOGIN_DATA.CONFIRM_PWD],
    }));

    return (
      <>
        <AuthCpwdField pwds={pwds} />
        <Spans>
          <AuthCpwdFieldError pwds={pwds} />
        </Spans>
      </>
    );
  }
);

export function AuthCpwdField({ pwds }) {
  const fieldIsOk = pwds.cpwd.value === pwds.pwd.value && pwds.cpwd.value.length > 0;
  return (
    <>
      <FieldText
        state={pwds.cpwd}
        action={setCpwdAuth}
        fieldIsOk={fieldIsOk}
      />
    </>
  );
}

export function AuthCpwdFieldError({ pwds }) {
  return (
    <>
      {pwds.cpwd.value !== pwds.pwd.value && (
        <Span>Les mots de passe ne sont pas identiques</Span>
      )}
    </>
  );
}

export function ResetPwdFieldAndError() {
  const { newPwd } = useSelector((state) => ({
    newPwd: state[SLICE_RESET_PWD_DATA][KEYS_RESET_PWD_DATA.NEW_PWD],
  }));

  return (
    <>
      <ResetPwdField newPwd={newPwd} />
      <Spans>
        <ResetPwdFieldError newPwd={newPwd} />
      </Spans>
    </>
  );
}

export function ResetPwdField({ newPwd }) {
  const fieldIsOk = newPwd.value.length >= 6;
  return (
    <>
      <FieldText state={newPwd} action={setNewPwd} fieldIsOk={fieldIsOk} />
    </>
  );
}

export function ResetPwdFieldError({ newPwd }) {
  return (
    <>
      {newPwd.value.length > 0 && newPwd.value.length < 6 && (
        <Span>Le mot de passe doit contenir au moins 6 caractères</Span>
      )}
    </>
  );
}

export const ResetCpwdFieldAndError = React.memo(
  function ResetCpwdFieldAndError() {
    const pwds = useSelector((state) => ({
      newPwd: state[SLICE_RESET_PWD_DATA][KEYS_RESET_PWD_DATA.NEW_PWD],
      confirmNewPwd:
        state[SLICE_RESET_PWD_DATA][KEYS_RESET_PWD_DATA.CONFIRM_NEW_PWD],
    }));

    return (
      <>
        <ResetCpwdField pwds={pwds} />
        <Spans>
          <ResetCpwdFieldError pwds={pwds}/>
        </Spans>
      </>
    );
  }
);

export function ResetCpwdField({ pwds }) {
  const fieldIsOk = pwds.confirmNewPwd.value === pwds.newPwd.value &&  pwds.confirmNewPwd.value.length > 0;
  return (
    <>
      <FieldText
        state={pwds.confirmNewPwd}
        action={setConfirmNewPwd}
        fieldIsOk={fieldIsOk}
      />
    </>
  );
}

export function ResetCpwdFieldError({ pwds }) {
  return (
    <>
      {pwds.confirmNewPwd.value !== pwds.newPwd.value && (
        <Span>Les mots de passe ne sont pas identiques</Span>
      )}
    </>
  );
}
