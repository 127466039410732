import React, { useRef, useState } from "react";
import clsx from "clsx";

const BouttonPressed = ({srcImg, handleClick, testid=""}) => {
  const [isClicked, setIsClicked] = useState(false);

  const hasReleasedRef = useRef(false);

  const onRelease = () => {
    if (!hasReleasedRef.current) {
      setIsClicked(false);
      handleClick();
      hasReleasedRef.current = true;
    }
  };

  const divContainerStyle = clsx(
    "relative",
    "h-8 sm:h-8 lg:h-10",
    "w-8 sm:w-8 lg:w-10",
    "rounded",
    "border-2 border-sky-500",
  );
  return (
      <div className={divContainerStyle} testid={testid}>
        <div className="absolute h-full w-full rounded top-0 left-0 bg-black opacity-50"></div>
        <button
          className={clsx(
            "absolute rounded top-0 left-0 h-full w-full",
            "-translate-y-2",
            "text-white bg-sky-500",
            "text-center",
            isClicked ? "animate-translateBottom" : ""
          )}
          onMouseDown={() => {
            setIsClicked(true);
            hasReleasedRef.current = false;
          }}
          onMouseUp={onRelease}
          onMouseLeave={() => {
            if (isClicked) {
              onRelease();
            }
          }}
        >
          <img src={srcImg} alt="variation logo"/>
        </button>
      </div>
  );
};

export default React.memo(BouttonPressed);
