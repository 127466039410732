import React from "react";
import { Outlet } from "react-router-dom";
import useRequireAuth from "../../hooks/routes/useRequireAuth";
import Header from "../elements/headers/Headers";
import LoadingModal from '../elements/modals/LoadingModal'
import useFood from "../../hooks/utils/useFood";
import { DivCol } from "../elements/divs/Divs";
import { ButtonFill } from "../elements/bouttons/Buttons";
import { H1, H2, P } from "../elements/texts/Texts";

const RequireAuth = () => {
  const { email, isSuccess, isLoading } = useRequireAuth();
  const {
    isLoadingFoodVersion,
    isLoadingFoodList,
    foodOk
  } = useFood();

  if (isLoading || isLoadingFoodVersion || isLoadingFoodList) {
    return (
    <>
    {isLoadingFoodVersion && <LoadingModal title="Contrôle de la fraîcheur des aliments"/>}
    {isLoadingFoodList && <LoadingModal title="Achats des aliments" />}
    </>
    )
  }



  if (email || (isSuccess && foodOk)) {
  return (
    <>
      <Header />
      <div className="w-full pt-20">
        <Outlet />
      </div>
    </>
  );
  }
  else {
    <DivCol>
      <H1>Mealster</H1>
      <H2>Erreur de chargement</H2>
      <P>Une erreur est survenue lors du chargement de la page.</P>
      <ButtonFill>Se reconnecter</ButtonFill>
    </DivCol>
  }
};

export default RequireAuth;
