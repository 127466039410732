import React from "react";
import { UlCol } from "../../elements/lists/Lists";
import { FoodIcon } from "../../elements/images/IconImage";
import { DivRow } from "../../elements/divs/Divs";
import { H2, P } from "../../elements/texts/Texts";
import clsx from "clsx";

function Ingredients({ value, baseCouvert, couvertWanted, add }) {
  const divIngredientsStyle = clsx(
    add ? add : "",
  )
  return (
    <>
      <H2 size={"text-2xl"}>Ingrédients</H2>
      <UlCol ju={"justify-start"} it={"items-start"} add={divIngredientsStyle}>
        {value.map((ingredient, index) => {
          let quantityToDisplay =
            couvertWanted !== null
              ? Math.ceil((ingredient.quantity * couvertWanted) / baseCouvert)
              : ingredient.quantity;

          let uniteToDisplay =
            (ingredient?.food?.unite || ingredient.unite) === "pièce" &&
            quantityToDisplay > 1
              ? "pièces"
              : ingredient?.food?.unite || ingredient.unite;

          return (
            <li key={index}>
              <DivRow>
                <FoodIcon
                  iconName={ingredient?.food?.icon || ingredient.icon}
                  altName={ingredient?.food?.name || ingredient.name}
                  size={"h-10 w-10"}
                />
                <P testid={ingredient?.food?.name || ingredient.name}>
                  {ingredient?.food?.name || ingredient.name} :{" "}
                  {quantityToDisplay}{" "}
                  {uniteToDisplay}
                </P>
              </DivRow>
            </li>
          );
        })}
      </UlCol>
    </>
  );
}

export default Ingredients;
