import React from "react";
import { useDispatch } from "react-redux";
import { LiIngredient, UlCol } from "../../elements/lists/Lists";
import { setRemoveIngredient, setUpdateQtyIngredient } from "../../../redux/slices/editRecipeSlice";
import clsx from "clsx";
import { Span } from "../../elements/spans/Spans";

function GroupOfSelectedIngredients({ ingredientsList }) {
  return (
    <>
      {ingredientsList.length > 0 && (
        <UlCol
          add={"w-full mt-2 pb-3 pr-2 rotate-180"}
          ju={"justify-end"}
          it={"items-end"}
          testid={"ingredientGroup"}
        >
          {ingredientsList.map((ingredient, index) => {
            return (
              <SelectedIngredient
                key={ingredient.name}
                ingredient={ingredient}
                index={index}
              />
            );
          })}
        </UlCol>
      )}
      {ingredientsList.length === 0 && <Span add={"pt-4"} testid={"span_two_ing_required"}>Deux ingrédients sont requis</Span>}
      {ingredientsList.length === 1 && <Span add={"pt-4"} testid={"span_one_ing_required"}>Encore un ingrédient requis</Span>}
    </>
  );
}

function SelectedIngredient({ ingredient, index }) {
  const liStyle = clsx(
    "-rotate-180 w-full py-2 pl-2",
    index % 2 === 0 ? "bg-sky-50" : "bg-sky-100"
  );

  const dispatch = useDispatch();

  function onRemoveIngredient(ingredientToRemove) {
    dispatch(setRemoveIngredient(ingredientToRemove));
  }

  function onIngredientQtyChange(index, newQuantity) {
    dispatch(setUpdateQtyIngredient({ index: index, newQuantity: newQuantity }))
  }

  return (
    <LiIngredient
      add={liStyle}
      foodObject={ingredient}
      onRemoveIngredient={onRemoveIngredient}
      onValueChange={onIngredientQtyChange}
      index={index}
      testid={`ingredient_${ingredient.name}`}
      />
    )}


export default GroupOfSelectedIngredients;
