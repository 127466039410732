import { DivRow } from "../../elements/divs/Divs";
import { DecoIcon } from "../../elements/images/IconImage";
import { H3 } from "../../elements/texts/Texts";

export function CouvertRecipe({ value, couvertWanted }) {
    const couvert = {
    title: "Couverts",
    logo: "icons8-cutlery",
    value: value,
  }

  return (
    <FeatureRecipe
      logo={couvert.logo}
      title={couvert.title}
      value={couvertWanted || couvert.value}
      testid={"couvert_recipe"}
    />
  );
}

export function TypeRecipe({ value }) {
    const type = {
    title: "Type",
    name: "type",
    value: value,
    logo: "icons8-tapas",
    data: [
      {
        name: "type",
        value: "entree",
        label: "Entrée",
        isDefault: false,
        logo: "icons8-greek-salad",
      },
      {
        name: "type",
        value: "plat",
        label: "Plat",
        isDefault: true,
        logo: "icons8-paella",
      },
      {
        name: "type",
        value: "dessert",
        label: "Dessert",
        isDefault: false,
        logo: "icons8-banana-split",
      },
    ],
  }

  return (
    <FeatureRadioRecipe feature={type} testid={"type_recipe"}/>
  );
}

export function ComplexityRecipe({ value }) {
  const complexity = {
    title: "Compléxité",
    name: "complexity",
    value: value,
    logo: "icons8-ok-hand",
    data: [
      {
        name: "complexity",
        value: "facile",
        label: "Facile",
        isDefault: true,
        logo: "icons8-take-it-easy",
      },
      {
        name: "complexity",
        value: "moyen",
        label: "Moyen",
        isDefault: false,
        logo: "icons8-two-fingers",
      },
      {
        name: "complexity",
        value: "difficile",
        label: "Difficile",
        isDefault: false,
        logo: "icons8-mayura-gesture",
      },
    ],
  }

  return (
    <FeatureRadioRecipe feature={complexity} testid={"complexity_recipe"}/>
  );
}

export function RegimeRecipe({ value }) {
  const regime = {
    title: "Régime",
    name: "regime",
    value: value,
    logo: "icons8-real-food-for-meals"
      ,
    data: [
      {
        name: "regime",
        value: "omnivore",
        label: "Omnivore",
        isDefault: true,
        logo: "icons8-thanksgiving",
      },
      {
        name: "regime",
        value: "vegetarien",
        label: "Végétarien",
        isDefault: false,
        logo: "icons8-vegetarian-food",
      },
      {
        name: "regime",
        value: "vegetalien",
        label: "Végétalien",
        isDefault: false,
        logo: "icons8-vegan-food",
      },
    ],
  }
  return (
    <FeatureRadioRecipe feature={regime} testid={"regime_recipe"}/>
  );
}

function FeatureRadioRecipe({ feature, testid }) {
  const logoToShow = findLogo(feature);
  const valueToShow = findTextToShowFromValue(feature);

  return (
    <FeatureRecipe
      logo={logoToShow}
      title={feature.title}
      value={valueToShow}
      testid={testid}
    />
  );
}

function findLogo(object) {
  const valueForLogoToFound = object.value;

  for (let i = 0; i < object.data.length; i++) {
    if (object.data[i].value === valueForLogoToFound) {
      return object.data[i].logo;
    }
  }
  return "";
}

function findTextToShowFromValue(object) {
  const valueForTextToFound = object.value;

  for (let i = 0; i < object.data.length; i++) {
    if (object.data[i].value === valueForTextToFound) {
      return object.data[i].label;
    }
  }
  return "";
}

function FeatureRecipe({ logo, title, value, testid }) {
  return (
    <DivRow>
      <DecoIcon iconName={logo} altName={title} size={"h-8 w-8"} />
      <H3 testid={testid}>{value}</H3>
    </DivRow>
  );
}