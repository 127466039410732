import {useState} from "react";
import useLocalStorage from "./useLocalStorage";
import { useQuery } from "react-query";
import {
  food_version,
  food_list,
} from "../../functions/requests/axiosRequests";
import useError from "./useError";

const useFood = () => {
  const [foodVersion, setFoodVersion] = useLocalStorage("foodVersion", "0");
  const [foodList, setFoodList] = useLocalStorage("foodList", []);
  const [FetchFoods, setFetchFoods] = useState(false);
  const [foodOk, setFoodOk] = useState(false);
  const {setError} = useError();

  const {
    isSuccess: isSuccessFoodVersion,
    isLoading: isLoadingFoodVersion,
    isError: isErrorFoodVersion,
  } = useQuery("foodVersion", food_version, 
  { 
    retry: 1,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    onSuccess: (data) => {
      if(data.version !== foodVersion) {
        setFoodVersion(data.version);
        setFetchFoods(true)
      }
      else if (data.version === foodVersion && foodList.length === 0) {
        setFetchFoods(true)
      }
      else {
        setFoodOk(true)
      }
    },
    onError: (error) => {
      setError(error);
    }
  });

  const {
    isSuccess: isSuccessFoodList,
    isLoading: isLoadingFoodList,
    isError: isErrorFoodList,
  } = useQuery("foodList", food_list,
  {
    retry: 1,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    enabled: !!FetchFoods,
    onSuccess: (data) => {
      setFoodList(data.foodsList);
      setFoodOk(true)
    },
    onError: (error) => {
      setError(error);
    }
  }
  );

  return {
    isSuccessFoodVersion,
    isLoadingFoodVersion,
    isErrorFoodVersion,
    isSuccessFoodList,
    isLoadingFoodList,
    isErrorFoodList,
    foodOk
  };
};

export default useFood;
