import React, { useCallback } from "react";
import clsx from "clsx";
import { useDispatch } from "react-redux";

export const TextInput = React.memo(
  ({
    item,
    value,
    onTextChange,
    icon,
    isDisable = false,
    isAutoComplete = false,
  }) => {
    //#region functions
    const handleChange = useCallback(
      (event) => {
        onTextChange(event.target.value);
      },
      [onTextChange]
    );
    //#endregion

    //#region styles
    const inputText = clsx(
      "w-full",
      "rounded-lg border-gray-200",
      isDisable ? "bg-gray-200 opacity-50" : "bg-white",
      "p-4 pr-12",
      "text-sm shadow-sm",
      "border-sky-900 border-2",
      "font-spin"
    );

    const inputIcon = clsx(
      "absolute",
      "inset-y-0 right-4",
      "inline-flex items-center"
    );
    //#endregion

    return (
      <div className="w-full relative z-10">
        <input
          className={inputText}
          type={item.type}
          name={item.name}
          value={value}
          onChange={handleChange}
          disabled={isDisable}
          autoComplete={isAutoComplete ? "on" : "off"}
          placeholder={item.placeholder}
          testid={item.name}
          maxLength="50"
        />
        {icon && <span className={inputIcon}>{icon}</span>}
      </div>
    );
  }
);

export function TextInputStore({ state, action }) {
  const dispatch = useDispatch();

  const onChange = useCallback(
    (value) => {
      dispatch(action(value));
    },
    [dispatch, action]
  );

  return <TextInput item={state} value={state.value} onTextChange={onChange} />;
}

export const ReducerTextAreaInput = React.memo(
  ({ index, step, onTextAreaChange, testid }) => {
    const handleChange = useCallback(
      (event) => {
        onTextAreaChange(index, event.target.value);
      },
      [index, onTextAreaChange]
    );

    const textAreaStyle = clsx(
      "w-full",
      "block",
      "rounded-lg border-gray-200",
      "bg-white",
      "p-4 pr-12",
      "text-sm shadow-sm",
      "border-sky-900 border-2",
      "focus:ring-blue-500 focus:border-blue-500",
      "font-spin"
    );

    return (
      <textarea
        className={textAreaStyle}
        rows="4"
        name={`text_step_${index}`}
        placeholder="Entrer un texte d'au moins 10 caratères..."
        value={step}
        onChange={handleChange}
        testid={testid}
      ></textarea>
    );
  }
);
