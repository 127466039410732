import React from "react";
import clsx from "clsx";
import DotsImgAnimation from "../animations/DotsImgAnimation";
import { sH1 } from "../../../styles/styles";
import { H1, H2, H3 } from "../texts/Texts";
import { DivCol } from "../divs/Divs";

export const LoadingSection = ({ textToShow }) => {
  //#region Styles
  const sectionStyle = clsx(
    "flex",
    "flex-col",
    "items-center",
    "justify-center",
    "mt-20 mx-8"
  );

  const h2Style = clsx("pt-8", "pb-8", "animate-bounce", "text-center");
  //#endregion

  return (
    <section className={sectionStyle}>
      <H1 className={sH1}>Mealster</H1>
      {textToShow && <H1 add="pt-8 text-center">{textToShow}</H1>}
      <H2 add={h2Style}>En attente de la réponse du serveur</H2>

      <DotsImgAnimation />
    </section>
  );
};

export function LoadingDiv({text=null}) {
  const h3Style = clsx("pt-8", "pb-8", "animate-bounce", "text-center");

  return (
    <DivCol add={"w-full"}>
      <H3 add={h3Style}>{text ? text : "En attente de la réponse du serveur"}</H3>
      <DotsImgAnimation />
    </DivCol>
  )
}