import useError from '../utils/useError'
import { useDispatch } from 'react-redux'
import { setTempRecipesFetched } from '../../redux/slices/tempRecipesSlice'
import { useMutation } from 'react-query'
import { get_temp_recipes } from '../../functions/requests/axiosRequests'

function useGetTempRecipesMutation() {
  const dispatch = useDispatch()
  const { setError } = useError()
  
  const getTempRecipesRequest = useMutation("get_temp_recipes", get_temp_recipes, {
    staleTime: Infinity,
    cacheTime: Infinity,
    onSuccess: (data) => {
      dispatch(
        setTempRecipesFetched([
          ...data.tempRecipes.map((recipe) => ({
            ...recipe,
            selected: false,
          })),
        ])
      );
    },
    onError: (error) => {
      setError(error.response?.data?.message || "Une erreur s'est produite");
    },
  });

  return getTempRecipesRequest
}

export default useGetTempRecipesMutation