import React from "react";
import { DecoIcon } from "../images/IconImage";

function ButtonCloseModal({ closeModal }) {
  return (
    <button
      className="absolute top-0 right-0 z-10"
      testid="close_button_modal"
      onClick={closeModal}
    >
      <DecoIcon
        iconName={"icons8-fermer-la-fenetre"}
        altName={"bouton_fermer_modal"}
        size={"h-12 w-12"}
      />
    </button>
  );
}

export default ButtonCloseModal;
