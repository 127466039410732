import { useEffect } from "react";
import { useQuery } from "react-query";
// import useAuth from "../utils/useAuth";
import useLocalStorage from "../utils/useLocalStorage";
import { loginByRefreshToken } from "../../functions/requests/axiosRequests";
import { useNavigate } from "react-router-dom";
import { LOGIN } from "../../constants/routes";
import { useDispatch, useSelector } from "react-redux";
import { SLICE_USER_DATA, KEYS_USER_DATA, setEmail, setAuthor, setAvatar, setIsLogged } from "../../redux/slices/userDataSlice";

const useRequireAuth = () => {
  const dispatch = useDispatch();
  // const { email, setUser } = useAuth();
  const { email } = useSelector(
    (state) => ({
      email: state[SLICE_USER_DATA][KEYS_USER_DATA.EMAIL]
    })
  );
  const navigate = useNavigate();
  const [persist] = useLocalStorage("persist", false);

  const isLoginByToken = !email && persist;

  useEffect(() => {
    if (!email && !persist) {
      navigate(LOGIN.route);
    }
  }, [email, persist, navigate]);

  const { isSuccess, isLoading } = useQuery(
    "loginByRefreshToken",
    loginByRefreshToken,
    {
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: 3000, // 5 minutes
      enabled: !!isLoginByToken,
      onSuccess: (data) => {
        dispatch(setEmail(data.email));
        dispatch(setAuthor(data.author));
        dispatch(setAvatar(data.avatar));
        dispatch(setIsLogged(true));
      },
      onError: (error) => {
        dispatch(setIsLogged(false));
        navigate(LOGIN.route);
      },
    }
  );

  return { email, isSuccess, isLoading };
};

export default useRequireAuth;
