import { useDispatch } from "react-redux";
import { Li } from "./Lists";
import clsx from "clsx";
import { setExcludeIngredient, setRemoveIngredient } from "../../../redux/slices/filtersRecipesSlice";
import { DecoIcon, FoodIcon } from "../images/IconImage";
import { H3 } from "../texts/Texts";
import { CheckBoxInput } from "../inputs/CheckBoxInputs";


function ListIngredients({ ingredients }) {
const ulContainerStyle = clsx(
    "flex flex-wrap justify-center items-center",
    "mt-2 pb-3 pr-2 rotate-180",
    "w-full",
)

  return (
    <>
      {ingredients.length > 0 && (
        // <UlCol
        //   add={"w-full mt-2 pb-3 pr-2 rotate-180 overflow-y-scroll"}
        //   ju={"justify-end"}
        //   it={"items-end"}
        //   testid={"ingredientGroupFilterRecipes"}
        // >
          <ul className={ulContainerStyle}>

          {ingredients.map((ingredient, index) => {
            return (
              <SelectedIngredient
              key={ingredient.name}
              ingredient={ingredient}
              index={index}
              />
              );
            })}
            </ul>
        // </UlCol>
      )}
    </>
  );
}

function SelectedIngredient({ ingredient, index }) {
  const dispatch = useDispatch();

  const liStyle = clsx(
    "-rotate-180 w-full py-2",
    index % 2 === 0 ? "bg-sky-50" : "bg-sky-100"
  );

  const isExludeData = {
    title: "Exclure",
    name: `isExclude_${ingredient.name}`,
    type: "checkbox",
    logo: require("../../../assets/icons/deco_icons/icons8-check-icon.svg")
      .default,
  };

  function onRemoveIngredient(ingredientToRemove) {
    dispatch(setRemoveIngredient(ingredientToRemove));
  }

  function onExcludeIngredient(isExclude) {
    dispatch(setExcludeIngredient({ index: index, isExclude: isExclude }));
  }

  return (
    <Li add={liStyle} testid={`ingredient_${ingredient.name}`}>
      <div className="flex flex-row w-full justify-center items-center">
        <div className="flex flex-col justify-start items-start w-11/12"
        >
        <div className="flex flex-row justify-start items-start w-11/12 pl-2">
              <FoodIcon
                iconName={ingredient.icon}
                altName={ingredient.name}
                size={"h-8 w-8"}
              />
              <H3 add={"line-clamp-1"}>{ingredient.name}</H3>
        </div>
        <div className="flex flex-row justify-start items-start w-11/12 pl-12">
          <CheckBoxInput
          item={isExludeData}
          value={ingredient.isExclude}
          setter={onExcludeIngredient}
          />
          </div>
        </div>
        <button
          onClick={() => onRemoveIngredient(ingredient)}
          className={clsx(
            // "sm:mr-4",
            // "absolute",
            // "right-0",
            // "top-1/2",
            // "transform",
            // "-translate-y-1/2"
          )}
          testid={`delete_${ingredient.name}`}
        >
          <DecoIcon
            size={"h-8 w-8"}
            iconName={"icons8-remove"}
            altName={ingredient.name}
          />
        </button>
      </div>
    </Li>
  );
}

export default ListIngredients;