import React from "react";
import clsx from "clsx";
import TimeCardRecipe from "./TimeCardRecipe";
import { DecoIcon } from "../../images/IconImage";
import { H3 } from "../../texts/Texts";
import SelectionCardRecipe from "./SelectionCardRecipe";

function TimeTypeSelectionCardRecipe({ recipe, changeRecipeSelectionStatus, isMobile, selectionDisabled }) {
  const divStyle = clsx(
    "flex flex-col md:flex-row justify-between items-center",
    "w-full",
    "h-20 md:h-40",
    "basis-2/5 md:basis-full",
    "order-first md:order-[2]",

  );
  return (
    <div className={divStyle}>
      {!isMobile && <TimeCardRecipe totalTime={recipe.totalTime} />}
      <TypeInfosCardRecipe
        regime={recipe.regime}
        type={recipe.type}
        complexity={recipe.complexity}
      />
      {
        (!isMobile && !selectionDisabled) && 
        <SelectionCardRecipe
        idRecipeToCook={recipe.idRecipeToCook}
        id={recipe._id}
        selected={recipe.selected}
        changeRecipeSelectionStatus={changeRecipeSelectionStatus}
        />
      }
      {selectionDisabled && 
      <div className="hidden md:block md:h-12 md:w-12"></div>}
    </div>
  );
}

function TypeInfosCardRecipe({ regime, type, complexity }) {
  const divContainerStyle = clsx(
    "w-full md:w-28", 
    "h-full md:h-10", 
    "pt-2",
    "relative"
    );
  const divBlurStyle = clsx(
    "blur-sm",
    "bg-white",
    "w-full",
    "h-full",
    "absolute top-0 left-0"
  );

  const divStyle = clsx(
    "flex flex-col md:flex-row justify-around items-center",
    "w-full h-full",
    "absolute top-0 left-0",
    "py-2 px-0 md:px-1 md:py-0"
  );
  return (
    <div className={divContainerStyle}>
      <div className={divBlurStyle}></div>
      <div className={divStyle}>
        <RegimeCardRecipe regime={regime} />
        <TypeCardRecipe type={type} />
        <ComplexityCardRecipe complexity={complexity} />
      </div>
    </div>
  );
}

function RegimeCardRecipe({ regime }) {
  const regimesList = [
    { name: "omnivore", logo: "icons8-thanksgiving" },
    { name: "vegetarien", logo: "icons8-vegetarian-food" },
    { name: "vegetalien", logo: "icons8-vegan-food" },
  ];

  return <IconCardRecipe attribute={regime} list={regimesList} />;
}

function ComplexityCardRecipe({ complexity }) {
  const complexitiesList = [
    { name: "facile", logo: "icons8-take-it-easy" },
    { name: "moyen", logo: "icons8-two-fingers" },
    { name: "difficile", logo: "icons8-mayura-gesture" },
  ];

  return <IconCardRecipe attribute={complexity} list={complexitiesList} />;
}

function IconCardRecipe({ attribute, list }) {
  const iconToDisplay = list.find((item) => attribute === item.name).logo;

  return (
    <DecoIcon
      size={"h-6 w-6"}
      iconName={iconToDisplay}
      altName={`logo_${attribute}`}
    />
  );
}

function TypeCardRecipe({ type }) {
  const typesList = [
    { name: "entree", value: "Entrée" },
    { name: "plat", value: "Plat" },
    { name: "dessert", value: "Dessert" },
  ];
  const typeToDisplay = typesList.find((item) => type === item.name).value;

  return <H3 size={"text-sm md:text-base"}>{typeToDisplay}</H3>;
}

export default TimeTypeSelectionCardRecipe;
