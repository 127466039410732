import { createSlice } from "@reduxjs/toolkit";
import { OFFSET_LIST_RECIPE_RESET } from "../../constants/recipe";
export const SLICE_FILTERS_RECIPES = "filtersRecipes";
export const KEYS_FILTERS_RECIPES = {
  RECIPE_NAME_TO_SEARCH: "recipeNameToSearch",
  COUVERT: "couvert",
  REPEAT: "repeat",
  TYPE: "type",
  REGIME: "regime",
  COMPLEXITY: "complexity",
  SEASONS: "seasons",
  TOTAL_TIME: "totalTime",
  OFFSET_TO_FETCH_RECIPES_FOR_LIST: "offsetToFetchRecipesForList",
  TYPE_OF_FETCH: "typeOfFetch",
  NUMBER_OF_RECIPE_TO_FETCH_IN_RANDOM: "numberOfRecipeToFetchInRandom",
  INGREDIENTS: "ingredients",
  FAVORITE: "favorite",
  SHOW_MORE_FILTERS: "showMoreFilters",
};

export const RANDOM_FETCH = "random"
export const LIST_FETCH = "list"


const initialState = {
  recipeNameToSearch: {
    name: "recipeNameToSearch",
    title: "Nom d'une recette",
    placeholder: "Entrer le nom d'une recette à chercher",
    value: "",
    logo: "icons8-pencil-drawing",
  },
  couvert: {
    name: "couvert", 
    // title: "Couverts", 
    value: 1,
    // unite: "",
    step: 1,
    min: 1,
    max:99,
    logo_unite: "icons8-cutlery",
    logo: "icons8-cutlery",
  },
  repeat: {
    name: "repeat", 
    // title: "Couverts", 
    value: 0,
    // unite: "",
    step: 1,
    min: 0,
    max:99,
    logo_unite: "icons8-repeat",
    logo: "icons8-repeat",
  },
  type: {
    title: "Type",
    name: "type",
    value: "plat",
    logo: "icons8-tapas",
    data: [
      { name: "type", value: "entree", label: 'Entrée', isDefault: false, logo: "icons8-greek-salad"},
      { name: "type", value: "plat", label: 'Plat', isDefault: true, logo: "icons8-paella"},
      { name: "type", value: "dessert", label: 'Dessert', isDefault: false, logo: "icons8-banana-split"}
    ]
  },
  regime: {
    title: "Régime",
    name: "regime",
    value: "omnivore",
    logo: "icons8-real-food-for-meals",
    data: [
      { name: "regime", value: "omnivore", label: "Omnivore", isDefault: true, logo: "icons8-thanksgiving"},
      { name: "regime", value: "vegetarien", label: "Végétarien", isDefault: false, logo: "icons8-vegetarian-food"},
      { name: "regime", value: "vegetalien", label: "Végétalien", isDefault: false, logo: "icons8-vegan-food"}
    ]
  },
  complexity: {
    title: "Compléxité",
    name: "complexity",
    value: "facile",
    logo: "icons8-ok-hand",
    data:
    [
      { name: "complexity", value: "facile", label: "Facile", isDefault: true, logo: "icons8-take-it-easy"},
      { name: "complexity", value: "moyen", label: "Moyen", isDefault: false, logo: "icons8-two-fingers"},
      { name: "complexity", value: "difficile", label: "Difficile", isDefault: false, logo: "icons8-mayura-gesture"}
    ]
  },
  seasons: {
    title: "Saisons",
    name: "seasons",
    logo: "icons8-four-seasons",
    value: [],
    data:
    [
      { name: "printemps", label: "Printemps", defaultValue: "printemps", logo: "icons8-sakura"},
      { name: "ete", label: "Eté", defaultValue: "ete", logo: "icons8-sun"},
      { name: "automne", label: "Automne", defaultValue: "automne", logo: "icons8-autumn"},
      { name: "hiver", label: "Hiver", defaultValue: "hiver", logo: "icons8-snow"}
    ],
  },
  totalTime: {
    name: "totalTime",
    title: "Temps total",
    value: 0,
    unite: "min",
    step: 5,
    min: 0,
    logo: "icons8-sand-timer"
  },
  offsetToFetchRecipesForList: {
    name: "offset",
    value: OFFSET_LIST_RECIPE_RESET,
  },
  typeOfFetch: {
    name: "typeOfFetch",
    title:'Recherche de recettes',
    value: RANDOM_FETCH,
    logo: "icons8-conversion",
    data: [
      { name: "typeOfFetch", value: RANDOM_FETCH, label: 'Aléatoire', isDefault: true, logo: "icons8-dice"},
      { name: "typeOfFetch", value: LIST_FETCH, label: 'Liste', isDefault: false, logo: "icons8-cookbook_2"},
    ]
  },
  numberOfRecipeToFetchInRandom: {
    name: "numberOfRecipe",
    title: "Nombre de recettes aléatoires",
    value: 7,
    unite: "",
    step: 1,
    min: 1,
    logo: "icons8-dice_1",
  },
  ingredients: {
    title: "Liste des ingrédients",
    name: "ingredients",
    value: [],
  },
  favorite: false,
  showMoreFilters: false,
};

const filtersRecipesSlice = createSlice({
  name: SLICE_FILTERS_RECIPES,
  initialState,
  reducers: {
    setRecipeNameToSearch: (state, action) => {
      state.recipeNameToSearch.value = action.payload;
    },
    setCouvert: (state, action) => {
      state.couvert.value = action.payload;
    },
    setRepeat: (state, action) => {
      state.repeat.value = action.payload;
    },
    setType: (state, action) => {
      state.type.value = action.payload;
    },
    setRegime: (state, action) => {
      state.regime.value = action.payload;
    },
    setComplexity: (state, action) => {
      state.complexity.value = action.payload;
    },
    setSeasons: (state, action) => {
      state.seasons.value = action.payload;
    },
    setTotalTime: (state, action) => {
      state.totalTime.value = action.payload;
    },
    setOffsetToFetchRecipesForList: (state, action) => {
      state.offsetToFetchRecipesForList.value = action.payload;
    },
    setTypeOfFetch: (state, action) => {
      state.typeOfFetch.value = action.payload;
    },
    setNumberOfRecipeToFetchInRandom: (state, action) => {
      state.numberOfRecipeToFetchInRandom.value = action.payload;
    },
    setAddIngredient: (state, action) => {
      state.ingredients.value = [...state.ingredients.value, action.payload];
    },
    setExcludeIngredient: (state, action) => {
      const { index, isExclude } = action.payload;
      state.ingredients.value[index].isExclude = isExclude;
    },
    setRemoveIngredient: (state, action) => {
      state.ingredients.value = state.ingredients.value.filter(
        (ingredient) => ingredient.name !== action.payload.name
      );
    },
    setRemoveAllIngredients: (state, action) => {
      state.ingredients.value = [];
    },
    resetState: state => {
      Object.assign(state, initialState)
    },
    setFavorite: (state, action) => {
      state.favorite = !state.favorite;
    },
    setShowMoreFilters: (state, action) => {
      state.showMoreFilters = action.payload;
    },
  },
});

export const {
  setRecipeNameToSearch,
  setCouvert,
  setRepeat,
  setType,
  setRegime,
  setComplexity,
  setSeasons,
  setTotalTime,
  setOffsetToFetchRecipesForList,
  setTypeOfFetch,
  setNumberOfRecipeToFetchInRandom,
  setAddIngredient,
  setExcludeIngredient,
  setRemoveIngredient,
  setRemoveAllIngredients,
  resetState,
  setFavorite,
  setShowMoreFilters,
} = filtersRecipesSlice.actions;

export default filtersRecipesSlice.reducer;