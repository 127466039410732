import React from "react";
import { SectionCol } from "../../elements/sections/Sections";
import ProgressBar from "../../elements/bars/ProgressBar";
import { DivCol, DivColRow } from "../../elements/divs/Divs";
import { H2 } from "../../elements/texts/Texts";
import { useDispatch, useSelector } from "react-redux";
import {
  ETAPES_STEP,
  INFOS_STEP,
  INGREDIENTS_STEP,
  KEYS_EDIT_RECIPE,
  SLICE_EDIT_RECIPE,
  resetState,
  setCurrentStep,
  setDisplayResumeRecipe,
} from "../../../redux/slices/editRecipeSlice";
import { ButtonEmpty, ButtonFill } from "../../elements/bouttons/Buttons";
import InfosEditRecipe from "./InfosEditRecipe";
import IngredientsEditRecipe from "./IngredientsEditRecipe";
import EtapesEditRecipe from "./EtapesEditRecipe";
import SuccessEditModal from "../../elements/modals/editRecipeModal/SuccessEditModal";
import useNewRecipeMutation from "../../../hooks/requests/useNewRecipeMutation";
import LoadingModal from "../../elements/modals/LoadingModal";
import ResumeRecipeModal from "../../elements/modals/ResumeRecipeModal";
import { PROFILE } from "../../../constants/routes";
import useUpdateRecipeMutation from "../../../hooks/requests/useUpdateRecipeMutation";
import {
  KEYS_OWN_RECIPES,
  SLICE_OWN_RECIPES,
} from "../../../redux/slices/ownRecipesSlice";

function EditRecipe() {
  const {
    steps,
    displayResumeRecipe,
    displayAddSuccess,
    displayUpdateSuccess,
    id,
  } = useSelector((state) => ({
    steps: state[SLICE_EDIT_RECIPE][KEYS_EDIT_RECIPE.STEPS],
    displayResumeRecipe:
      state[SLICE_EDIT_RECIPE][KEYS_EDIT_RECIPE.DISPLAY_RESUME_RECIPE],
    displayAddSuccess:
      state[SLICE_EDIT_RECIPE][KEYS_EDIT_RECIPE.DISPLAY_ADD_SUCCESS],
    displayUpdateSuccess:
      state[SLICE_EDIT_RECIPE][KEYS_EDIT_RECIPE.DISPLAY_UPDATE_SUCCESS],
    id: state[SLICE_EDIT_RECIPE][KEYS_EDIT_RECIPE.ID],
  }));

  const newRecipeMutation = useNewRecipeMutation();
  const updateRecipeMutation = useUpdateRecipeMutation();

  return (
    <>
      <SectionCol>
        <StepsProgressBar steps={steps} id={id} />
        <ActualStepFormToFill steps={steps} id={id} />
        <NavigationBetweenSteps steps={steps} />
      </SectionCol>
      {displayResumeRecipe && (
        <EditResumeRecipeModal
          postNewRecipe={newRecipeMutation.mutate}
          postUpdateRecipe={updateRecipeMutation.mutate}
          id={id}
        />
      )}

      {(newRecipeMutation.isLoading || updateRecipeMutation.isLoading) && (
        <LoadingModal />
      )}
      {displayAddSuccess && <SuccessEditModal whoDisplay={"new"} />}
      {displayUpdateSuccess && <SuccessEditModal whoDisplay={"update"} />}
    </>
  );
}

const StepsProgressBar = React.memo(function StepsProgressBar({ steps, id }) {
  return (
    <div className="fixed top-15 sm:top-20 right-0 left-0 z-20 bg-lime-50 w-full pb-2">
      <DivCol add="w-5/6 mx-auto">
        <H2 add="pt-4">
          {id ? "Modifions votre recette" : "Créons une nouvelle recette"}
        </H2>
        <ProgressBar steps={steps} />
      </DivCol>
    </div>
  );
});

function ActualStepFormToFill({ steps, id }) {
  return (
    <DivCol add="w-full space-y-8 pt-24 sm:pt-32">
      {id && <CancelEditionButton />}
      {steps.current === INFOS_STEP && <InfosEditRecipe />}
      {steps.current === INGREDIENTS_STEP && <IngredientsEditRecipe />}
      {steps.current === ETAPES_STEP && <EtapesEditRecipe />}
    </DivCol>
  );
}

function NavigationBetweenSteps({ steps }) {
  return (
    <DivColRow add="mt-4 mb-8 space-x-0 space-y-4 sm:space-x-4 sm:space-y-0">
      <PreviousStepButton currentStep={steps.current} />
      <NextStepButton steps={steps} />
    </DivColRow>
  );
}

function PreviousStepButton({ currentStep }) {
  const dispatch = useDispatch();

  function handlePrev() {
    dispatch(setCurrentStep(currentStep - 1));
  }

  return (
    <ButtonEmpty
      add="w-44"
      onClick={handlePrev}
      disabled={currentStep === INFOS_STEP}
      testid="previous"
    >
      Précédent
    </ButtonEmpty>
  );
}

function NextStepButton({ steps }) {
  const dispatch = useDispatch();

  function handleNext() {
    if (steps.current === ETAPES_STEP) {
      dispatch(setDisplayResumeRecipe(true));
    } else {
      dispatch(setCurrentStep(steps.current + 1));
    }
  }

  return (
    <ButtonFill
      add="w-44"
      onClick={handleNext}
      disabled={
        (steps.current === INFOS_STEP && !steps.list[INFOS_STEP].isFilled) ||
        (steps.current === INGREDIENTS_STEP &&
          !steps.list[INGREDIENTS_STEP].isFilled) ||
        (steps.current === ETAPES_STEP && !steps.list[ETAPES_STEP].isFilled)
      }
      testid="next"
    >
      Suivant
    </ButtonFill>
  );
}

function EditResumeRecipeModal({ postNewRecipe, postUpdateRecipe, id }) {
  const dataForm = useSelector((state) => state[SLICE_EDIT_RECIPE]);

  const seasonsToShow =
    dataForm.seasons.value.length === 0
      ? ["printemps", "ete", "automne", "hiver"]
      : dataForm.seasons.value;

  const editedRecipe = [
    {
      name: dataForm.nameRecipe.value,
      imageRecipe: dataForm.imageRecipe.imageFile,
      type: dataForm.type.value,
      couvert: dataForm.couvert.value,
      complexity: dataForm.complexity.value,
      regime: dataForm.regime.value,
      preparationTime: dataForm.preparationTime.value,
      cuissonTime: dataForm.cuissonTime.value,
      refrigerationTime: dataForm.refrigerationTime.value,
      reposTime: dataForm.reposTime.value,
      totalTime: dataForm.totalTime.value,
      ingredients: dataForm.ingredients.value,
      etapes: dataForm.etapes.value,
      seasons: seasonsToShow,
    },
  ];

  const { imageRecipeShown } = useSelector((state) => ({
    imageNameUpdatedFromBackend:
      state[SLICE_OWN_RECIPES][
        KEYS_OWN_RECIPES.IMAGE_NAME_UPDATED_FROM_BACKEND
      ],
    imageRecipeShown:
      state[SLICE_OWN_RECIPES][KEYS_OWN_RECIPES.IMAGE_RECIPE_SHOWN],
  }));

  let imageToShow = null;
  function isImageUpdateForOwnRecipe(imageRecipe, imageRecipeShown) {
    let shownFileName = imageRecipeShown.split("/").pop();
    if (
      imageRecipe.fileName !== null &&
      imageRecipe.fileName !== shownFileName
    ) {
      return imageRecipe.dataUrl;
    } else {
      shownFileName = imageRecipeShown.split("/").pop().split(".")[0];
      if (
        shownFileName === "main_meal" ||
        shownFileName === "starter_meal" ||
        shownFileName === "dessert_meal"
      ) {
        return imageRecipe.dataUrl;
      } else {
        return imageRecipeShown;
      }
    }
  }

  if (id) {
    imageToShow = isImageUpdateForOwnRecipe(
      dataForm.imageRecipe,
      imageRecipeShown,
      dataForm.type.value
    );
  } else {
    imageToShow = dataForm.imageRecipe.dataUrl;
  }

  return (
    <ResumeRecipeModal
      recipeShown={editedRecipe[0]}
      indexRecipeShown={0}
      imageRecipeShown={imageToShow}
      setShowResumeRecipe={setDisplayResumeRecipe}
      handleChangeLikeStatus={null}
      ButtonHandleActionOnRecipe={() => (
        <SubmitRecipeButton
          postNewRecipe={postNewRecipe}
          postUpdateRecipe={postUpdateRecipe}
          dataForm={dataForm}
          id={id}
        />
      )}
      handleDisplayRecipe={null}
      recipesToDisplay={editedRecipe}
    />
  );
}

function SubmitRecipeButton({ postNewRecipe, postUpdateRecipe, id, dataForm }) {
  let dataToSend = {};

  let formToSend = {
    name: dataForm.nameRecipe.value,
    imageRecipe: dataForm.imageRecipe.imageFile,
    type: dataForm.type.value,
    couvert: dataForm.couvert.value,
    complexity: dataForm.complexity.value,
    regime: dataForm.regime.value,
    preparationTime: dataForm.preparationTime.value,
    cuissonTime: dataForm.cuissonTime.value,
    refrigerationTime: dataForm.refrigerationTime.value,
    reposTime: dataForm.reposTime.value,
    totalTime: dataForm.totalTime.value,
    etapes: dataForm.etapes.value,
    ingredients: dataForm.ingredients.value.map((ingredient) => {
      return {
        food: ingredient._id,
        quantity: ingredient.quantity,
      };
    }),
    seasons:
      dataForm.seasons.value.length === 0
        ? ["printemps", "ete", "automne", "hiver"]
        : dataForm.seasons.value,
  };
  const newImageUploaded =
    dataForm.imageRecipe.fileName !== dataForm.imageRecipeNameToUpdate &&
    dataForm.imageRecipe.fileName !== ""
      ? true
      : false;
  const imageDeleted = dataForm.imageRecipe.fileName === "" ? true : false;
  const infosToUpdateImage = {
    _id: id,
    newImageUploaded: newImageUploaded,
    imageDeleted: imageDeleted,
    previousImageFileName: dataForm.imageRecipeNameToUpdate,
  };

  if (id) {
    dataToSend = {
      ...formToSend,
      ...infosToUpdateImage,
    };
  }

  function handleSubmitRecipe() {
    if (id) {
      postUpdateRecipe(dataToSend);
    } else {
      postNewRecipe(formToSend);
    }
  }

  return (
    <ButtonFill
      onClick={handleSubmitRecipe}
      add="mt-4"
      testid={"submit_recipe_button"}
    >
      {id ? "Valider les modifications" : "Enregistrer la recette"}
    </ButtonFill>
  );
}

function CancelEditionButton() {
  const dispatch = useDispatch();

  function handleCancelEdition() {
    dispatch(resetState());
  }

  return (
    <ButtonFill
      add="mt-8"
      onClick={handleCancelEdition}
      nav={PROFILE.route}
      testid="cancel_edition"
    >
      Annuler modification
    </ButtonFill>
  );
}

export default EditRecipe;
