import {useCallback} from 'react'
import { useDispatch } from 'react-redux';

function useHandleDisplayRecipe(setRecipeShown, setIndexRecipeShown, setShowResumeRecipe, setImageRecipeShown) {
  const dispatch = useDispatch();

  const handleDisplayRecipe = useCallback(
    (index, urlImage, recipe) => {
      dispatch(setRecipeShown(recipe));
      dispatch(setIndexRecipeShown(index));
      dispatch(setShowResumeRecipe(true));
      dispatch(setImageRecipeShown(urlImage));
    },
    [dispatch, setRecipeShown, setIndexRecipeShown, setShowResumeRecipe, setImageRecipeShown]
  );

  return handleDisplayRecipe
}

export default useHandleDisplayRecipe