import axios from "axios";
import { BASE_URL_API } from "../../constants/routes";

const mealsterPrivate = axios.create({
  baseURL: BASE_URL_API,
  headers: { "Content-Type": "application/json" },
  withCredentials: true,
});

mealsterPrivate.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error?.config;
    if (originalRequest.url !== "update_access_token") {
      if (
        (error?.response?.status === 403 || error?.response?.status === 401) &&
        !originalRequest._retry
      ) {
        originalRequest._retry = true;

        try {
          const res = await axios.get(BASE_URL_API + "update_access_token", {
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
          });
          if (res.data.status === 0) {
            return mealsterPrivate(originalRequest);
          } else {
            const error = res.data.message;
            console.log("error mealsterPrivate", error);
            throw error;
          }
        } catch (_error) {
          console.log("_error", _error);
          return Promise.reject(_error);
        }
      }
    }
  }
);

export default mealsterPrivate;