import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  tempRecipesFetched: [],
  showResumeRecipe: false,
  recipeShown: null,
  indexRecipeShown: 0,
  imageRecipeShown: null,
  displaySuccess: false,
  fileNameRecipeShown: null,
  imageNameUpdatedFromBackend: null,
}

export const SLICE_TEMP_RECIPES = "tempRecipes";
export const KEYS_TEMP_RECIPES = {
  TEMP_RECIPES_FETCHED: "tempRecipesFetched",
  SHOW_RESUME_RECIPE: "showResumeRecipe",
  RECIPE_SHOWN: "recipeShown",
  INDEX_RECIPE_SHOWN: "indexRecipeShown",
  IMAGE_RECIPE_SHOWN: "imageRecipeShown",
  DISPLAY_SUCCESS: "displaySuccess",
  IMAGE_NAME_UPDATED_FROM_BACKEND: "imageNameUpdatedFromBackend",
};

const tempRecipesSlice = createSlice({
  name: SLICE_TEMP_RECIPES,
  initialState,
  reducers: {
    setTempRecipesFetched: (state, action) => {
      state.tempRecipesFetched = action.payload;
    },
    setTempRecipeSelected: (state, action) => {
      const recipeId = action.payload;
      state.tempRecipesFetched = state.tempRecipesFetched.map(recipe => {
        if (recipe._id === recipeId) {
          return {...recipe, selected: !recipe.selected};
        }
        return recipe;
      });
    },
    setTempAllRecipesSelected: (state, action) => {
      state.tempRecipesFetched = state.tempRecipesFetched.map((recipe) => ({
        ...recipe,
        selected: true,
      }));
    },
    setAllTempRecipesDeselected: (state, action) => {
      state.tempRecipesFetched = state.tempRecipesFetched.map((recipe) => ({
        ...recipe,
        selected: false,
      }));
    },
    setShowResumeRecipe: (state, action) => {
      state.showResumeRecipe = action.payload;
    },
    setRecipeShown: (state, action) => {
      state.recipeShown = action.payload;
    },
    setIndexRecipeShown: (state, action) => {
      state.indexRecipeShown = action.payload;
    },
    setImageRecipeShown: (state, action) => {
      state.imageRecipeShown = action.payload;
    },
    setDisplaySuccess: (state, action) => {
      state.displaySuccess = action.payload;
    },
    setImageNameUpdatedFromBackend: (state, action) => {
      state.imageNameUpdatedFromBackend = action.payload;
    },
    setFileNameRecipeShown: (state, action) => {
      state.recipeShown.imageRecipe = action.payload;
    },
    setRecipeLiked: (state, action) => {
      const recipeId = action.payload;
      state.tempRecipesFetched = state.tempRecipesFetched.map(recipe => {
        if (recipe._id === recipeId) {
          return {...recipe, isLiked: !recipe.isLiked};
        }
        return recipe;
      });
    },
  },
});

export const {
  setTempRecipesFetched,
  setRecipeSelected,
  setAllRecipesSelected,
  setAllRecipesDeselected,
  setShowResumeRecipe,
  setRecipeShown,
  setIndexRecipeShown,
  setImageRecipeShown,
  setDisplaySuccess,
  setImageNameUpdatedFromBackend,
  setFileNameRecipeShown,
  setRecipeLiked,
} = tempRecipesSlice.actions;

export default tempRecipesSlice.reducer;