import React from 'react';

const starterMeal = require('../../../assets/images/starter_meal.png');
const mainMeal = require('../../../assets/images/main_meal.png');
const dessertMeal = require('../../../assets/images/dessert_meal.png');

const recipeImages = {
  entree: starterMeal,
  plat: mainMeal,
  dessert: dessertMeal,
  default: mainMeal,
};

function ImageRecipeUploaded({ dataUrl, typeRecipe }) {
  const imgSrc = dataUrl || recipeImages[typeRecipe] || recipeImages.default;

  return (
    // <div className='h-full w-full'>
      <img src={imgSrc} alt="image_recipe" className="object-contain h-full w-full" testid="image_recipe"/>
    // </div>
  );
}

export default ImageRecipeUploaded;
