import React from 'react'
import { DecoIcon } from './IconImage'

function ValidationFieldImage({fieldIsOk}) {
  const nameImage = fieldIsOk ? "icons8-check-icon" : "cross-icon"

  return (
    <DecoIcon iconName={nameImage} altName={nameImage} size={"h-5 w-5"} add={"ml-2"}  />
  )
}

export default ValidationFieldImage