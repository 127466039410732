import React from "react";
import GlobalModal from "../GlobalModal";
import { DivCol } from "../../divs/Divs";
import ReactConfetti from "react-confetti";
import { H1, H2, H3 } from "../../texts/Texts";
import CatApprouve from "../../../../assets/images/cat_approuve.png";
import clsx from "clsx";
import {
  setDisplayAddSuccess,
  setDisplayUpdateSuccess,
} from "../../../../redux/slices/editRecipeSlice";
import { useDispatch } from "react-redux";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { PROFILE } from "../../../../constants/routes";
import { ButtonFill } from "../../bouttons/Buttons";

function SuccessEditModal({ whoDisplay }) {

  const divSuccessStyle = clsx(
    "relative",
    "bg-lime-100",
    "mt-14",
    "w-4/5",
    "h-4/5",
    "rounded-lg",
    "drop-shadow-3xl",
    "px-4",
    "lg:pb-0",
    "overflow-y-scroll",
    "hide-scrollbar-firefox"
  );

  return (
    <GlobalModal>
      <DivCol add={divSuccessStyle} testid="modal_recipe_success">
        <ContentSuccessEditModal whoDisplay={whoDisplay} />
        <ReactConfetti className="w-full h-full absolute" />
      </DivCol>
    </GlobalModal>
  );
}

function ContentSuccessEditModal({ whoDisplay }) {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

function handleClick() {
  queryClient.invalidateQueries("get_own_recipes");
  if (whoDisplay === "new") {
    dispatch(setDisplayAddSuccess(false));
  } else {
    dispatch(setDisplayUpdateSuccess(false));
    navigate(PROFILE.route);
  }
}

  return (
    <DivCol add="transform -translate-y-16">
      <H1 add="text-center">
        {whoDisplay === "new" ? "Recette validée" : "Recette mise à jour"}
      </H1>
      <img className="h-56 w-56" src={CatApprouve} alt="Cat approuve" />
      <H2 add="text-center">Merci pour votre contribution</H2>
      <H3 add="text-center">Vous serez informé de sa disponibilité après un contrôle de la modération</H3>
      <ButtonFill add={"mt-6"} onClick={handleClick}>Fermer</ButtonFill>
    </DivCol>
  );
}

export default SuccessEditModal;
