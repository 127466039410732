import React from 'react'
import clsx from 'clsx'

function CardStand({children, onClick}) {

  const cardStandStyle = clsx(
    "bg-lime-700",
    "border-4 border-lime-900 rounded-lg",
    "hover:border-lime-500",
    "transition-transform duration-300 hover:scale-105",
    "w-80 md:w-64",
    "h-52 md:h-96",
    "relative",
    "cursor-pointer",
    "px-1 py-1 md:px-2 md:py-2",
    "m-1 md:m-2",
    "flex flex-row md:flex-col justify-between md:justify-start items-center",
  )

  return (
    <div className={cardStandStyle} onClick={onClick}>
      {children}
    </div>
  )
}

export default CardStand