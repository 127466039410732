import { useMutation } from "react-query";
import { delete_recipes_to_cook } from "../../functions/requests/axiosRequests";
import { useDispatch } from "react-redux";
import { setRecipesToCookFetched } from "../../redux/slices/recipesToCookSlice";
import useError from "../utils/useError";

function useDeleteRecipesToCookMutation(recipesToCookFetched) {
  const dispatch = useDispatch();
  const { setError } = useError();

  const deleteRecipesToCookMutation = useMutation(
    "delete_recipes_to_cook",
    delete_recipes_to_cook,
    {
      onSuccess: (data, recipesId) => {
        const remainingRecipes = recipesToCookFetched.filter(
          (recipe) => !recipesId.recipesToDelete.includes(recipe.idRecipeToCook.toString())
          );
          dispatch(setRecipesToCookFetched(remainingRecipes));
      },
      onError: (error) => {
        setError(error.response?.data?.message || "Une erreur s'est produite");
      },
    }
  );

  return deleteRecipesToCookMutation;
}

export default useDeleteRecipesToCookMutation;
