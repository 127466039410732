import { useDispatch, useSelector } from "react-redux";
import { SLICE_FILTERS_RECIPES, setOffsetToFetchRecipesForList } from "../../redux/slices/filtersRecipesSlice";
import { OFFSET_LIST_RECIPE, OFFSET_LIST_RECIPE_RESET } from "../../constants/recipe";

function useSubmitFilter(mutation) {
  const filtersRecipes = useSelector((state) => state[SLICE_FILTERS_RECIPES]);
  const dispatch = useDispatch();
  
  function submitFilters(incrementOffset = false) {
    const filterToApply = {
      recipeNameToSearch: filtersRecipes.recipeNameToSearch.value,
      couvert: filtersRecipes.couvert.value,
      type: filtersRecipes.type.value,
      regime: filtersRecipes.regime.value,
      complexity: filtersRecipes.complexity.value,
      totalTime: filtersRecipes.totalTime.value,
      offset: incrementOffset ? filtersRecipes.offsetToFetchRecipesForList.value : OFFSET_LIST_RECIPE_RESET,
      typeOfFetch: filtersRecipes.typeOfFetch.value,
      numberOfRecipe: filtersRecipes.numberOfRecipeToFetchInRandom.value,
      ingredients: filtersRecipes.ingredients.value,
      favorite: filtersRecipes.favorite,
      seasons:
        filtersRecipes.seasons.value.length === 0
          ? ["printemps", "ete", "automne", "hiver"]
          : filtersRecipes.seasons.value,
    };

    mutation(filterToApply);
    if(incrementOffset) { 
      dispatch(setOffsetToFetchRecipesForList(filtersRecipes.offsetToFetchRecipesForList.value+OFFSET_LIST_RECIPE));
    }
    else {
      dispatch(setOffsetToFetchRecipesForList(OFFSET_LIST_RECIPE_RESET));
    }
  }

  return submitFilters;
}

export default useSubmitFilter;