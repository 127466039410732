import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  favoriteRecipesFetched: [],
  showResumeRecipe: false,
  recipeShown: null,
  indexRecipeShown: null,
  imageRecipeShown: null,
  displaySuccess: false,
}

export const SLICE_FAVORITE_RECIPES = "favoriteRecipes";
export const KEYS_FAVORITE_RECIPES = {
  FAVORITE_RECIPES_FETCHED: "favoriteRecipesFetched",
};

const favoriteRecipesSlice = createSlice({
  name: SLICE_FAVORITE_RECIPES,
  initialState,
  reducers: {
    setFavoriteRecipesFetched: (state, action) => {
      state.favoriteRecipesFetched = action.payload;
    },
    setRecipeSelected: (state, action) => {
      const recipeId = action.payload;
      state.favoriteRecipesFetched = state.favoriteRecipesFetched.map(recipe => {
        if (recipe._id === recipeId) {
          return {...recipe, selected: !recipe.selected};
        }
        return recipe;
      });
    },
    setAllRecipesSelected: (state, action) => {
      state.favoriteRecipesFetched = state.favoriteRecipesFetched.map((recipe) => ({
        ...recipe,
        selected: true,
      }));
    },
    setAllRecipesDeselected: (state, action) => {
      state.favoriteRecipesFetched = state.favoriteRecipesFetched.map((recipe) => ({
        ...recipe,
        selected: false,
      }));
    },
    setShowResumeRecipe: (state, action) => {
      state.showResumeRecipe = action.payload;
    },
    setRecipeShown: (state, action) => {
      state.recipeShown = action.payload;
    },
    setIndexRecipeShown: (state, action) => {
      state.indexRecipeShown = action.payload;
    },
    setImageRecipeShown: (state, action) => {
      state.imageRecipeShown = action.payload;
    },
  },
});

export const {
  setFavoriteRecipesFetched,
  setRecipeSelected,
  setAllRecipesSelected,
  setAllRecipesDeselected,
  setShowResumeRecipe,
  setRecipeShown,
  setIndexRecipeShown,
  setImageRecipeShown,
} = favoriteRecipesSlice.actions;

export default favoriteRecipesSlice.reducer;