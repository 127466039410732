import React from "react";
import clsx from "clsx";
import { LOGIN, REGISTER } from "../../constants/routes";
import WelcomeCat from "../../assets/images/welcome_cat.png";
import { ButtonFill, ButtonEmpty } from "../elements/bouttons/Buttons";
import {H1, H2, H3} from "../elements/texts/Texts";


const Welcome = () => {
  setTimeout(
    () =>
      document
        .querySelectorAll(".opacity-0")
        .forEach((el) => el.classList.add("opacity-100")),
    300
  );

  //#region Styles
  const sectionStyle = clsx(
    "pt-20 px-2",
    "flex flex-col justify-center items-center",
  )

  const hStyle = clsx(
    "overflow-hidden",
    "text-center",
  );

  const spanAnimation = clsx(
    "block",
    "translate-y-full",
    "animate-spanFadeInY"
  );

  const divAnimation = clsx(
    "opacity-0",
    "transition-opacity duration-300 ease-in-out"
  )

  const divFlexCenter = clsx(
    "flex",
    "flex-col",
    "sm:flex-row",
    "items-center",
    "justify-center",
  );
  //#endregion

  return (
    <section className={sectionStyle}>
        <H1 add={hStyle}>
          <span className={spanAnimation}>Bienvenue sur Mealster</span>
        </H1>
        <H2 add={clsx(hStyle,"pt-4")}>
          <span className={clsx(spanAnimation, "animation-delay-50")}>
            Vos recettes sans prise de tête
          </span>
        </H2>
        <H3 add={clsx(hStyle,"pt-12")}>
          <span className={clsx(spanAnimation, "animation-delay-100")}>
            Obtenez vos plats, vos courses en un clic, et cuisinez avec délice !
          </span>
        </H3>

        <div className={divAnimation}>
          <div className={clsx(divFlexCenter, "mt-8","gap-4")}>
            <ButtonFill nav={LOGIN.route}>
              {LOGIN.name}
            </ButtonFill>
            <ButtonEmpty nav={REGISTER.route}>
              {REGISTER.name}
            </ButtonEmpty>
          </div>
          <div className={divFlexCenter}>
            <img
              src={WelcomeCat}
              alt="Chat"
              className="h-64 w-64 md:h-96 md:w-96"
            />
          </div>
        </div>
    </section>
  );
};

export default Welcome;
