export const defaultValues = {
  email: "",
  author: "",
  pwd: "",
  cpwd: ""
}

export const email = {
  title: "Adresse email",
  name: "email",
  type: "email",
  placeholder: "Veuillez renseigner votre email",
  defaultValue: "",
}


export const actualEmail = {
  title: "Adresse email actuelle",
  name: "actualEmail",
  type: "email",
  placeholder: "Veuillez renseigner votre email",
  defaultValue: "",
}

export const newEmail = {
  title: "Nouvelle adresse email",
  name: "newEmail",
  type: "email",
  placeholder: "Veuillez renseigner votre email",
  defaultValue: "",
}

export const confirmNewEmail = {
  title: "Confirmation adresse email",
  name: "confirmNewEmail",
  type: "email",
  placeholder: "Veuillez renseigner votre email",
  defaultValue: "",
}

export const author = {
  title: "Pseudonyme",
  name: "author",
  type: "text",
  placeholder: "Veuillez renseigner votre pseudo",
  defaultValue: "",
}
export const pwd = {
  title: "Mot de passe",
  name: "pwd",
  type: "password",
  placeholder: "Ecrivez votre mot de passe",
  defaultValue: "",
}
export const cpwd = {
  title: "Confirmation mot de passe",
  name: "cpwd",
  type: "password",
  placeholder: "Réécrivez votre mot de passe",
  defaultValue: "",
}

export const persist = {
  title: "Se souvenir de moi",
  name: "persist",
  type: "checkbox",
  defaultValues: false,
  logo: require("../assets/icons/deco_icons/icons8-check-icon.svg").default,
}

export const actualPwd = {
  title: "Mot de passe actuel",
  name: "actualPwd",
  placeholder: "",
  type: "password",
  defaultValue: "",
}

export const newPwd = {
  title: "Nouveau mot de passe",
  name: "newPwd",
  placeholder: "",
  type: "password",
  defaultValue: "",
}

export const confirmNewPwd = {
  title: "Confirmer le mot de passe",
  name: "confirmNewPwd",
  placeholder: "",
  type: "password",
  defaultValue: "",
}


export const registerFormValues = {
  email,
  author,
  pwd,
  cpwd
}

export const loginFormValues = {
  email,
  pwd
}

export const changePwdFormValues = {
  actualPwd,
  newPwd,
  confirmNewPwd
}

export const forgotPwdFormValues = {
  email
}

export const resetPwdFormValues = {
  newPwd,
  confirmNewPwd
}

export const changeEmailFormValues = {
  actualEmail,
  newEmail,
  confirmNewEmail,
}