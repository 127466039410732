import React from "react";
import { DivRow } from "../divs/Divs";
import { H3 } from "../texts/Texts";
import ValidationFieldImage from "../images/ValidationFieldImage";
import { TextInputStore } from "../inputs/TextInputs";
import { DecoIcon } from "../images/IconImage";

function FieldText({ state, action, fieldIsOk = null, titleCentered = false }) {
  return (
    <div className="w-full">
      <TitleFieldText
        title={state.title}
        logo={state.logo}
        fieldIsOk={fieldIsOk}
        titleCentered={titleCentered}
      />

      <TextInputStore state={state} action={action} />
    </div>
  );
}

function TitleFieldText({
  title,
  logo,
  fieldIsOk = null,
  titleCentered = false,
}) {
  return (
    <DivRow add="pb-1 space-x-2" >
      {logo && <DecoIcon iconName={logo} altName={title} size="h-8 w-8" />}
      <H3 add={titleCentered ? "text-center" : ""}>{title}</H3>
      {fieldIsOk !== null && <ValidationFieldImage fieldIsOk={fieldIsOk} />}
    </DivRow>
  );
}

export default React.memo(FieldText);
