import clsx from "clsx";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import GlobalModal from "./GlobalModal";
import { DecoIcon } from "../images/IconImage";
import ButtonCloseModal from "../bouttons/ButtonCloseModal";
import { DivCol } from "../divs/Divs";
import NameRecipe from "../../blocks/resumeRecipe/NameRecipe";
import {
  ComplexityRecipe,
  CouvertRecipe,
  RegimeRecipe,
  TypeRecipe,
} from "../../blocks/resumeRecipe/OtherInfoRecipe";
import {
  CuissonTime,
  PreparationTime,
  RefrigerationTime,
  ReposTime,
} from "../../blocks/resumeRecipe/TimesInfoRecipe";
import Ingredients from "../../blocks/resumeRecipe/Ingredients";
import EtapesRecipes from "../../blocks/resumeRecipe/EtapesRecipes";
import LeftArrow from "../../../assets/icons/deco_icons/icons8-left-arrow.svg";
import RightArrow from "../../../assets/icons/deco_icons/icons8-right-arrow.svg";
import useError from "../../../hooks/utils/useError";
import ImageRecipeUploaded from "../images/ImageRecipeUploaded";
import useWindowWidth from "../../../hooks/utils/useWindowWidth";
import SeasonIcon from "../images/SeasonIcon";
import { H2, H3 } from "../texts/Texts";
import ButtonIcon from "../bouttons/ButtonIcon";
import {BASE_URL_API, EDITER} from "../../../constants/routes";
import useHandleFavoritesRecipes from "../../../hooks/utils/useHandleFavoritesRecipes";
import { useLocation } from "react-router-dom";

function ResumeRecipeModal({
  recipeShown,
  indexRecipeShown,
  imageRecipeShown,
  setShowResumeRecipe,
  ButtonHandleActionOnRecipe,
  handleDisplayRecipe,
  recipesToDisplay,
  closeModalCustom = null,
  couvertWanted = null,
  repeat = null,
}) {
  const dispatch = useDispatch();
  const { setError } = useError();

  function closeModal() {
    dispatch(setShowResumeRecipe(false));
    if (closeModalCustom) {
      closeModalCustom();
    }
  }

  const divDisplayResumeStyle = clsx(
    "absolute",
    // "relative",
    "bg-lime-100",
    "mt-4",
    "mb-4",
    "w-4/5",
    "h-9/10",
    "rounded-lg",
    "drop-shadow-3xl",
    "px-4",
    "lg:pb-0",
    "overflow-y-scroll",
    "hide-scrollbar-firefox"
  );

  function handleArrow(direction) {
    if (direction === "left") {
      handleLeft();
    } else if (direction === "right") {
      handleRight();
    } else {
      setError("Erreur lors de la navigation entre les recettes");
    }
  }

  function handleLeft() {
    if (indexRecipeShown === 0) {
      const srcImg = setImageRecipeShown(
        recipesToDisplay[recipesToDisplay.length - 1]
      );
      handleDisplayRecipe(
        recipesToDisplay.length - 1,
        srcImg,
        recipesToDisplay[recipesToDisplay.length - 1]
      );
    } else {
      const srcImg = setImageRecipeShown(
        recipesToDisplay[indexRecipeShown - 1]
      );
      handleDisplayRecipe(
        indexRecipeShown - 1,
        srcImg,
        recipesToDisplay[indexRecipeShown - 1]
      );
    }
  }

  function setImageRecipeShown(recipe) {
    switch (recipe.imageRecipe) {
      case "starter_meal.png":
      case "main_meal.png":
      case "dessert_meal.png":
        return require(`../../../assets/images/${recipe.imageRecipe}`);
      default:
        return `${BASE_URL_API}/image_recipe/${recipe.imageRecipe}`;
    }
  }

  function handleRight() {
    if (indexRecipeShown === recipesToDisplay.length - 1) {
      const srcImg = setImageRecipeShown(recipesToDisplay[0]);
      handleDisplayRecipe(0, srcImg, recipesToDisplay[0]);
    } else {
      const srcImg = setImageRecipeShown(
        recipesToDisplay[indexRecipeShown + 1]
      );
      handleDisplayRecipe(
        indexRecipeShown + 1,
        srcImg,
        recipesToDisplay[indexRecipeShown + 1]
      );
    }
  }

  return (
    <GlobalModal>
      <div className={divDisplayResumeStyle}>
        <ButtonCloseModal closeModal={closeModal} />

        <DivCol add={"w-full h-full"}>
          <ContentRecipe
            add={"w-full h-full"}
            recipeShown={recipeShown}
            imageRecipeShown={imageRecipeShown}
            ButtonHandleActionOnRecipe={ButtonHandleActionOnRecipe}
            couvertWanted={couvertWanted}
            repeat={repeat}
          />
        </DivCol>
      </div>
      {recipesToDisplay.length > 1 && (
        <>
          <LeftArrowPreviousRecipe handleArrow={handleArrow} />

          <RightArrowNextRecipe handleArrow={handleArrow} />
        </>
      )}
    </GlobalModal>
  );
}

function ContentRecipe({
  recipeShown,
  imageRecipeShown,
  ButtonHandleActionOnRecipe,
  couvertWanted,
  changeLikeStatus,
  repeat,
}) {
  const isMobile = useWindowWidth();
  return (
    <>
      {!isMobile && (
        <DesktopContentRecipe
          recipeShown={recipeShown}
          imageRecipeShown={imageRecipeShown}
          ButtonHandleActionOnRecipe={ButtonHandleActionOnRecipe}
          couvertWanted={couvertWanted}
          repeat={repeat}
        />
      )}
      {isMobile && (
        <MobileContentRecipe
          recipeShown={recipeShown}
          imageRecipeShown={imageRecipeShown}
          ButtonHandleActionOnRecipe={ButtonHandleActionOnRecipe}
          couvertWanted={couvertWanted}
          repeat={repeat}
        />
      )}
    </>
  );
}

function DesktopContentRecipe({
  recipeShown,
  imageRecipeShown,
  ButtonHandleActionOnRecipe,
  couvertWanted,
  repeat,
}) {
  const location = useLocation();
  const isEditPage = location.pathname.includes(EDITER.route);
  
  //#region styles
  const divContainerStyle = clsx(
    "w-full",
    "h-full",
    "flex",
    "flex-row",
    "px-0 lg:px-1 xl:px-2",
    "pb-4",
    "space-x-2",
    // "overflow-hidden"
  );
  const divColOneStyle = clsx(
    "w-2/3",
    "h-full",
    "flex flex-col",
    "justify-start items-start",
    "overflow-hidden"
  );

  const divTitlesStyle = clsx(
    "w-full",
    "h-2/12",
    "flex flex-col",
    "justify-start items-center",
    "pt-2"
  );

  const divInfosDetailsStyle = clsx(
    "w-full",
    "h-2/12",
    "flex flex-col",
    "justify-start items-start",
  );

  const divInfosStyle = clsx(
    "w-full",
    "px-0.5",
    // "h-1/2",
    "flex flex-row",
    "justify-center items-start",
    "space-x-2 lg:space-x-8 xl:space-x-12 2xl:space-x-16",
  );

  const divTimesStyle = clsx(
    "w-full",
    "flex flex-wrap",
    "justify-center items-start",
    "space-x-4 lg:space-x-8 xl:space-x-12 2xl:space-x-16",
    "pt-4"
  );

  const divIngsStepsStyle = clsx(
    "w-full",
    "h-8/12",
    "flex flex-row",
    "justify-center items-start",
    // "md:pt-2 lg:pt-0",
    "space-x-2",
    "md:pt-14 lg:pt-12 xl:pt-6",
  );

  const divIngsStyle = clsx(
    "w-1/2",
    "h-full",
    "flex flex-col",
    "overflow-y-auto"
  );

  const divStepsStyle = clsx(
    "w-1/2",
    "h-full",
    "flex flex-col",
    "overflow-y-auto"
  );

  const divColTwoStyle = clsx(
    "w-1/3",
    "h-full",
    "flex flex-col",
    "justify-start items-center",
    "overflow-hidden",
    "pt-4"
  );

  const divImageStyle = clsx(
    "w-full",
    "h-6/12",
    "flex flex-col",
    "justify-center items-center",
    "mt-6"
  );

  const divSeasonStyle = clsx(
    "w-full",
    "h-1/12",
    "flex flex-row",
    "space-x-2",
    "mt-2",
    "justify-around items-center"
  );

  const divFavRepeatStyle = clsx(
  "flex flex-row",
  "w-full",
  "h-2/12",
  "justify-center items-center"
  )

  const divFavorisStyle = clsx(
    "w-full",
    "h-full",
    "flex flex-col",
    "justify-center items-center"
  );

  const divRepeatStyle = clsx(
    "flex flex-row",
    "w-full",
    "h-full",
    "justify-center items-center",
    "space-x-2"
  )

  const divHandleButtonStyle = clsx(
    "flex flex-col",
    "w-full",
    "h-3/12",
    "justify-center items-center"
  );

  //#endregion styles

  return (
    <div className={divContainerStyle}>
      <div className={divColOneStyle}>
        <div className={divTitlesStyle}>
          <NameRecipe nameRecipe={recipeShown.name} />
        </div>
        <div className={divInfosDetailsStyle}>
          <div className={divInfosStyle}>
            <CouvertRecipe
              value={recipeShown.couvert}
              couvertWanted={couvertWanted}
            />
            <TypeRecipe value={recipeShown.type} />
            <ComplexityRecipe value={recipeShown.complexity} />
            <RegimeRecipe value={recipeShown.regime} />
          </div>
          <div className={divTimesStyle}>
            <PreparationTime value={recipeShown.preparationTime} />
            <CuissonTime value={recipeShown.cuissonTime} />
            <RefrigerationTime value={recipeShown.refrigerationTime} />
            <ReposTime value={recipeShown.reposTime} />
          </div>
        </div>
        <div className={divIngsStepsStyle}>
          <div className={divIngsStyle} testid="divIngsStyle">
            <Ingredients
              value={recipeShown.ingredients}
              baseCouvert={recipeShown.couvert}
              couvertWanted={couvertWanted}
              add={"w-full overflow-y-scroll hide-scrollbar-firefox"}
            />
          </div>
          <div className={divStepsStyle}>
            <EtapesRecipes
              value={recipeShown.etapes}
              add={"w-full overflow-y-scroll hide-scrollbar-firefox"}
            />
          </div>
        </div>
      </div>
      <div className={divColTwoStyle}>
        <div className={divImageStyle}>
          <ImageRecipeUploaded
            dataUrl={imageRecipeShown}
            typeRecipe={recipeShown.type}
          />
        </div>
        <div className={divSeasonStyle}>
          <SeasonIcon seasons={recipeShown.seasons} />
        </div>
        <div className={divFavRepeatStyle}>
          {!isEditPage && (
            <div className={divFavorisStyle}>
              <H3 add="pb-2">Favoris</H3>
              <Favoris
                recipe={recipeShown}
              />
            </div>
          )}
          {repeat > 0 && (
            <div className={divRepeatStyle}>
              <DecoIcon
                iconName={"icons8-repeat"}
                altName={"repeat_recipe"}
                size={"h-8 w-8"}
              />
              <H3>Répétée {repeat} fois</H3>
            </div>
          )}
        </div>
        <div className={divHandleButtonStyle}>
        <ButtonHandleActionOnRecipe recipeShown={recipeShown} />
        </div>
      </div>
    </div>
  );
}

function MobileContentRecipe({
  recipeShown,
  imageRecipeShown,
  ButtonHandleActionOnRecipe,
  couvertWanted,
  repeat,
}) {
  const location = useLocation();
  const isEditPage = location.pathname.includes(EDITER.route);

  //#region styles
  const divContainerStyle = clsx(
    "relative",
    "w-full",
    "h-full",
    "flex flex-col",
    "justify-start items-center",
    "overflow-hidden"
  );

  const divFavorisStyle = clsx(
    "absolute",
    "top-0 left-0",
    "flex flex-col",
    "justify-center items-center"
  );

  const divPicAndTitleStyle = clsx("w-full", "h-4/12", "relative", "-z-10");

  const divTitleStyle = clsx(
    "absolute",
    "w-full",
    // "bg-lime-50/75",
    "bg-gradient-to-r from-transparent from-5% via-lime-50/[.90] via-50% to-transparent to-95%",
    // middle of the pic
    "top-3/4 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
  );

  const divNavBarStyle = clsx(
    "w-full",
    "h-1/12",
    "flex flex-row",
    "justify-around items-center"
  );

  const divInfosStyle = clsx(
    "w-full",
    "h-5/12",
    "flex flex-col",
    "justify-start items-center",
    "overflow-y-auto"
  );
  const divTitleInfos = clsx(
    "w-full",
    "h-1/12",
    "flex flex-col",
    "pt-2",
    "justify-center items-center",
  );

  const divInfosContentStyle = clsx(
    "w-full",
    "h-11/12",
    "mt-2",
    "flex flex-col",
    "justify-start items-center",
    "space-y-4",
    "overflow-y-auto"
  );

  const divIngsStyle = clsx(
    "w-full",
    "h-5/12",
    "flex flex-col",
    "justify-start items-center",
    "overflow-y-auto"
  );

  const divStepsStyle = clsx(
    "w-full",
    "h-5/12",
    "flex flex-col",
    "justify-start items-center",
    "overflow-y-auto"
  );

  const divButtonHandleStyle = clsx(
    "w-full",
    "h-2/12",
    "flex flex-col",
    "justify-center items-center"
  );
  //#endregion styles

  const DISPLAY_TYPES = [
    { label: "Infos", icon: "icons8-left-handed" },
    { label: "Ingrédients", icon: "icons8-ingredients-for-cooking" },
    { label: "Etapes", icon: "icons8-ambition" },
  ];

  const [whoDisplay, setWhoDisplay] = useState(DISPLAY_TYPES[0].label);

  return (
    <div className={divContainerStyle}>
      {!isEditPage && (
        <div className={divFavorisStyle}>
          <Favoris recipe={recipeShown} />
        </div>
      )}
      <div className={divPicAndTitleStyle}>
        <ImageRecipeUploaded
          dataUrl={imageRecipeShown}
          typeRecipe={recipeShown.type}
        />
        <div className={divTitleStyle}>
          <NameRecipe nameRecipe={recipeShown.name} />
        </div>
      </div>
      <div className={divNavBarStyle}>
        {DISPLAY_TYPES.map((displayType) => (
          <ButtonIcon
            key={displayType.label}
            handleClick={() => setWhoDisplay(displayType.label)}
            isSelected={whoDisplay === displayType.label}
            iconName={displayType.icon}
            spanText={displayType.label}
            disableSpan={true}
          />
        ))}
      </div>
      {whoDisplay === DISPLAY_TYPES[0].label && (
        <div className={divInfosStyle}>
          <div className={divTitleInfos}>
            <H2 size={"text-2xl"}>Infos</H2>
          </div>
          <div className={divInfosContentStyle}>
            <CouvertRecipe
              value={recipeShown.couvert}
              couvertWanted={couvertWanted}
            />
            <TypeRecipe value={recipeShown.type} />
            <ComplexityRecipe value={recipeShown.complexity} />
            <RegimeRecipe value={recipeShown.regime} />
            <PreparationTime value={recipeShown.preparationTime} />
            <CuissonTime value={recipeShown.cuissonTime} />
            <RefrigerationTime value={recipeShown.refrigerationTime} />
            <ReposTime value={recipeShown.reposTime} />
            <div className="flex flex-row justify-around items-center">
              <SeasonIcon seasons={recipeShown.seasons} />
            </div>
          </div>
        </div>
      )}
      {whoDisplay === DISPLAY_TYPES[1].label && (
        <div className={divIngsStyle}>
          <Ingredients
            value={recipeShown.ingredients}
            baseCouvert={recipeShown.couvert}
            couvertWanted={couvertWanted}
            add={"w-full overflow-y-scroll"}
          />
        </div>
      )}
      {whoDisplay === DISPLAY_TYPES[2].label && (
        <div className={divStepsStyle}>
          <EtapesRecipes
            value={recipeShown.etapes}
            add={"w-full overflow-y-scroll"}
          />
        </div>
      )}
      <div className={divButtonHandleStyle}>
        <ButtonHandleActionOnRecipe recipeShown={recipeShown} />
      </div>
    </div>
  );
}

function Favoris({ recipe }) {
  const [isLiked, setIsLiked] = useState(recipe.isLiked);
  const handleChangeLikeStatus = useHandleFavoritesRecipes();
  
  function handleClick(event) {
    event.stopPropagation();
    handleChangeLikeStatus(recipe._id, recipe.isLiked);
    setIsLiked(prev => !prev);
  }

  return (
    <div
      className="cursor-pointer hover:animate-heart-beat mt-2 ml-2 md:ml-0 md:mt-0"
      onClick={handleClick}
    >
      {!isLiked && (
        <DecoIcon
          iconName={"empty-heart"}
          altName={"favoris"}
          size={"h-9 w-9 sm:h-12 sm:w-12 md:h-15 md:w-15"}
        />
      )}
      {isLiked && (
        <DecoIcon
          iconName={"full-heart"}
          altName={"favoris"}
          size={"h-9 w-9 sm:h-12 sm:w-12 h-15 w-15"}
        />
      )}
    </div>
  );
}

function RightArrowNextRecipe({ handleArrow }) {
  return (
    <button
      className={clsx(
        "absolute",
        "transform",
        "bottom-12 md:bottom-1/2",
        "right-0",
        "translate-x-0 md:-translate-x-5"
      )}
      testid="right_arrow"
      onClick={() => handleArrow("right")}
    >
      <img src={RightArrow} alt="bouton fermer modal" className="h-18 w-18 md:h-20 md:w-20" />
    </button>
  );
}

function LeftArrowPreviousRecipe({ handleArrow }) {
  return (
    <button
      className={clsx(
        "absolute",
        // "top:1/4  ",
        "bottom-12 md:bottom-1/2",
        "left-0",
        "transform",
        "translate-x-0 md:translate-x-5"
      )}
      testid="left_arrow"
      onClick={() => handleArrow("left")}
    >
      <img src={LeftArrow} alt="bouton fermer modal" className="h-18 w-18 md:h-20 md:w-20" />
    </button>
  );
}

export default ResumeRecipeModal;
