import { useMutation } from "react-query";
import { useDispatch } from "react-redux";
import { setExistingEmailRegister } from "../../redux/slices/loginDataSlice";
import useError from "../utils/useError";
import {
  setDisplayPopupEmailVerif,
  setExistingAuthorRegister,
} from "../../redux/slices/loginDataSlice";
import { register } from "../../functions/requests/axiosRequests";
import ErrorCode from "../../constants/httpErrorCodes";

function useRegisterMutation() {
  const dispatch = useDispatch();
  const { setError } = useError();

  const registerMutation = useMutation(register, {
    onSuccess: () => {
      dispatch(setExistingEmailRegister(false));
      dispatch(setExistingAuthorRegister(false));
      dispatch(setDisplayPopupEmailVerif(true));
    },
    onError: (err) => {
      if (err.response.data.status) {
        if (
          err.response.data.message ===
          ErrorCode.EMAIL_OR_USERNAME_ALREADY_EXISTS
        ) {
          if (err.response.data.emailExists) {
            dispatch(setExistingEmailRegister(true));
          } else {
            dispatch(setExistingEmailRegister(false));
          }
          if (err.response.data.authorExists) {
            dispatch(setExistingAuthorRegister(true));
          } else {
            dispatch(setExistingAuthorRegister(false));
          }
        } 
        else if (err.response.data.message ===
          ErrorCode.ACCOUNT_WAIT_CONFIRM){
            dispatch(setDisplayPopupEmailVerif(true));
        }
        else {
          setError(err.response.data.message);
        }
      } else {
        setError(err.response.data.message);
      }
    },
    mutationKey: "register",
  });

  return registerMutation;
}

export default useRegisterMutation;