import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { SectionCol } from "../../elements/sections/Sections";
import { DivCol, DivRow } from "../../elements/divs/Divs";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  SLICE_FILTERS_RECIPES,
  KEYS_FILTERS_RECIPES,
  LIST_FETCH,
} from "../../../redux/slices/filtersRecipesSlice";
import {
  SLICE_RECIPES,
  KEYS_RECIPES,
  setShowResumeRecipe,
  setRecipeShown,
  setImageRecipeShown,
  setRecipeSelected,
  setIsAllRecipesSelected,
  setScrollToBottom,
  setIndexRecipeShown,
} from "../../../redux/slices/recipesSlice";

import useRecipesQuery from "../../../hooks/requests/useRecipesQuery";
import CardRecipe from "../../elements/cards/cardRecipe/CardRecipe";
import clsx from "clsx";
import { H3 } from "../../elements/texts/Texts";
import useRecipesMutation from "../../../hooks/requests/useRecipesMutation";
import BouttonPressed from "../../elements/bouttons/BouttonPressed";
import Plus from "../../../assets/icons/deco_icons/icons8-plus.svg";
import useSubmitFilter from "../../../hooks/requests/useSubmitFilter";
import SuccessRecipesModal from "../../elements/modals/SuccessRecipesModal";
import ResumeRecipeModal from "../../elements/modals/ResumeRecipeModal";
import useHandleDisplayRecipe from "../../../hooks/utils/useHandleDisplayRecipe";
import useWindowWidth from "../../../hooks/utils/useWindowWidth";
import AddRecipesButton from "../../elements/bouttons/AddRecipesButton";
import Filters from "./Filters";
import { LoadingDiv } from "../../elements/loadings/Loadings";

function Recipes() {
  const divRecipesContentStyle = clsx(
    "w-full",
    "mb-18 pb-18 md:pt-16 md:mb-0",
    "flex flex-col items-center justify-center",
    "overflow-hidden"
  );

  const recipeShown = useSelector(
    (state) => state[SLICE_RECIPES][KEYS_RECIPES.RECIPE_SHOWN]
  );
  const indexRecipeShown = useSelector(
    (state) => state[SLICE_RECIPES][KEYS_RECIPES.INDEX_RECIPE_SHOWN]
  );
  const imageRecipeShown = useSelector(
    (state) => state[SLICE_RECIPES][KEYS_RECIPES.IMAGE_RECIPE_SHOWN]
  );

  const recipesFetched = useSelector(
    (state) => state[SLICE_RECIPES][KEYS_RECIPES.RECIPES_FETCHED],
    shallowEqual
  );
  const memoizedRecipesFetched = useMemo(
    () => recipesFetched,
    [recipesFetched]
  );

  const showResumeRecipe = useSelector(
    (state) => state[SLICE_RECIPES][KEYS_RECIPES.SHOW_RESUME_RECIPE]
  );

  const couvert = useSelector(
    (state) => state[SLICE_FILTERS_RECIPES][KEYS_FILTERS_RECIPES.COUVERT]
  );
  const repeat = useSelector(
    (state) => state[SLICE_FILTERS_RECIPES][KEYS_FILTERS_RECIPES.REPEAT]
  );

  const displaySuccess = useSelector(
    (state) => state[SLICE_RECIPES][KEYS_RECIPES.DISPLAY_SUCCESS]
  );

  const handleDisplayRecipe = useHandleDisplayRecipe(
    setRecipeShown,
    setIndexRecipeShown,
    setShowResumeRecipe,
    setImageRecipeShown
  );

  return (
    <SectionCol>
      <Filters />
      <div className={divRecipesContentStyle}>
        <RecipesContent
          recipesFetched={memoizedRecipesFetched}
        />
      </div>
      {showResumeRecipe && (
        <ResumeRecipeModal
          recipeShown={recipeShown}
          indexRecipeShown={indexRecipeShown}
          imageRecipeShown={imageRecipeShown}
          setShowResumeRecipe={setShowResumeRecipe}
          repeat={repeat.value}
          ButtonHandleActionOnRecipe={() => (
            <ButtonsChoice recipeShown={recipeShown} />
          )}
          handleDisplayRecipe={handleDisplayRecipe}
          recipesToDisplay={memoizedRecipesFetched}
          couvertWanted={couvert.value}
        />
      )}
      {displaySuccess && <SuccessRecipesModal repeat={repeat.value} />}
    </SectionCol>
  );
}

const RecipesContent = React.memo(function RecipesContent({
  recipesFetched,
}) {
  
  const recipesQuery = useRecipesQuery();
  const recipesMutation = useRecipesMutation();
  const recipesQueryDone = useSelector(
    (state) => state[SLICE_RECIPES][KEYS_RECIPES.RECIPES_QUERY_DONE]
    );
    
  const divH3Style = clsx(
    "w-full flex flex-col items-center justify-center pt-16"
  );

  return (
    <>
      {((recipesQueryDone && recipesMutation.isLoading) ||
        (!recipesQueryDone && recipesQuery.isLoading)) && (
        <div className={divH3Style}>
          <LoadingDiv text={"Recherche des recettes"} />
        </div>
      )}
      {((recipesQueryDone && recipesMutation.isError) ||
        (!recipesQueryDone && recipesQuery.isError)) && (
        <div className={divH3Style}>
          <H3>Erreur lors de la récupération des recettes</H3>
          <H3>Relancer une recherche</H3>
        </div>
      )}

      {recipesFetched.length > 0 && (
        <RecipesGroup
        mutation={recipesMutation.mutate}
        recipesFetched={recipesFetched}
      />
      )}

      { !recipesMutation.isLoading && !recipesQuery.isLoading && recipesFetched.length === 0 && (
        <div className={divH3Style}>
          <H3>Il n'y a pas de recettes pour la recherche</H3>
          <H3>Modifier vos filtres</H3>
        </div>
      )}
    </>
  );
});

function RecipesGroup({ mutation, recipesFetched }) {
  const scrollRef = useRef(null);
  const dispatch = useDispatch();

  const scrollToBottom = useSelector(
    (state) => state[SLICE_RECIPES][KEYS_RECIPES.SCROLL_TO_BOTTOM]
  );

  const submitFilters = useSubmitFilter(mutation);

  useEffect(() => {
    if (scrollToBottom) {
      scrollRef.current.scrollIntoView({ behavior: "smooth" });
      dispatch(setScrollToBottom(false));
    }
  }, [scrollToBottom, dispatch]);

  const handleListClick = useCallback(() => {
    const incrementOffset = true;
    dispatch(setScrollToBottom(true));
    submitFilters(incrementOffset);
  }, [submitFilters, dispatch]);

  return (
    <DivCol add={"w-full"}>
      <DivCol add={"w-full pt-2 md:pt-4"}>
        <CardsRecipes
          recipesFetched={recipesFetched}
        />
      </DivCol>
      <LoadMoreRecipesListButton handleListClick={handleListClick} />
      <div ref={scrollRef} />
    </DivCol>
  );
}

function LoadMoreRecipesListButton({ handleListClick }) {
  const typeOfFetch = useSelector(
    (state) => state[SLICE_FILTERS_RECIPES][KEYS_FILTERS_RECIPES.TYPE_OF_FETCH]
  );

  const isEndOfList = useSelector(
    (state) => state[SLICE_RECIPES][KEYS_RECIPES.IS_END_OF_LIST]
  );

  return (
    <>
      {typeOfFetch.value === LIST_FETCH && !isEndOfList && (
        <DivRow add={"pt-4 pb-4"}>
          <BouttonPressed srcImg={Plus} handleClick={handleListClick} />
        </DivRow>
      )}
    </>
  );
}

function CardsRecipes({ recipesFetched }) {
  const dispatch = useDispatch();
  const isMobile = useWindowWidth();

  const couvert = useSelector(
    (state) => state[SLICE_FILTERS_RECIPES][KEYS_FILTERS_RECIPES.COUVERT]
  );

  const handleDisplayRecipe = useCallback(
    (index, urlImage) => {
      dispatch(setRecipeShown(recipesFetched[index]));
      dispatch(setIndexRecipeShown(index));
      dispatch(setShowResumeRecipe(true));
      dispatch(setImageRecipeShown(urlImage));
    },
    [recipesFetched, dispatch]
  );

  const handleSelectionStatus = useCallback(
    (id) => {
      dispatch(setRecipeSelected(id));
      dispatch(setIsAllRecipesSelected(false));
    },
    [dispatch]
  );
  const divContainerStyle = clsx(
    "w-full",
    "flex flex-wrap justify-center items-center"
  );

  return (
    <div className={divContainerStyle}>
      {recipesFetched.map((recipe, index) => {
        return (
          <CardRecipe
            key={recipe._id}
            recipe={recipe}
            index={index}
            openCard={handleDisplayRecipe}
            couvert={couvert.value}
            changeRecipeSelectionStatus={handleSelectionStatus}
            isMobile={isMobile}
          />
        );
      })}
    </div>
  );
}

function ButtonsChoice({ recipeShown }) {
  return (
    <DivCol>
      {/* <ButtonSelectRecipe recipeShown={recipeShown} /> */}
      <AddRecipesButton recipeShown={recipeShown} />
    </DivCol>
  );
}

// function ButtonSelectRecipe({ recipeShown }) {
//   const dispatch = useDispatch();
//   const [isSelected, setIsSelected] = useState(recipeShown.selected);

//   useEffect(() => {
//     setIsSelected(recipeShown.selected);
//   }, [recipeShown.selected]);

//   function handleClick() {
//     setIsSelected(!isSelected);
//     dispatch(setRecipeSelected(recipeShown._id));
//   }

//   return (
//     <ButtonFill onClick={handleClick} add={"my-4"}>
//       {isSelected ? "Désélectionner" : "Sélectionner"}
//     </ButtonFill>
//   );
// }

export default Recipes;
