import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setStepFilled, INFOS_STEP } from "../../redux/slices/editRecipeSlice";

export function useIsInfosEditRecipeFilled(lengthOfRecipeName) {
  const dispatch = useDispatch();

  useEffect(() => {
    if (lengthOfRecipeName >= 8) {
      dispatch(setStepFilled({ stepIndex: INFOS_STEP, isFilled: true }));
    } else {
      dispatch(setStepFilled({ stepIndex: INFOS_STEP, isFilled: false }));
    }
  }, [lengthOfRecipeName, dispatch]);
}
