import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import {
  SLICE_FILTERS_RECIPES,
} from "../../redux/slices/filtersRecipesSlice";
import {
  setRecipesFetched,
  setNoRecipeFound,
} from "../../redux/slices/recipesSlice";
import { filtered_recipe } from "../../functions/requests/axiosRequests";
import useError from "../utils/useError";
import ErrorCode from "../../constants/httpErrorCodes";

function useRecipesQuery() {
  const dispatch = useDispatch();
  const { setError } = useError();

  const filtersRecipes = useSelector((state) => state[SLICE_FILTERS_RECIPES]);

  const recipesQuery = useQuery(
    "filtered_recipe",
    () =>
      filtered_recipe({
        recipeNameToSearch: filtersRecipes.recipeNameToSearch.value,
        couvert: filtersRecipes.couvert.value,
        type: filtersRecipes.type.value,
        regime: filtersRecipes.regime.value,
        complexity: filtersRecipes.complexity.value,
        totalTime: filtersRecipes.totalTime.value,
        offset: filtersRecipes.offsetToFetchRecipesForList.value,
        typeOfFetch: filtersRecipes.typeOfFetch.value,
        numberOfRecipe: filtersRecipes.numberOfRecipeToFetchInRandom.value,
        ingredients: filtersRecipes.ingredients.value,
        seasons:
          filtersRecipes.seasons.value.length === 0
            ? ["printemps", "ete", "automne", "hiver"]
            : filtersRecipes.seasons.value,
      }),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      cacheTime: 60000,
      staleTime: Infinity,
      retry: 0,
      onSuccess: (data) => {
        dispatch(
          setRecipesFetched(
            data.recipes.map((recipe) => ({
              ...recipe,
              selected: false,
            }))
          )
        );
      },
      onError: (error) => {
        if (error.response?.data?.message === ErrorCode.GET_RECIPES_FAILED) {
          dispatch(setNoRecipeFound(true));
        } else {
          setError(
            error.response?.data?.message || "Une erreur s'est produite"
          );
        }
      },
    }
  );

  return recipesQuery;
}

export default useRecipesQuery;
