import { configureStore } from '@reduxjs/toolkit';
import errorsReducer from './slices/errorsSlice';
import recipesToCookReducer from './slices/recipesToCookSlice';
import filtersRecipesReducer from './slices/filtersRecipesSlice';
import recipesReducer from './slices/recipesSlice';
import loginDataReducer from './slices/loginDataSlice';
import userDataReducer from './slices/userDataSlice';
import resetPwdDataReducer from './slices/resetPwdSlice';
import profilePageReducer from './slices/profilePageSlice';
import editRecipeReducer from './slices/editRecipeSlice';
import ownRecipesReducer from './slices/ownRecipesSlice';
import favoriteRecipesReducer from './slices/favoriteRecipesSlice';
import tempRecipesReducer from './slices/tempRecipesSlice';

const store = configureStore({
  reducer: {
    errorsApp: errorsReducer,
    loginData: loginDataReducer,
    userData: userDataReducer,
    resetPwdData: resetPwdDataReducer,
    filtersRecipes: filtersRecipesReducer,
    recipes: recipesReducer,
    recipesToCook: recipesToCookReducer,
    profilePage: profilePageReducer,
    editRecipe: editRecipeReducer,
    ownRecipes: ownRecipesReducer,
    favoriteRecipes: favoriteRecipesReducer,
    tempRecipes: tempRecipesReducer,
  },
});

export default store;
