import { useMutation } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import {
  SLICE_FILTERS_RECIPES,
  KEYS_FILTERS_RECIPES,
  LIST_FETCH,
  setOffsetToFetchRecipesForList,
} from "../../redux/slices/filtersRecipesSlice";
import {
  RECIPES_DISPLAYED,
  setRecipesFetched,
  setAddRecipesToRecipesFetched,
  setNoRecipeFound,
  setAllRecipesDeselected,
  setWhoIsDisplay,
  setRecipesQueryDone,
  setIsEndOfList,
} from "../../redux/slices/recipesSlice";
import { filtered_recipe } from "../../functions/requests/axiosRequests";
import useError from "../utils/useError";
import ErrorCode from "../../constants/httpErrorCodes";
import { OFFSET_LIST_RECIPE, OFFSET_LIST_RECIPE_RESET } from "../../constants/recipe";

function useRecipesMutation() {
  const dispatch = useDispatch();
  const { setError } = useError();

  const { typeOfFetch } = useSelector((state) => ({
    typeOfFetch:
      state[SLICE_FILTERS_RECIPES][KEYS_FILTERS_RECIPES.TYPE_OF_FETCH],
  }));
  
  

  const recipesMutation = useMutation(
    filtered_recipe,
    {
      onSuccess: (data) => {
        if (typeOfFetch.value === LIST_FETCH) {
          if (data.offsetList > 0) {
            dispatch(
              setAddRecipesToRecipesFetched(
                data.recipes.map((recipe) => ({
                  ...recipe,
                  selected: false,
                }))
              )
            );
            if(data.recipes.length < OFFSET_LIST_RECIPE ) {
              dispatch(setIsEndOfList(true));
            }
          } else {
            dispatch(
              setRecipesFetched(
                data.recipes.map((recipe) => ({
                  ...recipe,
                  selected: false,
                }))
              )
            );
            if(data.recipes.length < OFFSET_LIST_RECIPE ) {
              dispatch(setIsEndOfList(true));
            }
            else {
              dispatch(setOffsetToFetchRecipesForList(OFFSET_LIST_RECIPE));
              dispatch(setIsEndOfList(false));
            }
            dispatch(setAllRecipesDeselected(false));
          }
          dispatch(setNoRecipeFound(false));
        } else {
          dispatch(
            setRecipesFetched(
              data.recipes.map((recipe) => ({
                ...recipe,
                selected: false,
              }))
            )
          );
          dispatch(setOffsetToFetchRecipesForList(OFFSET_LIST_RECIPE_RESET));
          dispatch(setAllRecipesDeselected(false));
        }
        dispatch(setRecipesQueryDone());
        dispatch(setWhoIsDisplay(RECIPES_DISPLAYED));
      },
      onError: (error) => {
        if (error.response?.data?.message === ErrorCode.GET_RECIPES_FAILED) {
          dispatch(setNoRecipeFound(true));
        } else {
          setError(
            error.response?.data?.message || "Une erreur s'est produite"
          );
        }
      },
    }
  );

  return recipesMutation;
}

export default useRecipesMutation;
