import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  ownRecipesFetched: [],
  showResumeRecipe: false,
  recipeShown: null,
  indexRecipeShown: 0,
  imageRecipeShown: null,
  displaySuccess: false,
  fileNameRecipeShown: null,
  imageNameUpdatedFromBackend: null,
  type: {
    // title: "Type",
    name: "type",
    value: "plat",
    // logo: "icons8-tapas",
    data: [
      {
        name: "type",
        value: "entree",
        label: "Entrée",
        isDefault: false,
        logo: "icons8-greek-salad",
      },
      {
        name: "type",
        value: "plat",
        label: "Plat",
        isDefault: true,
        logo: "icons8-paella",
      },
      {
        name: "type",
        value: "dessert",
        label: "Dessert",
        isDefault: false,
        logo: "icons8-banana-split",
      },
    ],
  },
}

export const STARTERS = "entree";
export const MEALS = "plat";
export const DESSERTS = "dessert";

export const SLICE_OWN_RECIPES = "ownRecipes";
export const KEYS_OWN_RECIPES = {
  OWN_RECIPES_FETCHED: "ownRecipesFetched",
  SHOW_RESUME_RECIPE: "showResumeRecipe",
  RECIPE_SHOWN: "recipeShown",
  INDEX_RECIPE_SHOWN: "indexRecipeShown",
  IMAGE_RECIPE_SHOWN: "imageRecipeShown",
  DISPLAY_SUCCESS: "displaySuccess",
  IMAGE_NAME_UPDATED_FROM_BACKEND: "imageNameUpdatedFromBackend",
  TYPE: "type",
};

const ownRecipesSlice = createSlice({
  name: SLICE_OWN_RECIPES,
  initialState,
  reducers: {
    setOwnRecipesFetched: (state, action) => {
      state.ownRecipesFetched = action.payload;
    },
    setRecipeSelected: (state, action) => {
      const recipeId = action.payload;
      state.ownRecipesFetched = state.ownRecipesFetched.map(recipe => {
        if (recipe._id === recipeId) {
          return {...recipe, selected: !recipe.selected};
        }
        return recipe;
      });
    },
    setAllRecipesSelected: (state, action) => {
      state.ownRecipesFetched = state.ownRecipesFetched.map((recipe) => ({
        ...recipe,
        selected: true,
      }));
    },
    setAllRecipesDeselected: (state, action) => {
      state.ownRecipesFetched = state.ownRecipesFetched.map((recipe) => ({
        ...recipe,
        selected: false,
      }));
    },
    setShowResumeRecipe: (state, action) => {
      state.showResumeRecipe = action.payload;
    },
    setRecipeShown: (state, action) => {
      state.recipeShown = action.payload;
    },
    setIndexRecipeShown: (state, action) => {
      state.indexRecipeShown = action.payload;
    },
    setImageRecipeShown: (state, action) => {
      state.imageRecipeShown = action.payload;
    },
    setDisplaySuccess: (state, action) => {
      state.displaySuccess = action.payload;
    },
    setImageNameUpdatedFromBackend: (state, action) => {
      state.imageNameUpdatedFromBackend = action.payload;
    },
    setFileNameRecipeShown: (state, action) => {
      state.recipeShown.imageRecipe = action.payload;
    },
    setRecipeLiked: (state, action) => {
      const recipeId = action.payload;
      state.ownRecipesFetched = state.ownRecipesFetched.map(recipe => {
        if (recipe._id === recipeId) {
          return {...recipe, isLiked: !recipe.isLiked};
        }
        return recipe;
      });
    },
    setType: (state, action) => {
      state.type.value = action.payload;
    },
  },
});

export const {
  setOwnRecipesFetched,
  setRecipeSelected,
  setAllRecipesSelected,
  setAllRecipesDeselected,
  setShowResumeRecipe,
  setRecipeShown,
  setIndexRecipeShown,
  setImageRecipeShown,
  setDisplaySuccess,
  setImageNameUpdatedFromBackend,
  setFileNameRecipeShown,
  setRecipeLiked,
  setType,
} = ownRecipesSlice.actions;

export default ownRecipesSlice.reducer;