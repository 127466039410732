import React, { useEffect } from "react";
import GlobalModal from "./GlobalModal";
import { DivCol } from "../divs/Divs";
import ReactConfetti from "react-confetti";
import { H1, H2 } from "../texts/Texts";
import CatApprouve from "../../../assets/images/cat_approuve.png";
import clsx from "clsx";
import { useDispatch } from "react-redux";
import { useQueryClient } from "react-query";
import { setDisplaySuccess } from "../../../redux/slices/recipesSlice";

function SuccessRecipesModal({repeat=0}) {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  useEffect(() => {
    setTimeout(() => {
      queryClient.invalidateQueries('get_recipes_to_cook')
      dispatch(setDisplaySuccess(false));
    }, 1500);
  }, [dispatch, queryClient]);

  const divSuccessStyle = clsx(
    "relative",
    "bg-lime-100",
    "mt-14",
    "w-4/5",
    "h-4/5",
    "rounded-lg",
    "drop-shadow-3xl",
    "px-4",
    "lg:pb-0",
    "overflow-y-scroll"
  );

  return (
    <GlobalModal>
      <DivCol add={divSuccessStyle} testid="modal_recipe_success">
        <ContentSuccessRecipesModal repeat={repeat}/>
        <ReactConfetti className="w-full h-full absolute" />
      </DivCol>
    </GlobalModal>
  );
}

function ContentSuccessRecipesModal({repeat}) {
  const repeatText = repeat > 0 ? ` ${repeat+1} fois` : "";
  return (
    <DivCol add="transform -translate-y-16">
      <H1 add="text-center">Recettes ajoutées{repeatText}</H1>
      <img className="h-56 w-56" src={CatApprouve} alt="Cat approuve" />
      <H2 add="text-center">Cuisinez bien !</H2>
    </DivCol>
  );
}

export default SuccessRecipesModal;
