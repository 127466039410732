import React, { useCallback, useState } from "react";
import { H3 } from "../texts/Texts";
import clsx from "clsx";
import MobileDetect from "mobile-detect";
import { DivCol, DivRow } from "../divs/Divs";
import { Li } from "../lists/Lists";
import { useDispatch } from "react-redux";
import { DecoIcon } from "../images/IconImage";

const md = new MobileDetect(window.navigator.userAgent);
const isMobile = md.mobile() !== null; // Vérifie si l'utilisateur est sur un périphérique mobile
const isPhone = md.phone() !== null; // Vérifie si l'utilisateur est sur un téléphone mobile

const Radio = ({ item, effect, handlerSelected, effectMobile, stateValue, numberOfItem }) => {
  const randomBoolean = Math.random() >= 0.5;
  const liContainerStyle = clsx(
    // `w-1/${numberOfItem}`,
    // "w-auto"
    "w-24 sm:w-28 md:w-24 lg:w-32 xl:w-40 2xl:w-44"
  )

  return (
    // <Li add="w-36 sm:w-40">
            <Li add={liContainerStyle}>
      <input
        type="radio"
        id={item.value}
        value={item.value}
        defaultChecked={stateValue === item.value}
        className="peer hidden [&:checked_+_label_img]:block"
        testid={item.value}
      />
      <label
        htmlFor={item.value}
        className={clsx(
          "flex items-center justify-center",
          "cursor-pointer",
          "rounded",
          "px-2 py-3 lg:px-4 lg:py-3",
          "border",
          "text-xs lg:text-sm",
          "font-mitr text-white",
          "hover:border-gray-200",
          isMobile || isPhone ? "" : "transition hover:scale-110",
          isMobile || isPhone
            ? ""
            : randomBoolean
            ? "hover:-rotate-2"
            : "hover:rotate-2",
          stateValue === item.value ? "border-blue-50" : "border-gray-100",
          stateValue === item.value ? "text-lime-50" : "text-sky-600",
          stateValue === item.value ? "bg-sky-600" : "bg-sky-300",
          (isMobile || isPhone) && stateValue === item.value && effect
            ? "animate-scaling"
            : ""
        )}
        onClick={() => {
          effectMobile();
          handlerSelected(item.value);
        }}
        onAnimationEnd={() => effectMobile()}
        testid={`label_${item.value}`}
      >
        <DecoIcon iconName={item.logo} altName={item.value} size={"h-6 w-6 lg:h-7 lg:w-7"}/>
        {item.label}
      </label>
    </Li>
  );
};

export const RadioInput = React.memo(({ items, setter, stateValue }) => {
  const [effect, setEffect] = useState(false);

  const handlerSelected = (value) => {
    setter( value);
  };

  const effectMobile = () => {
    if (isMobile || isPhone) {
      setEffect(!effect);
    }
  };

  const ulContainerStyle = clsx(
    items.title ? "pt-4" : "",
    "space-y-0 space-x-2 ",
    "sm:space-x-4 sm:space-y-0",
    "md:space-x-2 lg:space-x-2",
    "flex flex-row",
    "justify-center items-center",
  )

  const divContainerStyle = clsx(
    "w-full",
    items.title ? "pt-4" : "",
  )

  return (
    <DivCol add={divContainerStyle}>
      <DivRow add="space-x-2">
        {items.logo && (
          <DecoIcon iconName={items.logo} altName={items.name} 
          size={"h-4 w-4 md:h-6 md:w-6 lg:h-8 lg:w-8"}
          />
        )}
        {items.title && <H3 size={"text-lg md:text-lg lg:text-lg"}>{items.title}</H3>}
      </DivRow>

      <ul className={ulContainerStyle}>
        {items.data.map((item) => {
          return (
            <Radio
              key={item.value}
              item={item}
              effect={effect}
              handlerSelected={handlerSelected}
              effectMobile={effectMobile}
              stateValue={stateValue}
              numberOfItem={items.data.length}
            />
          );
        })}
      </ul>
    </DivCol>
  );
});

export const RadioInputStore = React.memo(({ state, action }) => {
  const dispatch = useDispatch();
  
  const onChange = useCallback((value) => {
    dispatch(action(value));
  }, [dispatch, action]);

  return (
    <RadioInput
      items={state}
      setter={onChange}
      stateValue={state.value}
    />
  );
}
);