import { createContext, useReducer } from "react";


const initialStates = {
  email: "",
  author:"",
  avatar: "icons8-chat-1",
};

const reducers = (state, action) => {
  switch (action.type) {
    case "SET_USER":
      return { ...state, 
        email: action.payload.email ?? state.email, 
        author: action.payload.author ?? state.author, 
        avatar: action.payload.avatar ?? state.avatar
      };
    case "LOGOUT":
      return { ...state, email: "", author:"", avatar: "icons8-chat-1" };
    default:
      return state;
  }
};

const AuthProvider = ({ children }) => {
  const [stateAuth, dispatchAuth] = useReducer(reducers, initialStates);

    return (
        <AuthContext.Provider value={[ stateAuth, dispatchAuth ]}>
            {children}
        </AuthContext.Provider>
    )
}
export const AuthContext = createContext(initialStates);

export default AuthProvider;