import { useSelector } from 'react-redux'
import {setAuthorAuth,
  KEYS_LOGIN_DATA, SLICE_LOGIN_DATA
} from '../../../redux/slices/loginDataSlice';
import { Spans, Span } from '../spans/Spans';
import FieldText from './FieldText';


export function AuthorRegisterField() {
  const { author, existingAuthorRegister } = useSelector(
    (state) => ({
      author: state[SLICE_LOGIN_DATA][KEYS_LOGIN_DATA.AUTHOR],
      existingAuthorRegister: state[SLICE_LOGIN_DATA][KEYS_LOGIN_DATA.EXISTING_AUTHOR_REGISTER]
    })
  );

  const fieldIsOk = author.value.length >= 3;

  return (
    <>
    <FieldText state={author} action={setAuthorAuth} fieldIsOk={fieldIsOk} />
      <Spans>
        { existingAuthorRegister && <Span>Ce pseudonyme n'est pas disponible</Span>}
      </Spans>
    </>
  );
}