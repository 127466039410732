import React from 'react'
import { DecoIcon } from '../../images/IconImage';
import clsx from 'clsx';
import useHandleFavoritesRecipes from '../../../../hooks/utils/useHandleFavoritesRecipes';

function FavorisCardRecipe({ id, isLiked }) {
  const handleChangeLikeStatus = useHandleFavoritesRecipes();
  
  function handleClick(event) {
    event.stopPropagation();
    handleChangeLikeStatus(id, isLiked);
  }

  const divContainerStyle = clsx(
    "static md:absolute md:top-1 md:right-2",
    "cursor-pointer",
    "hover:animate-heart-beat",
  )

  return (
    <div
      className={divContainerStyle}
      onClick={handleClick}
    >
        <DecoIcon
          iconName={isLiked ? "full-heart" : "empty-heart"}
          altName={"favoris"}
          size={"h-8 w-8 md:h-12 md:w-12"}
        />
    </div>
  );
}

export default React.memo(FavorisCardRecipe)