import { useContext, useCallback } from "react";
import { AuthContext } from "../../context/AuthProvider";

const useAuth = () => {
  const [stateAuth, dispatchAuth] = useContext(AuthContext);
  const { email, author, avatar } = stateAuth;

  const setUser = useCallback(
    (user) => {
      dispatchAuth({ type: "SET_USER", payload: user });
    },
    [dispatchAuth]
  );

  const setLogoutDelete = useCallback(() => {
    dispatchAuth({ type: "LOGOUT" });
  }, [dispatchAuth]);

  return { email, author, avatar, setUser, setLogoutDelete };
};

export default useAuth;
