import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { add_recipes_to_cook } from '../../functions/requests/axiosRequests';
import { setAllRecipesDeselected, setDisplaySuccess, setIsAllRecipesSelected } from '../../redux/slices/recipesSlice';
import useError from '../utils/useError';

function useAddRecipesToCookMutation() {
  const dispatch = useDispatch();
  const {setError} = useError();
  
  const addRecipesToCookMutation = useMutation("add_recipes_to_cook", add_recipes_to_cook, {
    onSuccess: (data) => {
      dispatch(setAllRecipesDeselected())
      dispatch(setDisplaySuccess(true))
      dispatch(setIsAllRecipesSelected(false))
    },
    onError: (error) => {
      setError(error.response?.data?.message || "Une erreur s'est produite");
    }
  })

  return addRecipesToCookMutation
}

export default useAddRecipesToCookMutation