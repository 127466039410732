import React from "react";
import { DivCol } from "../../elements/divs/Divs";
import { H2, P } from "../../elements/texts/Texts";
import Accordion from "../../elements/accordion/Accordion";
import clsx from "clsx";

function EtapesRecipes({ value, add }) {
  const divEtapesStyle = clsx(
    add ? add : "",
  )
  
  return (
    <>
    <H2 size={"text-2xl"}>Etapes</H2>
    {/* <UlCol>
      {value.map((etape, index) => {
        return (
          <Li key={index}>
            <DivCol>
              <H3 testid={`etape_${index+1}`}>Etape {index+1}</H3>
            </DivCol>
          </Li>
        );
      })}
    </UlCol> */}
    <DivCol ju={"justify-start"} it={"items-start"} add={divEtapesStyle}>
    {value.map((etape, index) => {
      return (
        <Accordion key={index} index={index} title={`Etape ${index+1}`} >
            <P testid={`content_etape_${index+1}`}>{etape}</P>
          </Accordion>
      );
    })}
    </DivCol>
    </>
  );
}

export default EtapesRecipes;
