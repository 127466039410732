import { useDispatch } from "react-redux";
import useFilterFood from "../../../hooks/utils/useFilterFood";
import { useCallback, useEffect, useState } from "react";
import { setAddIngredient } from "../../../redux/slices/filtersRecipesSlice";
import { TextInput } from "../inputs/TextInputs";
import FoodsFoundListModal from "../modals/FoodListModal";
import clsx from "clsx";
import { H3 } from "../texts/Texts";
import { DecoIcon } from "../images/IconImage";

function FieldSearchFood({ ingredients }) {
  const dispatch = useDispatch();

  const textInputSearchFoodData = {
    title: "Aliments",
    name: "searchFood",
    placeholder: "Rechercher un aliment",
    logo: require("../../../assets/icons/deco_icons/icons8-foods.svg").default,
  };

  const [searchingFoodName, setSearchingFoodName] = useState("");
  const [foodsFound, setFoodsFound] = useState([]);

  function updateSearchingFoodName(value) {
    setSearchingFoodName(value);
  }

  const filteredFoodFound = useFilterFood(searchingFoodName, ingredients);

  const addFoodFoundOnClick = useCallback(
    (food) => {
      const newIngredient = {
        ...food,
        isExclude: false,
      };
      dispatch(setAddIngredient(newIngredient));
      setSearchingFoodName("");
    },
    [dispatch, setSearchingFoodName]
  );

  useEffect(() => {
    if (searchingFoodName.length >= 3) {
      setFoodsFound(filteredFoodFound);
    }
  }, [filteredFoodFound, searchingFoodName]);

  const divContainerStyle = clsx(
    "flex flex-col w-full items-center justify-center space-y-4"
  );

  return (
    <div className={divContainerStyle}>
      <TitleField />
      <label className="relative w-full block">
        <TextInput
          item={textInputSearchFoodData}
          value={searchingFoodName}
          onTextChange={updateSearchingFoodName}
        />
        {searchingFoodName.length >= 3 && (
          <FoodsFoundListModal
            selectFood={addFoodFoundOnClick}
            foodsFound={foodsFound}
          />
        )}
      </label>
    </div>
  );
}

function TitleField() {
  const divContainer = clsx(
    "flex flex-row",
    "justify-center items-center",
    "w-full",
    "space-x-2"
  );

  return (
    <div className={divContainer}>
      <DecoIcon iconName={"icons8-foods"} altName={"icon_food"} />
      <H3>Recherche ingrédient</H3>
    </div>
  );
}

export default FieldSearchFood;
