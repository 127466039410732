import { useState } from "react";
import { H3 } from "../texts/Texts";
import clsx from "clsx";

const Accordion = ({ title, index, children, opened=false }) => {
  const [isOpen, setIsOpen] = useState(opened);

  const divAccordionContainerStyle = clsx(
    "w-full",
    "max-w-md",
    "mx-auto",
    "pt-1",
    "pb-1",
  )

  const divTitleStyle = clsx(
    "bg-lime-200",
    "p-3",
    "cursor-pointer",
    "flex",
    "justify-between",
    "items-center",
  )

const divContentStyle = clsx(
    "bg-lime-50",
    "border-b",
    "border-gray-200"
  )

  return (
    <div className={divAccordionContainerStyle}>
      <div className={divContentStyle}>
        <div
          className={divTitleStyle}
          onClick={() => setIsOpen(!isOpen)}
        >
          <H3 testid={`etape_${index+1}`}>Etape {index+1}</H3>
        </div>
        <div className="p-3">{children}</div>
      </div>
    </div>
  );
};

export default Accordion;