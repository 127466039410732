import DotsImgAnimation from '../animations/DotsImgAnimation'
import GlobalModal from './GlobalModal'
import { H2 } from '../texts/Texts'
import { DivCol } from '../divs/Divs'


const LoadingModal = ({title}) => {
  const modalTitle = title ? title : "Chargement";
  return (
    <GlobalModal>
      <DivCol add="space-y-8">
      <H2>{modalTitle}</H2>
      <DotsImgAnimation />
      </DivCol>
    </GlobalModal>
  )
}

export default LoadingModal