import React from 'react'
import { ListCheckBoxesInputStore } from '../inputs/CheckBoxInputs'
import { useSelector } from 'react-redux'
import { setSeasons } from '../../../redux/slices/filtersRecipesSlice';

function FieldSeasonOfRecipe() {
  const seasons = useSelector((state) => state.filtersRecipes.seasons);

  return (
    <ListCheckBoxesInputStore state={seasons} action={setSeasons} />
  )
}

export default React.memo(FieldSeasonOfRecipe)