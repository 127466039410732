import React, { useCallback, useEffect, useRef, useState } from "react";
import { DivCol, DivColRow, DivRow } from "../../elements/divs/Divs";
import { H2, H3 } from "../../elements/texts/Texts";
import { Li, UlCol } from "../../elements/lists/Lists";
import { useDispatch, useSelector } from "react-redux";
import {
  SLICE_EDIT_RECIPE,
  KEYS_EDIT_RECIPE,
  setAddEtape,
  setUpdateEtape,
  setRemoveEtape,
} from "../../../redux/slices/editRecipeSlice";
import { ReducerTextAreaInput } from "../../elements/inputs/TextInputs";
import BouttonPressed from "../../elements/bouttons/BouttonPressed";
import Plus from "../../../assets/icons/deco_icons/icons8-plus.svg";
import useIsEtapesEditRecipeFilled from "../../../hooks/utils/useIsEtapesEditRecipeFilled";
import ValidationFieldImage from "../../elements/images/ValidationFieldImage";

function EtapesEditRecipe() {
  const scrollRef = useRef(null);
  const [stepAdded, setStepAdded] = useState(false);

  const stepAddedToTrue = useCallback(() => {
    setStepAdded(true);
  }, [setStepAdded]);

  function scrollToBottom() {
    scrollRef.current.scrollIntoView({ behavior: "smooth" });
  }

  useEffect(() => {
    if (stepAdded) {
      scrollToBottom();
      setStepAdded(false);
    }
  }, [setStepAdded, stepAdded]);

  return (
    <>
      <DivCol add="w-full sm:w-3/4 md:w-2/3 lg:w-1/2">
        <H2 add="text-center">Étapes</H2>
        <GroupOfSteps />
        <AddStepButton stepAddedToTrue={stepAddedToTrue} />
        <div ref={scrollRef} />
      </DivCol>
    </>
  );
}

function GroupOfSteps() {
  const { etapes } = useSelector((state) => ({
    etapes: state[SLICE_EDIT_RECIPE][KEYS_EDIT_RECIPE.ETAPES],
  }));

  useIsEtapesEditRecipeFilled(etapes.value);

  return (
    <UlCol add="w-full mt-2 pb-3 pr-2" testid="stepsGroup">
      {etapes.value.map((etape, index) => (
        <Step key={`step_recipe_${index + 1}`} index={index} etape={etape} />
      ))}
    </UlCol>
  );
}

const Step = React.memo(function Step({ index, etape }) {
  const fieldIsOk = etape.length >= 10 ? true : false;

  return (
    <Li add="w-full py-2 pl-2" testid={`step_recipe_${index + 1}`}>
      <DivRow ju={"no"}>
        <H3 add="pl-1">Étape {index + 1}</H3>
        <ValidationFieldImage fieldIsOk={fieldIsOk} />
      </DivRow>
      <DivColRow>
        <TextareaStep index={index} etape={etape} />
        <RemoveStepButton index={index} />
      </DivColRow>
    </Li>
  );
});

function TextareaStep({ index, etape }) {
  const dispatch = useDispatch();

  function handleStepChange(index, value) {
    dispatch(setUpdateEtape({ index: index, newEtape: value }));
  }

  return (
    <ReducerTextAreaInput
      step={etape}
      onTextAreaChange={handleStepChange}
      index={index}
      testid={`textarea_step_${index + 1}`}
    />
  );
}

const RemoveStepButton = React.memo(function RemoveStepButton({ index }) {
  const dispatch = useDispatch();

  function onRemoveStep() {
    dispatch(setRemoveEtape(index));
  }

  return (
    <button
      onClick={onRemoveStep}
      className={"ml-0 mt-2 sm:ml-2 sm:mt-0"}
      testid={`delete_step_${index + 1}`}
    >
      <RemoveIcon index={index} />
    </button>
  );
});

const RemoveIcon = React.memo(function RemoveIcon({ index }) {
  return (
    <img
      src={
        require("../../../assets/icons/deco_icons/icons8-remove.svg").default
      }
      alt={`delete_step_${index + 1}`}
      className="h-10 w-10"
    />
  );
});

const AddStepButton = React.memo(function AddStepButton({ stepAddedToTrue }) {
  const dispatch = useDispatch();

  function addNewStep() {
    dispatch(setAddEtape());
    stepAddedToTrue();
  }

  return (
    <BouttonPressed handleClick={addNewStep} srcImg={Plus} testid="add_step" />
  );
});

export default EtapesEditRecipe;
