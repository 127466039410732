import React from "react";
import { Link } from "react-router-dom";
import useRegisterMutation from "../../hooks/requests/useRegisterMutation";
import LoadingModal from "../elements/modals/LoadingModal";
import { SectionCol } from "../elements/sections/Sections";
import { H1 } from "../elements/texts/Texts";
import { useSelector } from "react-redux";
import {
  SLICE_LOGIN_DATA,
  KEYS_LOGIN_DATA,
} from "../../redux/slices/loginDataSlice";
import EmailVerificationModal from "../elements/modals/EmailVerificationModal";
import { FormRegister } from "../elements/forms/Forms"
import { AuthEmailFieldAndError } from "../elements/fields/EmailFields";
import {
  AuthCpwdFieldAndError,
  AuthPwdFieldAndError,
} from "../elements/fields/PwdFields";
import { AuthorRegisterField } from "../elements/fields/AuthorRegisterField";
import { DivColRowRev } from "../elements/divs/Divs";
import { LOGIN } from "../../constants/routes";
import clsx from "clsx";
import { ButtonFill } from "../elements/bouttons/Buttons";
import useAuthentFieldsOk from "../../hooks/utils/useAuthentFieldsOk";

function Register() {
  const registerMutation = useRegisterMutation();

  return (
    <>
      {registerMutation.isLoading && <LoadingModal />}
      <RegisterContent registerPostRequest={registerMutation.mutate} />
    </>
  );
}

function RegisterContent({ registerPostRequest }) {
  const { email, author, pwd, displayPopupEmailVerif } = useSelector(
    (state) => ({
      email: state[SLICE_LOGIN_DATA][KEYS_LOGIN_DATA.EMAIL],
      author: state[SLICE_LOGIN_DATA][KEYS_LOGIN_DATA.AUTHOR],
      pwd: state[SLICE_LOGIN_DATA][KEYS_LOGIN_DATA.PWD],
      displayPopupEmailVerif:
        state[SLICE_LOGIN_DATA][KEYS_LOGIN_DATA.DISPLAY_POPUP_EMAIL_VERIF],
    })
  );

  async function onSubmit() {
    registerPostRequest({
      email: email.value,
      author: author.value,
      pwd: pwd.value,
    });
  }

  return (
    <>
      {displayPopupEmailVerif && (
        <EmailVerificationModal data={{ email: email.value, pwd: pwd.value }} />
      )}

      <SectionCol add="pt-12 px-4">
        <H1 add="text-center">Qui est ce nouveau cuisinier ?</H1>
        <FormRegister>
          <FormContent onSubmit={onSubmit} />
        </FormRegister>
      </SectionCol>
    </>
  );
}

function FormContent({ onSubmit }) {
  return (
    <>
      <AuthEmailFieldAndError />
      <AuthorRegisterField />
      <AuthPwdFieldAndError />
      <AuthCpwdFieldAndError />
      <RedirectLinksAndSubmitButton handleSubmit={onSubmit} />
    </>
  );
}


function RedirectLinksAndSubmitButton({ handleSubmit }) {
  return (
    <DivColRowRev ju={"justify-between"}>
      <RedirectLogin />
      <ButtonRegister handleSubmit={handleSubmit} />
    </DivColRowRev>
  );
}

function RedirectLogin() {
  const pLinkStyle = clsx(
    "text-gray-500",
    "font-normal",
    "text-sm sm:text-base",
    "font-spin",
    "py-4 sm:py-0"
  );

  return (
    <p className={pLinkStyle}>
      Déjà inscrit ?{" "}
      <Link className="underline" to={LOGIN.route}>
        {LOGIN.name}
      </Link>
    </p>
  );
}

function ButtonRegister({ handleSubmit }) {
  const { registerFormOk } = useAuthentFieldsOk();

  return (
    <ButtonFill
      type="submit"
      onClick={handleSubmit}
      testid="inscription"
      disabled={!registerFormOk}
    >
      Inscription
    </ButtonFill>
  );
}

export default Register;
