import clsx from "clsx";
import { DivCol, DivRow } from "../divs/Divs";
import { QtyInputNumber } from "../inputs/ButtonNumberInput";
// import ButtonNumberInput from "../inputs/ButtonNumberInput";
import { H3 } from "../texts/Texts";
import { useLocation } from "react-router-dom";
import { RECETTES, EDITER } from "../../../constants/routes";
import SwitchSpan from "../spans/SwitchSpan";
import { useDispatch } from "react-redux";

export const UlRow = ({ it, ju, add, children }) => {
  const sUl = clsx(
    "flex",
    "flex-row",
    "list-none",
    it === "no" ? "" : it ? it : "items-center",
    ju === "no" ? "" : ju ? ju : "justify-center",
    add ? add : ""
  );

  return <ul className={sUl}>{children}</ul>;
};

export const UlCol = ({ it, ju, add, children, testid }) => {
  const sUl = clsx(
    "flex",
    "flex-col",
    "list-none",
    it === "no" ? "" : it ? it : "items-center",
    ju === "no" ? "" : ju ? ju : "justify-center",
    add ? add : ""
  );

  return <ul className={sUl} testid={testid}>{children}</ul>;
};

export const UlColRow = ({ it, ju, add, children }) => {
  const sUl = clsx(
    "flex",
    "flex-col sm:flex-row",
    "list-none",
    it === "no" ? "" : it ? it : "items-center",
    ju === "no" ? "" : ju ? ju : "justify-center",
    add ? add : ""
  );

  return <ul className={sUl}>{children}</ul>;
};

export const UlGrid = ({ add, children, cln = 1 }) => {
  const sUl = clsx(
    "grid",
    cln === 1 ? "grid-cols-1 sm:grid-cols-3" : "grid-cols-2 sm:grid-cols-4",
    "list-none",
    "gap-4",
    add ? add : ""
  );

  return <ul className={sUl}>{children}</ul>;
};

export const Li = ({ onClick, add, children, testid }) => {
  const sLi = clsx(add ? add : "");

  return (
    <li onClick={onClick} className={sLi} testid={testid}>
      {children}
    </li>
  );
};

export const LiIngredient = ({
  foodObject,
  onRemoveIngredient,
  add,
  onValueChange,
  index,
}) => {
  // importer le pathanme avec useLocation
  const location = useLocation();
  const pathname = location.pathname;
  return (
    <Li add={add} testid={`ingredient_${foodObject.name}`}>
      <DivRow add="relative" ju={"justify-start"}>
        <DivCol it={"items-start"} add="w-full space-y-4">
          <DivRow>
            <img
              src={require(`../../../assets/icons/food_icons/${foodObject.icon}.svg`)}
              alt={foodObject.name}
              className="w-10 h-10 rounded-full"
            />
            <H3 add="text-center">{foodObject.name}</H3>
          </DivRow>
          <DivCol add="w-full" it={"items-center"}>
            {pathname === EDITER.route && (
              <QtyInputNumber
                onValueChange={onValueChange}
                foodObject={foodObject}
                index={index}
              />
            )}
            {pathname === RECETTES.route && (
              // composant toggle por faire changer isExclude entre true et false
              <SwitchSpan onChange={onValueChange} foodObject={foodObject} index={index} />
            )}
          </DivCol>
        </DivCol>
        <button
          onClick={() => onRemoveIngredient(foodObject)}
          className={clsx(
            "sm:mr-4",
            "absolute",
            "right-0",
            "top-1/2",
            "transform",
            "-translate-y-1/2"
          )}
          testid={`delete_${foodObject.name}`}
        >
          <img
            src={
              require("../../../assets/icons/deco_icons/icons8-remove.svg")
                .default
            }
            alt={`delete_${foodObject.name}`}
            className="h-10 w-10"
          />
        </button>
      </DivRow>
    </Li>
  );
};

export function LivNavSelecter({ whoDisplay, whoIsDisplay, setDisplay, children, col="border-sky-300" }) {
  const dispatch = useDispatch();

  function handleClick() {
    dispatch(setDisplay(whoDisplay))
  }

  return (
    <Li 
      k={whoDisplay}
      add={clsx(
        "cursor-pointer",
        whoIsDisplay === whoDisplay ? `border-b-2 ${col}` : ""
      )}
      onClick={handleClick}
      testid={whoDisplay}
    >
      {children}
    </Li>
  );
}