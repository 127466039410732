import { useDispatch, useSelector } from "react-redux";
import useError from "../utils/useError";
import { useMutation } from "react-query";
import {
  KEYS_TEMP_RECIPES,
  SLICE_TEMP_RECIPES,
  setShowResumeRecipe,
  setTempRecipesFetched,
} from "../../redux/slices/tempRecipesSlice";
import { delete_temp_recipe } from "../../functions/requests/axiosRequests";

function useDeleteTempRecipeMutation() {
  const dispatch = useDispatch();
  const { setError } = useError();

  const { tempRecipesFetched } = useSelector((state) => ({
    tempRecipesFetched:
      state[SLICE_TEMP_RECIPES][KEYS_TEMP_RECIPES.TEMP_RECIPES_FETCHED],
  }));

  const deleteTempRecipesRequest = useMutation(
    "delete_temp_recipe",
    delete_temp_recipe,
    {
      onSuccess: (data) => {
        const remainingTempRecipes = tempRecipesFetched.filter(
          (recipe) => recipe._id.toString() !== data.tempRecipeId.toString()
        );
        dispatch(setTempRecipesFetched(remainingTempRecipes));
        dispatch(setShowResumeRecipe(false));
      },
      onError: (error) => {
        setError(
          error.response?.data?.message || "Une erreur s'est produite"
        );
      },
    }
  );

  return deleteTempRecipesRequest;
}

export default useDeleteTempRecipeMutation;
