import React from "react";
import clsx from "clsx";
import { H3 } from "../../texts/Texts";
import { FoodIcon } from "../../images/IconImage";
import useWindowWidth from "../../../../hooks/utils/useWindowWidth";

function IngredientsCardRecipe({ ingredients, idRecipeToCook, id }) {
  const divContainerStyle = clsx(
    "bg-lime-50",
    "h-12 md:h-16",
    "w-full md:w-56",
    "flex flex-col justify-start md:justify-center items-center",
    "order-[2] md:order-first",
    "basis-3/5 md:basis-full",
    "rounded-lg",
    "drop-shadow-2xl",
    "mt-2 md:mt-7",
    "px-0 py-2 md:px-2 md:py-0"
  );

  return (
    <div className={divContainerStyle}>
      <Ingredients
        ingredients={ingredients}
        idRecipeToCook={idRecipeToCook}
        id={id}
      />
    </div>
  );
}

function Ingredients({ ingredients, idRecipeToCook, id }) {
  const isMobile = useWindowWidth();
  const maxIngredients = isMobile ? 8 : 6;
  const endKey = idRecipeToCook ? idRecipeToCook : id;
  const styleUl = clsx(
    "grid",
    "h-12",
    ingredients.length < 5 ? "grid-cols-1" : "grid-cols-2",
    "md:grid-cols-6"
  );
  const sizeFoodIcon = clsx(
    "md:w-10 md:h-10",
    ingredients.length < 4 ? "w-8 h-8" : "w-6 h-6"
  );

  const styleH3 = clsx("hidden md:flex justify-center items-center");

  return (
    <>
      <H3
        add={"hidden md:inline-block"}
        size={"text-base"}
        col={"text-sky-500"}
      >
        Ingrédients
      </H3>
      <ul className={styleUl}>
        {ingredients.slice(0, maxIngredients).map((ingredient, index) => {
          const key = `${endKey}_${ingredient.food.name}`;
          return (
            <li key={key} >
              {index === maxIngredients - 1 &&
              ingredients.length > maxIngredients ? (
                <H3 add={styleH3} size={"text-2xl"}>
                  ...
                </H3>
              ) : (
                <FoodIcon
                  iconName={ingredient.food.icon}
                  altName={ingredient.food.name}
                  size={sizeFoodIcon}
                />
              )}
            </li>
          );
        })}
      </ul>
    </>
  );
}

export default IngredientsCardRecipe;
