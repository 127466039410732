import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  KEYS_RESET_PWD_DATA,
  SLICE_RESET_PWD_DATA,
} from "../../redux/slices/resetPwdSlice";
import useResetPwdQueries from "../../hooks/requests/useResetPwdQueries";
import { SectionCol } from "../elements/sections/Sections";
import { useLocation } from "react-router-dom";
import { LoadingSection } from "../elements/loadings/Loadings";
import { ButtonFill } from "../elements/bouttons/Buttons";
import { H1, H2 } from "../elements/texts/Texts";
import { FORGOT_PWD, LOGIN } from "../../constants/routes";
import LoadingModal from "../elements/modals/LoadingModal";
import {
  ResetPwdFieldAndError,
  ResetCpwdFieldAndError,
} from "../elements/fields/PwdFields";
import { FormAuth } from "../elements/forms/Forms";

function ResetPwd() {
  const { linkValidityResetPwdQuery } = useResetPwdQueries();

  return (
    <SectionCol add={"pt-12 space-y-8"}>
      <H1 add="text-center">Modification du mot de passe</H1>
      {linkValidityResetPwdQuery.isLoading && <LoadingSection />}
      {linkValidityResetPwdQuery.isError && <RequestError />}
      {linkValidityResetPwdQuery.isSuccess && <LinkValiditySuccess />}
    </SectionCol>
  );
}

function RequestError() {
  const { errorRequest } = useSelector((state) => ({
    errorRequest:
      state[SLICE_RESET_PWD_DATA][KEYS_RESET_PWD_DATA.ERROR_REQUEST],
  }));

  return (
    <>
      <H2 add="text-center">{errorRequest}</H2>
      <ButtonFill nav={FORGOT_PWD.route} testid="back_forgot">
        Réessayer
      </ButtonFill>
    </>
  );
}

function LinkValiditySuccess() {
  const { resetPwdMutation } = useResetPwdQueries();

  return (
    <>
      {!resetPwdMutation.isLoading &&
        !resetPwdMutation.isSuccess &&
        !resetPwdMutation.isError && (
          <ResetPwdForm resetPwdRequest={resetPwdMutation.mutate} />
        )}
      {resetPwdMutation.isLoading && <LoadingModal />}
      {resetPwdMutation.isError && <RequestError />}
      {resetPwdMutation.isSuccess && <ResetPwdSuccess />}
    </>
  );
}

function ResetPwdForm({ resetPwdRequest }) {
  const location = useLocation();

  const { newPwd, confirmNewPwd } = useSelector((state) => ({
    newPwd: state[SLICE_RESET_PWD_DATA][KEYS_RESET_PWD_DATA.NEW_PWD],
    confirmNewPwd:
      state[SLICE_RESET_PWD_DATA][KEYS_RESET_PWD_DATA.CONFIRM_NEW_PWD],
  }));

  const [resetPwdFormOk, setResetPwdFormOk] = useState(false);

  async function onSubmit() {
    resetPwdRequest({location, newPwd: newPwd.value});
  }

  useEffect(() => {
    if (newPwd.value.length >= 6 && newPwd.value === confirmNewPwd.value) {
      setResetPwdFormOk(true);
    } else {
      setResetPwdFormOk(false);
    }
  }, [newPwd.value, confirmNewPwd.value]);

  return (
    <FormAuth>
      <ResetPwdFieldAndError />
      <ResetCpwdFieldAndError />
      <SubmitButton resetPwdFormOk={resetPwdFormOk} onSubmit={onSubmit} />
    </FormAuth>
  );
}

function SubmitButton({ resetPwdFormOk, onSubmit }) {
  return (
    <ButtonFill testid="reset" onClick={onSubmit} disabled={!resetPwdFormOk}>
      Valider
    </ButtonFill>
  );
}

function ResetPwdSuccess() {
  return (
    <>
      <H2 add="text-center">Votre mot de passe a bien été modifié</H2>
      <ButtonFill nav={LOGIN.route} testid="connection">
        {LOGIN.name}
      </ButtonFill>
    </>
  );
}

export default ResetPwd;
