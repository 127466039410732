import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import WelcomeCat from "../../../assets/images/welcome_cat.png";
import HorizontalNav from "../navbars/HorizontalNav";
import MobileHeaderNav from "../navbars/MobileHeaderNav";
import { sH2 } from "../../../styles/styles"
import useWindowWidth from "../../../hooks/utils/useWindowWidth";
import { RECETTES } from "../../../constants/routes";

function Header() {
  const isMobile = useWindowWidth()
  const navigate = useNavigate();
  
  function handleClick() {
    navigate(RECETTES.route);
  }

  //#region Styles
  const headerStyle = clsx(
    "bg-lime-200 ",
    "w-full h-15 sm:h-20",
    "fixed",
    "top-0 bottom-0 right-0 left-0",
    "z-40",
    "flex flex-row items-center justify-between sm:justify-center space-x-10"
  );

  const divStyle = clsx("flex flex-row justify-between items-center cursor-pointer");

  const imgStyle = clsx("h-20 w-20");

  const h2Style = clsx(
    sH2,
    "hidden md:block",
    "text-lg sm:text-xl md:text-2xl lg:text-3xl",
    "text-start",
    "overflow-hidden"
  );

  const divHeaderMobileStyle = clsx(
    "flex flex-row items-center justify-between sm:justify-center space-x-10",
    "relative",
    "w-full h-full"
  )
  //#endregion

  return (
    <>
    <header className={headerStyle}>
      <div className={divStyle} onClick={handleClick}>
        <img src={WelcomeCat} alt="Chat" className={imgStyle} />

        <h2 className={h2Style}>Mealster</h2>
      </div>

      {!isMobile && <HorizontalNav />}
      {isMobile && (
      <div className={divHeaderMobileStyle}>
        <MobileHeaderNav />
        </div>
        )}
      {/* {isMobile && <VerticalNav />} */}
    </header>
    </>
  );
}

export default Header;
