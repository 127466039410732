import { UlCol } from "../lists/Lists";
import clsx from "clsx";
import { Span } from "../spans/Spans";

const FoodItem = ({ food, index, selectFood }) => {
  return (
    <li
      className={clsx(
        index % 2 === 0 ? "bg-blue-100" : "bg-blue-50",
        "w-full",
        "py-2",
        "hover:bg-blue-200",
        "pl-4",
        "cursor-pointer",
        "flex flex-row items-center space-x-2"
      )}
      onClick={() => selectFood(food)}
      testid={`suggestion_${food.name}`}
    >
      <img
        src={require(`../../../assets/icons/food_icons/${food.icon}.svg`)}
        alt={`icon_${food.name}`}
        className="w-6 h-6 sm:h-8 sm:w-8"
      />
      <Span>{food.name}</Span>
    </li>
  );
};

const FoodsFoundListModal = ({ selectFood, foodsFound }) => {
  return (
    <div
      className="test absolute z-10 mt-2 w-full h-96 overflow-y-scroll bg-white border rounded"
      testid="suggestionGroup"
    >
      <UlCol>
        {foodsFound.map((food, index) => {
          return (
            <FoodItem
              key={food._id}
              index={index}
              food={food}
              selectFood={selectFood}
            />
          );
        })}
      </UlCol>
    </div>
  );
};

export default FoodsFoundListModal;
