import mealsterPrivate from "./mealsterPrivate";
import mealsterPublic from "./mealsterPublic";
import ErrorCode from "../../constants/httpErrorCodes";
import extractLink from "../utils/extractLink";

async function sendRequest(
  method,
  endpoint,
  data,
  isPrivate = false,
  customHeaders = {}
) {
  try {
    const axiosInstance = isPrivate ? mealsterPrivate : mealsterPublic;
    const config = method === "delete" ? { data: data } : data;
    const res = await axiosInstance[method](endpoint, config, {
      headers: customHeaders,
    });

    if (res.data.status) {
      let error = {
        response: {
          data: { status: res.data.status, message: res.data.message },
        },
      };

      if (
        method === "post" &&
        !isPrivate &&
        res.data.message === ErrorCode.EMAIL_OR_USERNAME_ALREADY_EXISTS
      ) {
        error.response.data.emailExists = res.data.emailExists;
        error.response.data.authorExists = res.data.authorExists;
      }

      throw error;
    }
    return res.data;
  } catch (err) {
    if (err.message !== "Network Error") {
      throw err;
    } else {
      console.log("Error in request err", err);
      const error = { response: { data: { message: "Serveur injoignable" } } };
      throw error;
    }
  }
}

//#region post public
export async function register(data) {
  return sendRequest("post", "register", data);
}

export async function confirmAccount(location) {
  const link = extractLink(location);
  const data = { linkConfirmAccount: link };
  return sendRequest("post", "confirm_account", data);
}

export async function emailConfirm(data) {
  return sendRequest("post", "email_confirm", data);
}

export async function login(data) {
  return sendRequest("post", "login", data);
}

export async function forgot_pwd(data) {
  return sendRequest("post", "forgot_pwd", data);
}

export async function checkLinkValidityResetPwd(location) {
  const link = extractLink(location);
  const data = { linkResetPwd: link };
  return sendRequest("post", "link_validity", data);
}

export async function reset_pwd(data) {
  const link = extractLink(data.location);
  const _data = { linkResetPwd: link, newPwd: data.newPwd };
  return sendRequest("post", "reset_pwd", _data);
}
//#endregion

//#region get public
//#endregion

//#region post private
export async function change_pwd(data) {
  const bodyRequest = {
    actualPwd: data.actualPwd,
    newPwd: data.newPwd,
  };
  return sendRequest("post", "change_pwd", bodyRequest, true);
}

export async function change_email(data) {
  const bodyRequest = {
    actualEmail: data.actualEmail,
    newEmail: data.newEmail,
  };
  return sendRequest("post", "change_email", bodyRequest, true);
}

export async function change_avatar(data) {
  const bodyRequest = { avatar: data.avatar };
  return sendRequest("post", "change_avatar", bodyRequest, true);
}

export async function new_recipe(data) {
  return sendRequest("post", "new_recipe", data, true, {
    "Content-Type": "multipart/form-data",
  });
}

export async function update_recipe(data) {
  return sendRequest("post", "update_recipe", data, true, {
    "Content-Type": "multipart/form-data",
  });
}

export async function filtered_recipe(data) {
  return sendRequest("post", "filtered_recipe", data, true);
}

export async function add_recipes_to_cook(data) {
  return sendRequest("post", "add_recipes_to_cook", data, true);
}

export async function delete_recipes_to_cook(data) {
  return sendRequest("post", "delete_recipes_to_cook", data, true);
}

export async function delete_own_recipes(data) {
  return sendRequest("post", "delete_own_recipes", data, true);
}

export async function foods_bought(data) {
  return sendRequest("post", "foods_bought", data, true);
}

export async function add_favorite_recipe(data) {
  const recipesId = { recipesId: [data] };
  return sendRequest("post", "add_favorite_recipe", recipesId, true);
}

export async function delete_favorite_recipe(data) {
  const recipesId = { recipesId: [data] };
  return sendRequest("post", "delete_favorite_recipe", recipesId, true);
}

export async function add_temp_recipe(tempRecipeId) {
  const tempRecipeIdToAdd = { tempRecipeId: tempRecipeId };
  return sendRequest("post", "add_temp_recipe", tempRecipeIdToAdd, true);
}

export async function delete_temp_recipe(tempRecipeId) {
  const tempRecipeIdToDelete = { tempRecipeId: tempRecipeId };
  return sendRequest("post", "delete_temp_recipe", tempRecipeIdToDelete, true);
}

//#endregion

//#region get private
export async function loginByRefreshToken() {
  return sendRequest("get", "login_token", null, true);
}

export async function logout() {
  return sendRequest("get", "logout", null, true);
}

export async function food_version() {
  return sendRequest("get", "food_version", null, true);
}

export async function food_list() {
  return sendRequest("get", "food_list", null, true);
}

export async function food_user_list() {
  return sendRequest("get", "food_user_list", null, true);
}

export async function foods_user_list_regenerate() {
  return sendRequest("get", "foods_user_list_regenerate", null, true);
}

export async function get_recipes_to_cook() {
  return sendRequest("get", "get_recipes_to_cook", null, true);
}

export async function get_own_recipes() {
  return sendRequest("get", "get_own_recipes", null, true);
}

export async function get_favorite_recipes() {
  return sendRequest("get", "get_favorite_recipes", null, true);
}

export async function get_temp_recipes() {
  return sendRequest("get", "get_temp_recipes", null, true);
}

//#endregion

//#region delete private
export async function delete_account(data) {
  return sendRequest("delete", "delete_account", data, true);
}
//#endregion
