import { useDispatch } from "react-redux";
import { setRemoveAllIngredients } from "../../../redux/slices/filtersRecipesSlice";
import { ButtonFill } from "./Buttons";

function ButtonDeleteAllIngredients() {
  
  const dispatch = useDispatch();
  function handleClick() {
    dispatch(setRemoveAllIngredients());
  }
  return (
    <ButtonFill add={"mt-4"} onClick={handleClick}>
      Supprimer la liste
    </ButtonFill>
  );
}

export default ButtonDeleteAllIngredients;