import React, { useEffect } from "react";
import clsx from "clsx";
import Cross from "../../../assets/icons/deco_icons/icons8-fermer-la-fenetre.svg";
import { H2, H3 } from "../texts/Texts";
import { useMutation, useQueryClient } from "react-query";
import useError from "../../../hooks/utils/useError";
import {
  change_avatar,
  delete_account,
} from "../../../functions/requests/axiosRequests";
import LoadingModal from "./LoadingModal";
import { ButtonEmpty, ButtonFill } from "../bouttons/Buttons";
import { LOGIN, WELCOME } from "../../../constants/routes";
import { useNavigate } from "react-router-dom";
import { DivCol, DivGrid } from "../divs/Divs";
import ErrorCode from "../../../constants/httpErrorCodes";
import { useDispatch, useSelector } from "react-redux";
import {
  KEYS_USER_DATA,
  SLICE_USER_DATA,
  setEmail,
  setAvatar
} from "../../../redux/slices/userDataSlice";
import {
  setCpwdAuth,
  setEmailAuth,
  setPwdAuth,
  setAuthorAuth,
} from "../../../redux/slices/loginDataSlice";

export const Modal = ({ setter, children, title, bgCol, bgChild, bgOpa }) => {
  const handleCloseModal = () => {
    setter(false);
  };

  const divBackgroundStyle = clsx(
    bgCol ? bgCol : "bg-white",
    bgOpa ? bgOpa : "opacity-70"
  );

  return (
    <div className="fixed z-20 inset-0 overflow-y-auto hide-scrollbar-firefox">
      <div
        className="flex items-center justify-center min-h-screen"
        testid="div_modal"
      >
        <div
          className={clsx(divBackgroundStyle, "fixed inset-0")}
          onClick={handleCloseModal}
        ></div>
        {/* <div className={clsx(divBgChildrenStyle, "relative shadow-2xl rounded-lg w-3/4 bg-white")}> */}
        <DivCol
          add="relative shadow-2xl rounded-lg w-3/4 bg-white"
          testid="div_infos_modal"
        >
          {setter && (
            <button
              className="absolute top-0 right-0"
              testid="close_button_modal"
              onClick={handleCloseModal}
            >
              <img
                src={Cross}
                alt="bouton fermer modal"
                className="h-12 w-12"
              />
            </button>
          )}
          <H2 add="text-center pt-12">{title}</H2>
          <DivCol add="h-96 w-full overflow-y-scroll">{children}</DivCol>
        </DivCol>
      </div>
    </div>
  );
};

export const AvatarsListModal = ({ setDisplayAvatars, title }) => {
  const avatars = [
    "icons8-chat-1",
    "icons8-chat-2",
    "icons8-chat-3",
    "icons8-chat-4",
    "icons8-chat-5",
    "icons8-chat-6",
    "icons8-chat-7",
    "icons8-chat-8",
    "icons8-chat-9",
    "icons8-chat-10",
    "icons8-chat-11",
    "icons8-chat-12",
    "icons8-chat-13",
    "icons8-chat-14",
  ];
  // const { setUser } = useAuth();
  const dispatch = useDispatch();
  const { setError } = useError();
  const navigate = useNavigate();

  const { isLoading, mutate: postAvatar } = useMutation(change_avatar, {
    onSuccess: (data) => {
      dispatch(setAvatar(data.avatar))
      setDisplayAvatars(false);
    },
    onError: (err) => {
      if (err.response.data.message === ErrorCode.UNAUTHORIZED) {
        console.log("ErrorCode.UNAUTHORIZED", ErrorCode.UNAUTHORIZED);
        navigate(LOGIN.route);
      } else {
        setError(err.response.data.message);
        setDisplayAvatars(false);
      }
    },
    mutationKey: "login",
  });

  const handleAvatar = (avatar) => {
    postAvatar({ avatar: avatar });
  };

  return (
    <Modal setter={setDisplayAvatars} title={title}>
      {isLoading && <LoadingModal />}
      <DivGrid add="gap-4 h-full pt-4">
        {avatars.map((avatar) => (
          <img
            key={avatar}
            src={require(`../../../assets/icons/profile_icons/${avatar}.svg`)}
            alt={`avatar_${avatar}`}
            className="h-20 w-20 cursor-pointer hover:scale-110"
            onClick={() => handleAvatar(avatar)}
          />
        ))}
      </DivGrid>
    </Modal>
  );
};

export const ConfirmDeleteAccountModal = ({ setDisplayDeleteAccount }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { email } = useSelector((state) => ({
    email: state[SLICE_USER_DATA][KEYS_USER_DATA.EMAIL],
  }));

  const { isSuccess, isLoading, isError, error, mutate } =
    useMutation(delete_account);

  const handleDelete = () => {
    mutate({ email: email });
  };

  const queryClient = useQueryClient();

  useEffect(() => {
    if (isSuccess) {
      setTimeout(() => {
        dispatch(setEmail(""));
        queryClient.invalidateQueries();
        navigate(WELCOME.route);
      }, 3000);
      dispatch(setEmailAuth(""));
      dispatch(setAuthorAuth(""));
      dispatch(setPwdAuth(""));
      dispatch(setCpwdAuth(""));

    }
  }, [isSuccess, navigate, dispatch, queryClient]);

  if (isSuccess) {
    return (
      <Modal title={"Suppression du compte"}>
        <DivCol add="w-full h-full space-y-8 pt-12" ju={"justify-start"}>
          <H3>Votre compte a bien été supprimé</H3>
          <H3>Vous allez être redirigé vers la page d'accueil</H3>
        </DivCol>
      </Modal>
    );
  }
  if (isLoading) {
    return (
      <Modal title={"Suppression en cours"}>
        <LoadingModal />
      </Modal>
    );
  }
  if (isError) {
    return (
      <Modal title={"Suppression du compte"}>
        {/* <DivCol add="w-full h-full"> */}
        <H3>{error.response.data.message}</H3>
        <ButtonFill onClick={() => setDisplayDeleteAccount(false)}>
          Fermer
        </ButtonFill>
        {/* </DivCol> */}
      </Modal>
    );
  }
  return (
    <Modal
      setter={setDisplayDeleteAccount}
      title={"Êtes-vous sûr de vouloir supprimer votre compte ?"}
    >
      <DivCol add="w-full h-full space-y-8 pt-12" ju={"justify-start"}>
        <ButtonFill
          add="w-44"
          onClick={() => setDisplayDeleteAccount(false)}
          testid="cancel_delete_account"
        >
          Non
        </ButtonFill>
        <ButtonEmpty
          add="w-44"
          onClick={handleDelete}
          testid="confirm_delete_account"
        >
          Oui
        </ButtonEmpty>
      </DivCol>
    </Modal>
  );
};

export function ConfirmDeleteRecipesModal({ recipes, query, setDisplayModal }) {
  function deleteRecipes() {
    const recipesId = recipes
      .filter((recipe) => {
        return recipe?.recipe?.selected || recipe.selected;
      })
      .map((recipe) => recipe._id);
    query.mutate({ recipesToDelete: recipesId });
  }

  if (query.isLoading) {
    return (
      <Modal title={"Suppression en cours"}>
        <LoadingModal />
      </Modal>
    );
  }

  return (
    <Modal
      setter={setDisplayModal}
      title={"Êtes-vous sûr de vouloir supprimer ces recettes ?"}
    >
      <DivCol add="w-full h-full space-y-8 pt-12" ju={"justify-start"}>
        <ButtonFill
          add="w-44"
          onClick={() => setDisplayModal(false)}
          testid="cancel_delete_recipes"
        >
          Non
        </ButtonFill>
        <ButtonEmpty
          add="w-44"
          onClick={deleteRecipes}
          testid="confirm_delete_recipes"
        >
          Oui
        </ButtonEmpty>
      </DivCol>
    </Modal>
  );
}
