import { createSlice } from "@reduxjs/toolkit";
import { REDIRECT_AFTER_LOGIN } from "../../constants/routes";

export const initialState = {
  email: "",
  author: "",
  avatar: "icons8-chat-1",
  isLogged: false,
  mobileRouteHeader: REDIRECT_AFTER_LOGIN.route
}

export const SLICE_USER_DATA = "userData";
export const KEYS_USER_DATA = {
  EMAIL: "email",
  AUTHOR: "author",
  AVATAR: "avatar",
  IS_LOGGED: "isLogged",
  MOBILE_ROUTE_HEADER: "mobileRouteHeader"
};

const userDataSlice = createSlice({
  name: SLICE_USER_DATA,
  initialState,
  reducers: {
    setEmail: (state, action) => {
      state.email = action.payload;
    },
    setAuthor: (state, action) => {
      state.author = action.payload;
    },
    setAvatar: (state, action) => {
      state.avatar = action.payload;
    },
    setIsLogged: (state, action) => {
      state.isLogged = action.payload;
    },
    setMobileRouteHeader: (state, action) => {
      state.mobileRouteHeader = action.payload;
    }
  }
});

export const { 
  setEmail,
  setAuthor,
  setAvatar,
  setIsLogged,
  setMobileRouteHeader
 } = userDataSlice.actions;

export default userDataSlice.reducer;