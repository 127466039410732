export const ROOT = {route: "/", name: "Mealster"};
export const WELCOME = {route: "/", name: "Mealster"};
export const RECETTES = {route: "/recettes", name: "Recettes", logo: require("../assets/icons/deco_icons/icons8-cookbook.svg").default};
export const RECETTE = {route: "/recettes/:id_recipe", name: "Recette"};
export const ALEA = {route: "/aleatoire", name: "Aléatoire", logo: require("../assets/icons/deco_icons/icons8-dice.svg").default};
export const EDITER = {route: "/editer", name: "Créer", logo: require("../assets/icons/deco_icons/icons8-left-handed1.svg").default};
export const CUISINER = {route: "/cuisiner", name: "Cuisiner", logo: require("../assets/icons/deco_icons/icons8-cutting-a-carrot.svg").default};
export const COURSES = {route: "/courses", name: "Courses", logo: require("../assets/icons/deco_icons/icons8-ingredients.svg").default};
export const PROFILE = {route: "/profile", name: "Profil", logo: "../assets/icons/profile_icons/"};
export const PROFILE_REDIRECTION = {route: "/profile/infosAccount", name: "Profil", logo: require("../assets/icons/profile_icons/icons8-chat-1.svg").default};
export const PROFILE_INFOS = {route: "infosAccount", name: "Information du compte", logo: require("../assets/icons/profile_icons/icons8-chat-1.svg").default};
export const PROFILE_EMAIL = {route: "/profile/changeEmail", name: "Changer d'adresse email"};
export const PROFILE_PWD = {route: "/profile/changePwd", name: "Changer de mot de passe"};
export const PROFILE_OWN = {route: "ownRecipes", name: "Liste de vos recettes"};
export const ERROR = {route: "/error", name: "Page inconnue"};
export const REGISTER = {route: "/register", name: "Inscription"}
export const LOGIN = {route: "/login", name: "Connexion"}
export const FORGOT_PWD = {route: "/forgot_pwd", name: "Mot de passe oublié"}
export const CONFIRM_ACCOUNT = {route: "/confirmaccount/:id", name:"Validation du compte"}
export const RESET_PWD = {route: "/resetpwd/:id", name:"Réinitialisation du mot de passe."}
export const REDIRECT_AFTER_LOGIN = CUISINER

export const routesHeaderFct = [RECETTES, COURSES, CUISINER, EDITER, PROFILE];

export const BASE_URL_API = process.env.REACT_APP_API_URL || "http://localhost:5000/api/";