import React from 'react'
import { RadioInputStore } from '../inputs/RadioInput'
import { useSelector } from 'react-redux';
import { setTypeOfFetch } from '../../../redux/slices/filtersRecipesSlice';

function FieldTypeRecipeToFetch() {
  const typeOfFetch = useSelector((state) => state.filtersRecipes.typeOfFetch);
  
  return (
    <RadioInputStore state={typeOfFetch} action={setTypeOfFetch} />
  )
}

export default React.memo(FieldTypeRecipeToFetch)