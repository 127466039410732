import React from "react";
import clsx from "clsx";
import ErrorCat from "../../assets/images/error_cat.png";

function Error() {
  const sectionStyle = clsx(
    "flex",
    "flex-col",
    "items-center",
    "w-full",
    "h-full",
    "pt-28",
    "px-2",
  );

  const h1Style = clsx(
    "text-3xl sm:text-5xl",
    "font-rubik",
    "font-extrabold",
    "text-center",
    "text-sky-300"
  );

  const imgStyle = clsx(
    "h-48 w-48",
    "sm:h-64 sm:w-64",
    "md:h-96 md:w-96",
  );
  return (
    <section className={sectionStyle}>
      <h1 className={h1Style}>Oups</h1>
      <img
        className={imgStyle}
        src={ErrorCat}
        alt="Chat cuisinier qui vous regarde tristement"
      />
      <h1 className={h1Style}>Cette page n'existe pas !</h1>
    </section>
  );
}
export default Error;
