import SelectionCheckBox from "../../checkboxs/SelectionCheckBox";

function SelectionCardRecipe({ idRecipeToCook, id, selected, changeRecipeSelectionStatus }) {
  
  function handleClick(event) {
    event.stopPropagation(event);
    if (idRecipeToCook) {
      changeRecipeSelectionStatus(idRecipeToCook);
    } else {
      changeRecipeSelectionStatus(id);
    }
  }

  return (
    <SelectionCheckBox selected={selected} handleClick={handleClick} />
  );
}

export default SelectionCardRecipe;