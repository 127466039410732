import { useMutation } from "react-query";
import { useDispatch } from "react-redux";
import {
  setEmail,
  setAuthor,
  setAvatar,
  setIsLogged,
} from "../../redux/slices/userDataSlice";
import { setInvalidLogin } from "../../redux/slices/loginDataSlice";
import { login } from "../../functions/requests/axiosRequests";
import useError from "../utils/useError";
import ErrorCode from "../../constants/httpErrorCodes"
import { useNavigate } from "react-router-dom";
import { REDIRECT_AFTER_LOGIN } from "../../constants/routes";

export function useLoginMutation() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setError } = useError();

  const loginMutation = useMutation(login, {
    onSuccess: (data) => {
      dispatch(setEmail(data.email));
      dispatch(setAuthor(data.author));
      dispatch(setAvatar(data.avatar));
      dispatch(setIsLogged(true));
      navigate(REDIRECT_AFTER_LOGIN.route)
    },
    onError: (err) => {
      if (err.response.data.status) {
        if (err.response.data.message === ErrorCode.EMAIL_OR_PASSWORD_INCORRECT) {
          dispatch(setInvalidLogin(true));
        } else {
          dispatch(setInvalidLogin(false));
          setError(err.response.data.message);
        }
      }
      else {
        setError(err.response.data.message);
      }
    },
  });

  return loginMutation;
}

export default useLoginMutation;