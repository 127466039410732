import { createContext, useReducer } from "react";

const initialStates = {
  error: "",
};


const reducers = (state, action) => {
  switch (action.type) {
    case "SET_ERROR":
      return { ...state, error: action.payload };
    default:
      return state;
  }
};

const ErrorProvider = ({ children }) => {
  const [stateError, dispatchError] = useReducer(reducers, initialStates);

    return (
        <ErrorContext.Provider value={[ stateError, dispatchError ]}>
            {children}
        </ErrorContext.Provider>
    )
}
export const ErrorContext = createContext(initialStates);

export default ErrorProvider;