import ErrorCode from "../../constants/httpErrorCodes";
import { useDispatch } from "react-redux";
import { setIsLogged } from "../../redux/slices/userDataSlice";
import { setErrorsIsDisplayed, setErrorDescription } from "../../redux/slices/errorsSlice";


function useError() {
  const dispatch= useDispatch()

  function setError(err) {
    if(err !== "") {

      if (err === ErrorCode.UNAUTHORIZED) {
        dispatch(setIsLogged(false))
      } else {
        dispatch(setErrorsIsDisplayed(true))
        dispatch(setErrorDescription(err))
      }
    }
    else {
      dispatch(setErrorsIsDisplayed(false))
      dispatch(setErrorDescription(""))
    }
  };

  return { setError };
};

export default useError;
