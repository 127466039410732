import { Routes, Route, Navigate } from "react-router-dom";
import Layout from "./components/routes/Layout";
import Welcome from "./components/routes/Welcome";
import Error from "./components/routes/Error";
import Register from "./components/routes/Register";
import Login from "./components/routes/Login";
import ConfirmAccount from "./components/routes/ConfirmAccount";
import RequireAuth from "./components/routes/RequireAuth";
import Profile2 from "./components/routes/profile/Profile2";
import ResetPwd from "./components/routes/ResetPwd";
import ForgotPwd from "./components/routes/ForgotPwd";
import ChangePwd from "./components/routes/ChangePwd";
import ChangeEmail from "./components/routes/ChangeEmail";
import EditRecipe from "./components/routes/editRecipe/EditRecipe";
import Courses from "./components/routes/Courses";
import Cooking from "./components/routes/cooking/Cooking";

import {
  ROOT,
  WELCOME,
  REGISTER,
  LOGIN,
  CONFIRM_ACCOUNT,
  ERROR,
  PROFILE,
  PROFILE_PWD,
  PROFILE_EMAIL,
  FORGOT_PWD,
  RESET_PWD,
  EDITER,
  RECETTES,
  COURSES,
  CUISINER, 
} from "./constants/routes";
import Recipes from "./components/routes/recipes/Recipes";

function App() {

  return (
    <Routes>
      <Route path={ROOT.route} element={<Layout />}>
        {/* public routes */}
        <Route path={WELCOME.route} element={<Welcome />} />
        <Route path={REGISTER.route} element={<Register />} />
        <Route path={LOGIN.route} element={<Login />} />
        <Route path={FORGOT_PWD.route} element={<ForgotPwd />} />
        <Route path={CONFIRM_ACCOUNT.route} element={<ConfirmAccount />} />
        <Route path={RESET_PWD.route} element={<ResetPwd />} />

        {/* private routes */}
        <Route element={<RequireAuth />}>
          <Route path={RECETTES.route} element={<Recipes />} />
          <Route path={COURSES.route} element={<Courses />} />
          <Route path={CUISINER.route} element={<Cooking />} />
          <Route path={EDITER.route} element={<EditRecipe />} />
          <Route path={PROFILE.route} element={<Profile2 />} />
          <Route path={PROFILE_PWD.route} element={<ChangePwd />} />
          <Route path={PROFILE_EMAIL.route} element={<ChangeEmail />} />
        </Route>

        {/* catch all */}
        <Route exact path="*" element={<Navigate to={ERROR.route} replace />} />
        <Route exact path={ERROR.route} element={<Error />} />
      </Route>
    </Routes>
  );
}

export default App;
